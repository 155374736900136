import React from 'react';
import { List, Filter, TextInput, Datagrid, TextField, EditButton, DeleteWithConfirmButton, BooleanField, NumberField, ImageField } from 'react-admin';
import {Button, Menu, MenuItem} from "@material-ui/core"
import ActionComponent from "./actions";
import {LinkField, PriceField, DateField, Pagination} from '../../partials';

const FilterComponents = (props) => {
    return (
        <Filter {...props}>
            <TextInput source="name" label="Name" />
        </Filter>
    );
};

const ButtonComponent = (props) => {
    const {record = {}} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>...</Button>
            <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            >
                <MenuItem onClick={handleClose}><EditButton {...props}/></MenuItem>
                {!record.deleted && <MenuItem onClick={handleClose}>
                    <DeleteWithConfirmButton  {...props}/>
                </MenuItem>}
        </Menu>
    </div>
    )
};
const ListComponent = (props) => (
    <List {...props} pagination={<Pagination />}  perPage={20} bulkActionButtons={false} actions={<ActionComponent />} sort={{field:"created_at", order: "DESC"}}>
        <Datagrid filters={<FilterComponents />}>
            <TextField source="id" label="ID"/>
            <LinkField reference="productmetadatas" field="name" source="product_metadata_id" label="Product Metadata ID"/>
            <TextField source="name" label="Name"/>
            <ImageField source="images[0]" label="Images"/>
            <PriceField source="unit_price" label="Unit Price"/>
            <PriceField source="sale_price" label="Sale Price"/>
            <PriceField source="shell_price" label="Shell Price"/>
            <PriceField source="agency_price" label="Agency Price"/>
            <NumberField source="unit_point" label="Unit Point"/>
            <NumberField source="net_weight" label="Net Weight"/>
            <NumberField source="priority" label="Priority"/>
            <BooleanField source="deleted" label="Deleted"/>
            <DateField source="created_at" label="Created"/>
            <ButtonComponent />
        </Datagrid>
    </List>
);

export default ListComponent