
import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput } from 'react-admin';

import InputComponent from '../imageInput';
import ArrayImageField from '../arrayImageField';

const EditComponent = (props) => {
    const [removeImages, setRemoveImages] = React.useState([])
    const [refresh, setRefresh] = React.useState(true)
    const handleClick = (url = "", isBack = "") => {
        if(url) {
            if(!isBack) {
                setRemoveImages([...removeImages, ...[url.src]])
            } else {
                setRemoveImages(removeImages.filter(e => e !== url.src))
            }
        }
    }
     return (
        <Edit {...props} undoable={false}>
            <SimpleForm redirect={"list"}>
                <TextInput label="ID" source="id" />
                <TextInput label="Name" source="name" />
                <TextInput multiline label="Description" source="description" />
                <NumberInput label="Priority" source="priority" initialValue={1}/>
                <InputComponent multiple source="imagesNew" nameBase="imagesBase" label="Images"/>
                <ArrayImageField label="Pre Images" source="images" edit={true} handleClick={handleClick} removeImages={removeImages} setRefresh={setRefresh} refresh={refresh}/>
            </SimpleForm>
        </Edit>
    )
};

export default EditComponent;