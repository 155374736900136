import React from 'react';
import {Button, Menu, MenuItem} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { EditButton, DeleteWithConfirmButton, useNotify, useRedirect, useMutation, ShowButton } from 'react-admin';
import {ButtonField} from '../../partials';
import { _actionTransferTransition, _roleTransferTransition, _groupPermission} from '../../utils/enum';

const ActionComponent = (props) => {
    const {record = {}, nameBtn = "..."} = props;
    const notify = useNotify();
    const redirect = useRedirect();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [actions, setActions] = React.useState([]);
    const customPermission = _groupPermission(props.permissions);
    const [actionTransfer, { loading }] = useMutation();
    const handleTransfer = (name, dst) => actionTransfer(
        {
            type: 'transition',
            resource: 'warehouseagenciestransfer',
            payload: { name, src: (record.status || "").indexOf("TRANSFER_STATUS_") < 0 ? `TRANSFER_STATUS_${record.status}` : record.status, dst, id: record.id },
        },
        {
            refresh: true,
            onSuccess: ({ data }) => {
                notify('Done');
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }
    )


    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleBack = () => {
        redirect(`${props.basePath}`)
    }
    
    React.useEffect(() => {
        if(customPermission && _roleTransferTransition[customPermission]) {
            const status = (record.status || "").indexOf("TRANSFER_STATUS_") < 0 ? `TRANSFER_STATUS_${record.status}` : record.status
            let data = _actionTransferTransition.filter(e => e.Src.find(i => i === status)) || []
            const roles = _roleTransferTransition[customPermission]
            data = data.filter(e => roles.find(i => i === e.Name))
            setActions(data)
        }
    }, [customPermission, record])

    let html = [];
    if(record.status === "" || record.status === "DRAFT" || record.status === "TRANSFER_STATUS_DRAFT") {
        html = [...html, ...[
            <MenuItem onClick={handleClose}>
                <DeleteWithConfirmButton  {...props} label={"Delete"}/>
            </MenuItem>,
            <MenuItem onClick={handleClose}><EditButton {...props} label={"Edit"} redirect={"show"}/></MenuItem>
        ]]
    }
    if(!props.isShow) {
        html.push(<MenuItem onClick={handleClose}><ShowButton {...props} label={"Show"}/></MenuItem>)
    }

    actions.forEach(role => {
        html.push(
            <MenuItem onClick={handleClose}><ButtonField onClick={() => handleTransfer(role.Name, role.Dst)} loading={loading} color="secondary" name={role && role.Name? role.Name.replace("TRANSFER_TRANSITION_", "") : ""}/></MenuItem>
        )
    })
    return (
        <React.Fragment>
            {props.isShow && <Button onClick={handleBack} color="primary" startIcon={<ArrowBackIcon />}>Back</Button>}
                {(record.status === "" || record.status === "DONE" || record.status === "TRANSFER_STATUS_DONE" || (!(record.status === "" || record.status === "DRAFT" || record.status === "TRANSFER_STATUS_DRAFT") && actions.length === 0)) 
                ? (!props.isShow ? <ShowButton {...props} label={"Show"}/> : "")
            : html.length === 0 ? "" : <React.Fragment>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>{nameBtn}</Button>
                    <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    >
                    {html}
                </Menu>
            </React.Fragment>}
        </React.Fragment>
    )
};

export default ActionComponent
