import { USER_GROUP_OWNER_AGENCY, USER_GROUP_ADMIN } from "./const"
export const _enum2Array = (e = {}, prefix = "") => {
    return Object.keys(e).filter(e => e.indexOf("_NONE") === -1).map(i => {
        return {
            id: e[i],
            key: i,
            name: i.replace(prefix, "")
        }
    })
}
export const _enumUploadType = {
    "UPLOAD_TYPE_NONE": 0, "UPLOAD_TYPE_AVATAR": 1
}

export const _enumSex = {
    "SEX_NONE": 0, 'SEX_MALE': 1, "SEX_FEMALE": 2
}
export const _arraySex = _enum2Array(_enumSex, "SEX_")
export const _choiseSex = _enum2Array(_enumSex)

export const _enumNotiStatus = {
    "NOTIFICATION_MASTER_STATUS_NONE": 0,
    "NOTIFICATION_MASTER_STATUS_PENDING": 1,
    "NOTIFICATION_MASTER_STATUS_SENDING": 2,
    "NOTIFICATION_MASTER_STATUS_SENT": 3,
    "NOTIFICATION_MASTER_STATUS_DELETED": 4,
}

export const _enumUserStatus = {
    "USER_STATUS_NONE": 0, "USER_STATUS_ACTIVE": 1, "USER_STATUS_BAN": 2
}
export const _enumVipCustomerStatus = {
    "VIP_CUSTOMER_STATUS_NONE": 0, "VIP_CUSTOMER_STATUS_ACTIVE": 1, "VIP_CUSTOMER_STATUS_INACTIVE": 2
}
export const _choiceVipCustomerStatus = _enum2Array(_enumVipCustomerStatus, "VIP_CUSTOMER_STATUS_")

export const _choiceUserStatus = _enum2Array(_enumUserStatus, "USER_STATUS_")

export const _enumUserGroup = {
    "USER_GROUP_NONE": 0, "USER_GROUP_CUSTOMER": 1, "USER_GROUP_EMPLOYEE_AGENCY": 2, "USER_GROUP_EMPLOYEE_COMPANY": 3, USER_GROUP_OWNER_AGENCY: 4, USER_GROUP_ADMIN: 5
}


export const _groupPermission = (key = "") => {
    const permissions = {
        "USER_GROUP_NONE": "none",
        "USER_GROUP_CUSTOMER": "none",
        "USER_GROUP_EMPLOYEE_AGENCY": "agency",
        USER_GROUP_OWNER_AGENCY: "agency",
        "USER_GROUP_EMPLOYEE_COMPANY": "admin",
        USER_GROUP_ADMIN: "admin"
    }
    return permissions[key] || "none"
}
export const _reverseObject = (object = {}) => {
    const r = {}
    Object.keys(object).forEach(e => {
        r[object[e]] = e;
    })
    return r
}
export const _reverseSex = _reverseObject(_enumSex)
export const _reverseUserStatus = _reverseObject(_enumUserStatus)

export const _reverseUserGroup = _reverseObject(_enumUserGroup)
export const _arrayUserGroup = _enum2Array(_enumUserGroup, "USER_GROUP_")
export const _choiceUserGroup = _enum2Array(_enumUserGroup)


export const _enumAgencyStatus = {
    "AGENCY_STATUS_NONE": 0, "AGENCY_STATUS_ACTIVE": 1, "AGENCY_STATUS_CLOSE": 2
}
export const _reverseAgencyStatus = _reverseObject(_enumAgencyStatus)
export const _arrayAgencyStatus = _enum2Array(_enumAgencyStatus, "AGENCY_STATUS_")
export const _choiceAgencyStatus = _enum2Array(_enumAgencyStatus)

export const _enumTransferStatus = {
    "TRANSFER_STATUS_NONE": 0, "TRANSFER_STATUS_DRAFT": 1, "TRANSFER_STATUS_REQUESTED": 2, "TRANSFER_STATUS_ACCEPTED": 3, "TRANSFER_STATUS_ON_DELIVERING": 4,
    "TRANSFER_STATUS_DELIVERED": 5, "TRANSFER_STATUS_CANCELED": 6, "TRANSFER_STATUS_DONE": 7, "TRANSFER_STATUS_ON_RETURNING": 8, "TRANSFER_STATUS_RETURNED": 9
}
export const _arrayTransferStatus = _enum2Array(_enumTransferStatus, "TRANSFER_STATUS_")

export const _enumTransferTransition = {
    "TRANSFER_TRANSITION_NONE": 0, "TRANSFER_TRANSITION_REQUEST": 1, "TRANSFER_TRANSITION_ACCEPT": 2, "TRANSFER_TRANSITION_START_DELIVERY": 3,
    "TRANSFER_TRANSITION_MARKED_DELIVERED": 4, "TRANSFER_TRANSITION_CANCEL": 5, "TRANSFER_TRANSITION_FINISH": 6,
    "TRANSFER_TRANSITION_START_RETURN": 7, "TRANSFER_TRANSITION_MARKED_RETURNED": 8
}
export const _arrayTransferTransition = _enum2Array(_enumTransferTransition, "TRANSFER_TRANSITION_")
export const _reverseTransferTransition = _reverseObject(_enumTransferTransition)


export const _enumImportStatus = {
    "IMPORT_STATUS_NONE": 0, "IMPORT_STATUS_DRAFT": 1, "IMPORT_STATUS_DONE": 2
}
export const _arrayImportStatus = _enum2Array(_enumImportStatus, "IMPORT_STATUS_")


export const _getString = (v = 0, e = {}) => {
    const find = Object.keys(e).find(i => i.trim() === v.trim()) || "";
    return find;
}

export const _actionTransferTransition = [{
    Name: "TRANSFER_TRANSITION_REQUEST",
    Src: ["TRANSFER_STATUS_DRAFT"],
    Dst: "TRANSFER_STATUS_REQUESTED",
},
{
    Name: "TRANSFER_TRANSITION_ACCEPT",
    Src: ["TRANSFER_STATUS_REQUESTED"],
    Dst: "TRANSFER_STATUS_ACCEPTED",
},
{
    Name: "TRANSFER_TRANSITION_START_DELIVERY",
    Src: ["TRANSFER_STATUS_ACCEPTED"],
    Dst: "TRANSFER_STATUS_ON_DELIVERING",
},
{
    Name: "TRANSFER_TRANSITION_MARKED_DELIVERED",
    Src: ["TRANSFER_STATUS_ON_DELIVERING"],
    Dst: "TRANSFER_STATUS_DELIVERED",
},
{
    Name: "TRANSFER_TRANSITION_CANCEL",
    Src: ["TRANSFER_STATUS_REQUESTED"],
    Dst: "TRANSFER_STATUS_CANCELED",
},
{
    Name: "TRANSFER_TRANSITION_FINISH",
    Src: ["TRANSFER_STATUS_DELIVERED"],
    Dst: "TRANSFER_STATUS_DONE",
},
{
    Name: "TRANSFER_TRANSITION_START_RETURN",
    Src: ["TRANSFER_STATUS_ON_DELIVERING"],
    Dst: "TRANSFER_STATUS_ON_RETURNING",
},
{
    Name: "TRANSFER_TRANSITION_MARKED_RETURNED",
    Src: ["TRANSFER_STATUS_ON_RETURNING"],
    Dst: "TRANSFER_STATUS_RETURNED",
}];

export const _roleTransferTransition = {
    admin: [
        "TRANSFER_TRANSITION_ACCEPT", "TRANSFER_TRANSITION_START_DELIVERY", "TRANSFER_TRANSITION_MARKED_DELIVERED", "TRANSFER_TRANSITION_MARKED_RETURNED"
    ],
    agency: [
        "TRANSFER_TRANSITION_REQUEST", "TRANSFER_TRANSITION_CANCEL", "TRANSFER_TRANSITION_FINISH", "TRANSFER_TRANSITION_START_RETURN",
    ]
}
export const _enumSalaryStatus = {
    "EMPLOYEE_SALARY_STATUS_NONE": 0, "EMPLOYEE_SALARY_STATUS_PENDING": 1, "EMPLOYEE_SALARY_STATUS_CANCELED": 2, "EMPLOYEE_SALARY_STATUS_DONE": 3
}

export const _arraySalaryStatus = _enum2Array(_enumSalaryStatus, "EMPLOYEE_SALARY_")

export const _month = [{
    key: 1,
}, {
    key: 2,
}, {
    key: 3,
}, {
    key: 4,
}, {
    key: 5,
}, {
    key: 6,
}, {
    key: 7,
}, {
    key: 8,
}, {
    key: 9,
}, {
    key: 10,
}, {
    key: 11,
}, {
    key: 12,
}]


export const _arraryConfigKey = [{
    id: "bonus_rate",
    name: "bonus_rate",
    permissions: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
    for: [USER_GROUP_OWNER_AGENCY],
}, {
    id: "point",
    name: "point",
    permissions: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
    for: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
}, {
    id: "app_version",
    name: "app_version",
    permissions: [USER_GROUP_ADMIN],
    for: [USER_GROUP_ADMIN],
}, {
    id: "category_point",
    name: "category_point",
    permissions: [USER_GROUP_ADMIN],
    for: [USER_GROUP_ADMIN],
}, {
    id: "price",
    name: "price",
    permissions: [USER_GROUP_ADMIN],
    for: [USER_GROUP_OWNER_AGENCY],
}]
export const _arraryConfigPoint = [{
    id: "point_rate",
    name: "Rate",
}, {
    id: "point_ref",
    name: "Ref",
}, {
    id: "register_success",
    name: "Register success",
}]
export const _arraryConfigPrice = [{
    id: "price_coefficient",
    name: "Price Coefficient",
}]

export const _actionOrderTransition = [{
    Name: "ORDER_TRANSITION_ASSIGN",
    Src: ["ORDER_STATUS_DRAFT", "ORDER_STATUS_ON_ASSIGNING"],
    Dst: "ORDER_STATUS_ASSIGNED",
},
{
    Name: "ORDER_TRANSITION_START_DELIVERY",
    Src: ["ORDER_STATUS_ASSIGNED"],
    Dst: "ORDER_STATUS_ON_DELIVERING",
},
{
    Name: "ORDER_TRANSITION_MARKED_DELIVERED",
    Src: ["ORDER_STATUS_ON_DELIVERING"],
    Dst: "ORDER_STATUS_DELIVERED",
},
{
    Name: "ORDER_TRANSITION_CANCEL",
    Src: ["ORDER_STATUS_DRAFT", "ORDER_STATUS_ON_ASSIGNING"],
    Dst: "ORDER_STATUS_CANCELED",
},
{
    Name: "ORDER_TRANSITION_HAND_OVER",
    Src: ["ORDER_STATUS_DELIVERED"],
    Dst: "ORDER_STATUS_WAITING_TO_FINISH",
},
{
    Name: "ORDER_TRANSITION_ACCEPT",
    Src: ["ORDER_STATUS_WAITING_TO_FINISH", "ORDER_STATUS_ON_ASSIGNING", "ORDER_STATUS_WAITING_FOR_APPROVE_RETURN", "ORDER_STATUS_ASSIGNED", "ORDER_STATUS_ON_DELIVERING",
        "ORDER_STATUS_DELIVERED", "ORDER_STATUS_RETURNED"],
    Dst: "ORDER_STATUS_DONE",
},
{
    Name: "ORDER_TRANSITION_DENY",
    Src: ["ORDER_STATUS_WAITING_TO_FINISH", "ORDER_STATUS_RETURNED"],
    Dst: "ORDER_STATUS_RETURNED",
},
{
    Name: "ORDER_TRANSITION_START_RETURN",
    Src: ["ORDER_STATUS_ON_DELIVERING"],
    Dst: "ORDER_STATUS_WAITING_FOR_APPROVE_RETURN",
},
{
    Name: "ORDER_TRANSITION_MARKED_RETURNED",
    Src: ["ORDER_STATUS_ON_DELIVERING"],
    Dst: "ORDER_STATUS_RETURNED",
}];

export const _enumOrderStatus = {
    "ORDER_STATUS_NONE": 0, "ORDER_STATUS_DRAFT": 1, "ORDER_STATUS_ON_ASSIGNING": 2, "ORDER_STATUS_ASSIGNED": 3,
    "ORDER_STATUS_ON_DELIVERING": 4, "ORDER_STATUS_DELIVERED": 5, "ORDER_STATUS_CANCELED": 6, "ORDER_STATUS_WAITING_TO_FINISH": 7,
    "ORDER_STATUS_DONE": 8, "ORDER_STATUS_WAITING_FOR_APPROVE_RETURN": 9, "ORDER_STATUS_RETURNED": 10
}
export const _arrayOrderStatus = _enum2Array(_enumOrderStatus, "ORDER_STATUS_")

export const _roleOrderTransition = {
    admin: [
        "ORDER_TRANSITION_MARKED_RETURNED",
    ],
    agency: [
        "ORDER_TRANSITION_ACCEPT", "ORDER_TRANSITION_ASSIGN", "ORDER_TRANSITION_MARKED_RETURNED", "ORDER_TRANSITION_CANCEL"
    ]
}

export const _configGroupByPermission = (permissions, sFor) => {
    return _arraryConfigKey.filter(e => e.permissions.indexOf(permissions) !== -1 && ((sFor && e.for.indexOf(sFor) !== -1) || !sFor));
}