
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useForm, useFormState } from "react-final-form";
import { Card, Paper, Grid, CardHeader, CardContent } from '@material-ui/core';
import {useDataProvider, Loading, useNotify, Datagrid , NumberField, ArrayField, SimpleForm, SelectInput} from 'react-admin';
import { _parseQuery, _stringifyQuery } from '../../utils/helper'

const DetailComponent = (props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const form = useForm();
    const formData = useFormState().values;
    const [data, setData] = useState({results: []});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        var value = _parseQuery(props.location.search)
        if (value && value.year) {
            form.batch(() => {
                form.change("year", value.year);
            })
        } else {
            form.batch(() => {
                form.change("year", 'all');
            })
        }
    }, [form, props.location.search])

    const handleDateChange = useCallback((e) => {
        const year = e.target.value

        form.batch(() => {
            form.change("year", year);
        })
    }, [form])

    const agencyId = props.id || ''

    const getData = useCallback(() => {
      let params = {
        agencyId: agencyId,
        groupBy: 'GROUP_BY_MONTH',
        limit: 100,
        year: formData.year
      }
      if (formData.year === 'all') {
        params.groupBy = 'GROUP_BY_YEAR'
        params.year = ''
      }

        return dataProvider.getOne("agency_report", params)
            .then((res) => {
                if (res.error) {
                    setLoading(false);
                    if (res.message === "NotFound" && res.err_code === 1000) {
                        setData({});
                        notify(`Dont have data for this month`, 'info');
                    } else {
                        notify(`${res.message || res._message}`, 'warning');
                    }
                    return
                }
                setData(res);
                setLoading(false);
            })
            .catch(error => {
                if (error.message === "NotFound" && error.err_code === 1000) {
                    setData({});
                    notify(`Dont have data for this month`, 'info');
                } else {
                    notify(error.message, 'warning');
                }
                setLoading(false);
            })
    }, [dataProvider, notify, formData, agencyId])

    useEffect(() => {
        if (formData && formData.year) {
            if (props.history && props.history.location && props.history.push) {
                const location = props.history.location;
                props.history.push({
                    pathname: location.pathname,
                    search: `?${_stringifyQuery({
                        year: formData.year,
                    })}`
                })
            }
        }

          getData()
    }, [formData, getData, props.history])
   if (loading) return <Loading />;

  var years = [{id: 'all', name: 'All'}];

  const startYear = 2019
  const currentYear = moment().year();
    for (let i = currentYear; i >= startYear; i --) {
      years.push({
        id: i + '',
        name: i + ''
      })
    }

    return (
        <Paper>
            <Card>
                <CardHeader title="Report Point" />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item sm={6}>
      <SelectInput source="year" choices={years}
                                    onChange={handleDateChange}
/>
                        </Grid>
                    </Grid>

                    <br />

      <ArrayField record={data} source="data" label="Data">
            <Datagrid>
              <Text source="time" />
              <NumberField source="total_point_distributed" />
              <NumberField source="total_point_exchanged" />
            </Datagrid>
          </ArrayField>
               </CardContent>
            </Card>
        </Paper>
    )
};

const Text = (props) => {
  return (
    <p>{props.record[props.source]}</p>
  )
}

const Component = (props) => {
    return (
        <SimpleForm {...props} toolbar={null} style={{ width: "100%" }}>
            <DetailComponent {...props} />
        </SimpleForm>
    )
};

export default Component;

export {Component}
