import React, { useState } from "react";
import {
    TextField,
    Card,
    CardContent,
    Button,
    Grid,
    Avatar,
} from "@material-ui/core";
import {
    makeStyles,
    ThemeProvider,
    createMuiTheme,
} from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import {
    useQuery,
    Loading,
    Error,
    useRedirect,
    useTranslate,
    useMutation,
    useNotify,
} from "react-admin";
import { _formatDate } from "../../utils/helper";
import GenerateOTP from "./generateOTP";

const theme = createMuiTheme({
    overrides: {
        MuiFormLabel: {
            root: {
                "&$disabled": {
                    color: "unset !important",
                },
            },
        },
        MuiInputBase: {
            root: {
                "&$disabled": {
                    color: "unset !important",
                },
            },
        },
    },
});
const useStyles = makeStyles((theme) => ({
    large: {
        backgroundColor: theme.palette.secondary.main,
        height: theme.spacing(20),
        width: theme.spacing(20),
        margin: "auto",
        [theme.breakpoints.down("sm")]: {
            height: theme.spacing(10),
            width: theme.spacing(10),
        },
        [theme.breakpoints.up("lg")]: {
            height: theme.spacing(30),
            width: theme.spacing(30),
        },
    },
    actionBtn: {
        width: "100%",
        textAlign: "right",
    },
    grid: {
        width: "unset",
    },
}));
const ShowComponent = (props) => {
    const classes = useStyles();
    const redirect = useRedirect();
    const notify = useNotify();
    const translate = useTranslate();
    const [data, setData] = useState();
    const { loading, error } = useQuery(
        {
            type: "profile",
            resource: "profiles",
            payload: {
                filter: {},
                pagination: {},
                sort: {},
            },
        },
        {
            onSuccess: ({ data = {} }) => {
                setData(data);
            },
        }
    );

    const [onDisabled2FA, { loading: disable2FALoading }] = useMutation(
        {
            type: "disable2FA",
            resource: "profiles",
            payload: {},
        },
        {
            onSuccess: ({ data, status, message }) => {
                if (status) {
                    notify("Disabled 2FA success");
                    setData((prev) => ({ ...prev, enabled_2fa: false }));
                } else {
                    notify(`Disabled 2FA error: ${message}`, "warning");
                }
            },
            onFailure: (error) =>
                notify(`Disabled 2FA error: ${error.message}`, "warning"),
        }
    );

    if (loading) {
        return <Loading />;
    }
    if (error) {
        return <Error />;
    }
    const handleEdit = () => {
        redirect(`${props.basePath}/${data.id}`);
    };

    const onVerifyOTPSuccess = () => {
        setData((prev) => ({ ...prev, enabled_2fa: true }));
    };

    return (
        <ThemeProvider theme={theme}>
            <Card>
                <CardContent>
                    <div className={classes.actionBtn}>
                        <Button
                            startIcon={<EditIcon />}
                            color="primary"
                            variant="outlined"
                            onClick={handleEdit}
                        >
                            {"Edit"}
                        </Button>
                        {data.enabled_2fa ? (
                            <Button
                                color="primary"
                                variant="outlined"
                                disabled={disable2FALoading}
                                onClick={onDisabled2FA}
                            >
                                {"Disabled 2FA"}
                            </Button>
                        ) : (
                            <GenerateOTP
                                onVerifyOTPSuccess={onVerifyOTPSuccess}
                                onDisabled2FA={onDisabled2FA}
                                email={data.email}
                            />
                        )}
                    </div>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item md={12}>
                                <Avatar
                                    alt="Avatar"
                                    src={data.avatar}
                                    className={classes.large}
                                >
                                    {}
                                </Avatar>
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    label="Name"
                                    value={data.name}
                                    margin="normal"
                                    fullWidth={true}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    label="Phone"
                                    value={data.phone_number}
                                    margin="normal"
                                    fullWidth={true}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    label="Email"
                                    value={data.email}
                                    margin="normal"
                                    fullWidth={true}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    label="Birthday"
                                    value={_formatDate(data.birth_day)}
                                    margin="normal"
                                    fullWidth={true}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    label="Sex"
                                    value={translate(
                                        `gender.${data.sex
                                            .replace("SEX_", "")
                                            .toLowerCase()}`
                                    )}
                                    margin="normal"
                                    fullWidth={true}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    label="Group"
                                    value={translate(
                                        `user_group.${data.group
                                            .replace("USER_GROUP_", "")
                                            .toLowerCase()}`
                                    )}
                                    margin="normal"
                                    fullWidth={true}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    label="Status"
                                    value={translate(
                                        `status.${data.status
                                            .replace("USER_STATUS_", "")
                                            .toLowerCase()}`
                                    )}
                                    margin="normal"
                                    fullWidth={true}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    label="Address"
                                    value={data.address}
                                    margin="normal"
                                    fullWidth={true}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    label="Latitude"
                                    value={data.latitude}
                                    margin="normal"
                                    fullWidth={true}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    label="Longitude"
                                    value={data.longitude}
                                    margin="normal"
                                    fullWidth={true}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </ThemeProvider>
    );
};
export default ShowComponent;
