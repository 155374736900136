import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles(theme => ({
    delete: {
        color: "#f44336",
    },
}));
export default function ResponsiveDialog(props) {
    const {title, content, nameCancel = 'Cancel', nameOk = 'OK', actionOk, actionCacel, record = {}, btnOk, btnName = "popup", btnIcon, deleteButton} = props;
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
        if(actionOk) actionOk(record)
    };

    const handleClose = () => {
        setOpen(false);
        if(actionCacel) actionCacel(record)
    };

    return (
        <div>
            <Button color="primary" className={deleteButton ? classes.delete : ""} onClick={handleClickOpen} startIcon={btnIcon ? btnIcon : <ArrowForwardIcon /> }> {btnName}</Button>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                fullWidth
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">{nameCancel}</Button>
                    {btnOk ? btnOk : <Button onClick={handleClose} color="primary" autoFocus>{nameOk}</Button> }
                </DialogActions>
            </Dialog>
        </div>
    );
}
