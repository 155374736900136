
import React from 'react';
import { Grid, FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {NumberField, ArrayField, Datagrid, ReferenceField, BooleanField, useQuery } from 'react-admin';
import { TextField, PriceField, UserField, DateField, EnumField} from "../../../partials"
import {_enumTransferStatus} from "../../../utils/enum"
import {USER_GROUP_ADMIN} from '../../../utils/const';

const useStyles = makeStyles(theme => ({
    label: {
        fontSize: "0.875rem",
        marginBottom: theme.spacing(1),
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: "0.00938em",
        display: "block"
    },
    rootGrid: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        boxSizing: "border-box",
        "& > div": {
            marginBottom: theme.spacing(3)
        }
    },
    gridAction: {
        textAlign: "right"
    }
}));

const DataComponent = (props) => {
    const classes = useStyles();
    let {record = {}, resource} = props;
    const product_id = (record.items || []).map(e => e.id)
    const { data: dataWarehouseAgency = [] } = useQuery({
        type: 'list',
        resource: 'warehouseagencies',
        payload: {
            filter: {
                ...(product_id.length && {product_id: product_id}),
                agency_id: record.agency_id,
            },
            pagination: {
                perPage: product_id.length,
            }
        }
    });
    const { data: dataWarehouseCompany = [] } = useQuery({
        type: 'list',
        resource: 'warehousecompanies',
        payload: {
            filter: {
                ...(product_id.length && {product_id: product_id}),
            },
            pagination: {
                perPage: product_id.length,
            }
        }
    });

    record.items = (record.items || []).map(e => {
        const findAgency = dataWarehouseAgency.find(i => i.id === e.id) || {};
        e.warehouseagency_quantity = findAgency.quantity || 0
        const findCompany = dataWarehouseCompany.find(i => i.id === e.id) || {};
        e.warehousecompany_quantity = findCompany.quantity || 0
        return e
    })

    const requireProps = {
        permissions: props.permissions,
        record: record,
        basePath: props.basePath,
        resource: props.resource,
        input: undefined,
        isShow: true,
        nameBtn: "Action"
    }
    const rowStyle = (record, index) => ({
        backgroundColor: record.warehousecompany_quantity === 0 ? "#dadada" : record.quantity > record.warehousecompany_quantity ? '#fffedb' : "unset",
    });
    return (
        <Grid spacing={3} className={classes.rootGrid}>
            <Grid item md={12}>
                <FormLabel className={classes.label}>{"Transfer By"}</FormLabel>
                <UserField {...requireProps} label="Transfer By" source="transfer_product.transfer_by"/>
            </Grid>
            <Grid item md={6}>
                <FormLabel className={classes.label}>{"Note"}</FormLabel>
                <TextField {...requireProps} label="Note" source="transfer_product.note" multiline rows={3}/>
            </Grid>
            <Grid item md={6}>
                <FormLabel className={classes.label}>{"Status"}</FormLabel>
                <EnumField {...requireProps} label="Status" source="transfer_product.status" _enum={_enumTransferStatus} prefix="TRANSFER_STATUS_"/>
            </Grid>
            <Grid item md={6}>
                <FormLabel className={classes.label}>{"Total Products"}</FormLabel>
                <NumberField {...requireProps} label="Total Products" source="transfer_product.total_products"/>
            </Grid>
            <Grid item md={6}>
                <FormLabel className={classes.label}>{"Total Prices"}</FormLabel>
                <PriceField {...requireProps} label="Total Prices" source="transfer_product.total_price"/>
            </Grid>
            <Grid item md={6}>
                <FormLabel className={classes.label}>{"Transfer at"}</FormLabel>
                <DateField {...requireProps} label="Transfer At" source="transfer_product.transfer_at"/>
            </Grid>
            <Grid item md={6}>
                <FormLabel className={classes.label}>{"Created At"}</FormLabel>
                <DateField {...requireProps} label="Created At" source="transfer_product.created_at"/>
            </Grid>
            <Grid item md={12} style={{overflow: "auto"}}>
                <FormLabel className={classes.label}>{"Items"}</FormLabel>
                <ArrayField {...requireProps} source="items">
                    <Datagrid rowStyle={rowStyle}>
                        <TextField source="id" label="ID"/>
                        <TextField label="Product Meta ID" source="product_metadata_id"/>
                        {props.permissions === USER_GROUP_ADMIN ?
                        <ReferenceField label="Product Meta" source="product_metadata_id" reference="productmetadatas">
                            <TextField source="name" />
                        </ReferenceField>
                        : "" }

                        <TextField source="warehousecompany_quantity" label="Warehouse Company" />
                        <TextField source="warehouseagency_quantity" label="Warehouse Agency" />
                        <NumberField source="quantity" label="Quantity"/>
                        <TextField source="name" label="Name"/>
                        <PriceField source="unit_price" label="Unit Price"/>
                        {resource.indexOf("transferrequests") === -1 && <PriceField source="sale_price" label="Sale Price"/>}
                        <PriceField source="shell_price" label="Shell Price"/>
                        <NumberField source="unit_point" label="Unit Point"/>
                        <NumberField source="net_weight" label="Net Weight"/>
                        <BooleanField source="deleted" label="Deleted"/>
                        <DateField source="created_at" label="Created" />
                    </Datagrid>
                </ArrayField>
            </Grid>
        </Grid>
    )
}
export default DataComponent;
