import {_endpointOrder} from '../../utils/provider';
import {_createRequest, _pick, _fetch} from '../../utils/helper';
import {USER_GROUP_OWNER_AGENCY} from '../../utils/const';
const endpoint = (props) => {
    const {user = {}} = props;
    let params = JSON.parse(JSON.stringify(props.params || {}))
    if(user.group === USER_GROUP_OWNER_AGENCY) {
        params.agency_id = user.agency_id;
    }   
    return _fetch(_createRequest(_endpointOrder.countOrder, true, {
        ..._pick(params, ["agency_id", "status", "endAt"]),
    }))
}
export default endpoint