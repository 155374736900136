import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import {
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    SelectInput,
    useQuery,
} from "react-admin";

// import CompleteAddress from '../completeAddress';
import { _arrayAgencyStatus } from "../../utils/enum";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        },
    },
}));
const CreateComponent = (props) => {
    const classes = useStyles();
    const { data = {}, loading } = useQuery({
        type: "getAgencyTypes",
        resource: "agencies",
        payload: {
            filter: {},
            pagination: {},
            sort: {},
        },
    });
    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Create {...props}>
            <SimpleForm className={classes.form} redirect="list">
                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <TextInput label="Name" source="name" fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <TextInput
                            label="Phone"
                            source="phone_number"
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <SelectInput
                            label="Status"
                            source="status"
                            choices={_arrayAgencyStatus}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <TextInput label="Email" source="email" fullWidth />
                    </Grid>
                    <Grid item sm={6}>
                        <TextInput
                            label="Password"
                            source="password"
                            type="password"
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextInput label="Address" source="address" fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <NumberInput
                            label="Latitude"
                            source="latitude"
                            initialValue={0}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <NumberInput
                            label="Longitude"
                            source="longitude"
                            initialValue={0}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <NumberInput
                            label="Radius"
                            source="radius"
                            initialValue={10}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <SelectInput
                            label="Agency Type"
                            source="agency_type"
                            choices={data}
                            fullWidth
                            initialValue={data[0] ? data[0].id : ""}
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default CreateComponent;
