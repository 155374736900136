import React from 'react';
import { List, Datagrid, TextField, SelectInput, Filter,NumberField } from 'react-admin';
import {UserField, EnumField, DateField, Pagination, PriceField} from '../../partials';
import {_enumImportStatus, _arrayImportStatus} from '../../utils/enum';
import ActionComponent from "./actions";

const FilterComponents = (props) => {
    return (
        <Filter {...props}>
            <SelectInput source="status" label="Status" choices={_arrayImportStatus} optionValue="name"/>
        </Filter>
    );
};


const ListComponent = (props) => {
    return (
        <List {...props} pagination={<Pagination />}  perPage={20} bulkActionButtons={false} filters={<FilterComponents />} sort={{ field: 'created_at', order: 'DESC' }}>
            <Datagrid>
                <UserField source="import_by" label="Import by"/>
                <TextField source="import_at" label="Import at"/>
                <TextField source="note" label="Note"/>
                <EnumField source="status" label="Status" _enum={_enumImportStatus} prefix="IMPORT_STATUS_"/>
                <PriceField label="Total Price" source="total_price" fullWidth />                
                <NumberField label="Total Products" source="total_products" fullWidth />
                <DateField source="created_at" label="Created"/>
                <ActionComponent {...props} label="Action" />
            </Datagrid>
        </List>
    );
}

export default ListComponent