import { USER_GROUP_OWNER_AGENCY, USER_GROUP_ADMIN } from "./const";
export const _typeProvider = {
  GET_LIST: "list",
  CREATE: "create",
  GET_ONE: "one",
  GET_MANY: "many",
  GET_MANY_REFERENCE: "manyReference",
  UPDATE: "update",
  DELETE_MANY: "deleteMany",
  DELETE: "delete",
};

export const _endpointAgency = {
  base: "/gaogas.console/Agency",
  list: "/gaogas.console/Agency/AgencyList",
  create: "/gaogas.console/Agency/CreateAgency",
  one: "/gaogas.console/Agency/GetAgencyDetail",
  update: "/gaogas.console/Agency/EditAgency",
  delete: "/gaogas.console/Agency/DeleteAgency",
  getAgencyTypes: "/gaogas.console/Agency/GetAgencyTypes",
  roles: [USER_GROUP_ADMIN],
};

export const _endpointAgencyType = {
  base: "/gaogas.console/Agency",
  create: "/gaogas.console/Agency/CreateAgencyType",
  update: "/gaogas.console/Agency/EditAgencyType",
  list: "/gaogas.console/Agency/GetAgencyTypes",
  roles: [USER_GROUP_ADMIN],
};

export const _endpointPartner = {
  base: "/gaogas.console/PartnerService",
  create: "/gaogas.console/PartnerService/CreatePartner",
  update: "/gaogas.console/PartnerService/UpdatePartner",
  list: "/gaogas.console/PartnerService/RetrievePartner",
  roles: [USER_GROUP_ADMIN],
};

export const _endpointReportAgency = {
  point: "/gaogas.console/Agency/ReportPoint",
  roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
};

export const _endpointUser = {
  base: "/gaogas.console/User",
  list: "/gaogas.console/User/ListUser",
  create: "/gaogas.console/User/CreateUser",
  one: "/gaogas.console/User/GetUserDetail",
  update: "/gaogas.console/User/EditUser",
  reset: "/gaogas.console/User/ResetPassword",
  generateOTP: "/gaogas.console/User/GenerateOTP",
  verifyOTP: "/gaogas.console/User/VerifyOTP",
  disable2FA: "/gaogas.console/User/Disable2FA",
  roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
};
export const _endpointListCustomerAgency = {
  base: "/gaogas.console/User",
  list: "/gaogas.console/User/ListCustomerAgency",
  roles: [USER_GROUP_OWNER_AGENCY],
};
export const _endpointVipCustomer = {
  base: "/gaogas.console/User",
  list: "/gaogas.console/User/RetrieveVipCustomer",
  update: "/gaogas.console/User/EditVipCustomer",
  roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
};
export const _endpointEmployeeSalary = {
  base: "/gaogas.console/User",
  list: "/gaogas.console/User/EmployeeSalaryList",
  one: "/gaogas.console/User/EmployeeSalaryDetail",
  roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
};
export const _endpointWarehouseAgency = {
  base: "/gaogas.console/WarehouseAgency",
  statistic:
    "/gaogas.console/WarehouseAgency/RetrieveWarehouseAgencyStatistics",
  statisticv2:
    "/gaogas.console/WarehouseAgency/RetrieveWarehouseAgencyStatisticsV2",
  suppendProduct:
    "/gaogas.console/WarehouseAgency/SuppendProduct",
  editProduct: "/gaogas.console/WarehouseAgency/EditAgencyProduct",
  transfer: {
    update: "/gaogas.console/WarehouseAgency/EditTransferProduct",
    create: "/gaogas.console/WarehouseAgency/CreateTransferProduct",
    transition: "/gaogas.console/WarehouseAgency/TransitionTransferProduct",
    list: "/gaogas.console/WarehouseAgency/RetrieveHistoryTransferProduct",
    one: "/gaogas.console/WarehouseAgency/RetrieveHistoryTransferProductDetail",
    delete: "/gaogas.console/WarehouseAgency/DeleteTransferProduct",
    roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
  },
  roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
};
export const _endpointWarehouseCompany = {
  base: "/gaogas.console/WarehouseCompany",
  statistic:
    "/gaogas.console/WarehouseCompany/RetrieveWarehouseCompanyStatisticsV2",
  import: {
    create: "/gaogas.console/WarehouseCompany/CreateImportProduct",
    list: "/gaogas.console/WarehouseCompany/RetrieveHistoryImportProduct",
    one: "/gaogas.console/WarehouseCompany/RetrieveHistoryImportProductDetail",
    update: "/gaogas.console/WarehouseCompany/EditImportProduct",
    finish: "/gaogas.console/WarehouseCompany/FinishImportProduct",
    delete: "/gaogas.console/WarehouseCompany/DeleteImportProduct",
    roles: [USER_GROUP_ADMIN],
  },
  roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
};
export const _endpointUpload = {
  base: "/gaogas.console/Upload",
  upload: "/gaogas.mice/Upload/Upload",
};
export const _endpointMasterData = {
  base: "/gaogas.console/MasterData",
  categories: {
    list: "/gaogas.console/MasterData/CategoryList",
    create: "/gaogas.console/MasterData/CreateCategory",
    delete: "/gaogas.console/MasterData/DeleteCategory",
    update: "/gaogas.console/MasterData/EditCategory",
    import: "/gaogas.console/MasterData/ImportCategory",
    roles: [USER_GROUP_ADMIN],
    list_roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
  },
  products: {
    create: "/gaogas.console/MasterData/CreateProduct",
    delete: "/gaogas.console/MasterData/DeleteProduct",
    update: "/gaogas.console/MasterData/EditProduct",
    one: "/gaogas.console/MasterData/GetProductDetail",
    import: "/gaogas.console/MasterData/ImportProduct",
    list: "/gaogas.console/MasterData/ProductList",
    roles: [USER_GROUP_ADMIN],
    list_roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
    one_roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
  },
  productMetadatas: {
    create: "/gaogas.console/MasterData/CreateProductMetadata",
    delete: "/gaogas.console/MasterData/DeleteProductMetadata",
    update: "/gaogas.console/MasterData/EditProductMetadata",
    one: "/gaogas.console/MasterData/GetProductMetadataDetail",
    import: "/gaogas.console/MasterData/ImportProductMetadata",
    list: "/gaogas.console/MasterData/ProductMetadataList",
    roles: [USER_GROUP_ADMIN],
    list_roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
    one_roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
  },
};
export const _endpointConfigAgency = {
  base: "/gaogas.console/Agency",
  one: "/gaogas.console/Agency/AgencyConfig",
  create: "/gaogas.console/Agency/CreateAgencyConfig",
  update: "/gaogas.console/Agency/EditAgencyConfig",
  roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
};
export const _endpointConfig = {
  base: "/gaogas.console/MasterData",
  create: "/gaogas.console/MasterData/CreateConfig",
  update: "/gaogas.console/MasterData/EditConfig",
  list: "/gaogas.console/MasterData/ListConfig",
  roles: [USER_GROUP_ADMIN],
};

export const _endpointOrder = {
  base: "/gaogas.console/Order",
  list: "/gaogas.console/Order/OrderList",
  assign: "/gaogas.console/Order/AssignOrder",
  finish: "/gaogas.console/Order/FinishOrder",
  create: "/gaogas.console/Order/CreateOrder",
  markedReturned: "/gaogas.console/Order/MarkedReturned",
  cancel: "/gaogas.console/Order/CancelOrder",
  countOrder: "/gaogas.console/Order/CountOrder",
  roles: [USER_GROUP_OWNER_AGENCY],
  list_roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
  create_roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
  markedReturned_roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
};

export const _endpointOrderComment = {
  all: "/gaogas.console/Order/GetOrderComments",
  sendNotification: "/gaogas.console/Order/SendMessageViaOrder",
  roles: [USER_GROUP_OWNER_AGENCY],
};

export const _endpointNotification = {
  base: "/gaogas.console/Notification",
  list: "/gaogas.console/Notification/GetNotificationMaster",
  delete: "/gaogas.console/Notification/DeleteNotificationMaster",
  create: "/gaogas.console/Notification/CreateNotificationMaster",
  update: "/gaogas.console/Notification/UpdateNotificationMaster",
  roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
  another: {
    approve: "/gaogas.console/Notification/ApproveNotificationMaster",
    roles: [USER_GROUP_ADMIN],
  },
};

export const _endpointEvent = {
  base: "/gaogas.console/Event",
  list: "/gaogas.console/Event/EventList",
  delete: "",
  create: "/gaogas.console/Event/CreateEvent",
  update: "/gaogas.console/Event/EditEvent",
  export: "/gaogas.console/Event/ExportUserJoinEvent",
  roles: [USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY],
};

export const _convertTypeProvider = (type = "") => {
  return _typeProvider[type] || type;
};
