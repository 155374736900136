import React from 'react';
import { List, TextInput, Filter } from 'react-admin';
import {DatagridComponent} from "../../warehouseAgenciesTransfer/list";
import {Pagination} from "../../../partials";

const FilterComponents = (props) => {
    return (
        <Filter {...props}>
            <TextInput source="agency_id" label="Agency ID" />
        </Filter>
    );
};


const ListComponent = ({hasShow, hasEdit, hasCreate, hasList, ...props}) => {
    const {basePath} = props;
    return (
        <List {...props} title="Transfer Requests" pagination={<Pagination />}  perPage={20} bulkActionButtons={false} filterDefaultValues={{
            ...(basePath !== "/transferrequests" && {
                status: (`TRANSFER_STATUS_` + basePath.replace("/transferrequests", "")).toUpperCase()
            })
        }} filters={<FilterComponents />} sort={{ field: 'created_at', order: 'DESC' }}>
            <DatagridComponent {...props} />
        </List>
    );
}
export default ListComponent
