
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core"

import { Show, SimpleShowLayout, TextField, NumberField, TabbedShowLayout, Tab, } from 'react-admin';
import Map from "../map";
import { EnumField, DateField, GridField } from '../../partials';
import { _enumUserGroup, _enumUserStatus, _enumSex } from '../../utils/enum';

import OrderList from "./detail/order";
import SalaryList from "./detail/salary"
const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
    map: {
        marginBottom: theme.spacing(3),
        minHeight: theme.spacing(10),
        width: "100%",
        height: theme.spacing(30),
        "& > div:first-child": {
            width: "100%",
            height: "100%"
        }
    },
    listRoot: {
        overflow: "auto"
    }
}));
const MapComponent = (props) => {
    const classes = useStyles();
    const { record = {} } = props;
    return (
        <div {...props} className={classes.map}>
            <Map {...props} {...record} />
        </div>
    );
};
const GridComponent = (props) => {
    return (
        <Grid container spacing={3}>
            <GridField {...props} item sm={6}>
                <TextField label="Name" source="name" />
            </GridField>
            <GridField {...props} item sm={6}>
                <TextField label="Phone" source="phone_number" />
            </GridField>
            <GridField {...props} item sm={6}>
                <TextField label="Email" source="email" />
            </GridField>
            <GridField {...props} item sm={6}>
                <DateField label="DOB" source="birth_day" />
            </GridField>
            <GridField {...props} item sm={4}>
                <EnumField {...props} label="Sex" source="sex" _enum={_enumSex} prefix="SEX_" />
            </GridField>
            <GridField {...props} item sm={4}>
                <EnumField {...props}  label="Group" source="group" _enum={_enumUserGroup} prefix="USER_GROUP_" />
            </GridField>
            <GridField {...props} item sm={4}>
                <EnumField {...props}  label="Status" source="status" _enum={_enumUserStatus} prefix="USER_STATUS_" />
            </GridField>
            <GridField {...props} item sm={4}>
                <NumberField label="Basic Salary" source="basic_salary" />
            </GridField>
            <GridField {...props} item sm={4}>
                <NumberField label="Latitude" source="latitude" />
            </GridField>
            <GridField {...props} item sm={4}>
                <NumberField label="Longitude" source="longitude" />
            </GridField>
            <GridField {...props} item sm={12}>
                <TextField label="Address" source="address" />
            </GridField>
            <GridField {...props} item sm={12}>
                <MapComponent label="Map" fullWidth={true} />
            </GridField>
        </Grid>
    );
};
const ShowComponent = (props) => {
    const classes = useStyles();
    return (
        <Show {...props} title={`Detail ${props.resource}`}>
            <SimpleShowLayout className={classes.form}>
                <GridComponent />
                {props.resource === "employeeagencies" || props.resource === "customers" || props.resource === "agency_customers" ? <TabbedShowLayout>
                    <Tab label="orders">
                        <OrderList className={classes.listRoot} label="" filter={props.resource === "employeeagencies" ? {employee_id: props.id} : {customer_id: props.id}} />
                    </Tab>
                    {props.resource === "employeeagencies" && <Tab label="Salary"><SalaryList label="" filter={{employee_id: props.id}} /></Tab>}
                </TabbedShowLayout> : ""}
            </SimpleShowLayout>
        </Show>
    )
};

export default ShowComponent;
