import { _endpointOrder } from "../../utils/provider";
import { _createRequest, _getFilter, _pick, _fetch } from "../../utils/helper";
import { _arrayOrderStatus } from "../../utils/enum";
import { USER_GROUP_OWNER_AGENCY } from "../../utils/const";

const convertKey2Enum = (key = "") => {
    let result = key;
    _arrayOrderStatus.forEach((e) => {
        let name = `orders${e.name.replace("_", "").toLowerCase()}`;
        if (name === key) {
            result = e.name;
            return result;
        }
    });
    return result;
};
const endpoint = async (props) => {
    const { key, type, user = {} } = props;
    let params = JSON.parse(JSON.stringify(props.params || {}));
    let filter = params.filter || {};
    if (user.group === USER_GROUP_OWNER_AGENCY) {
        filter.agency_id = user.agency_id;
    }
    if (key !== "orders" && key !== "orderOnAssigning") {
        filter.status = `ORDER_STATUS_${convertKey2Enum(key)}`;
    }
    params.filter = filter;
    switch (type) {
        case "assign": {
            if (
                !params.data ||
                !params.data.order_ids ||
                params.data.order_ids.length === 0
            ) {
                throw new Error("Cant process withh empty id");
            }
            if (!params.data || !params.data.employee_id) {
                throw new Error("Please select employee");
            }
            return _fetch(
                _createRequest(_endpointOrder.assign, true, {
                    ..._pick(params.data, ["employee_id", "order_ids"]),
                })
            );
        }
        case "create": {
            return _fetch(
                _createRequest(_endpointOrder.create, true, {
                    ..._pick(params.data, [
                        "note",
                        "phone_number",
                        "address",
                        "latitude",
                        "longitude",
                        "items",
                        "agency_id",
                        "customer_id",
                    ]),
                })
            ).then((res) => {
                if (res.data && res.data) res.data.id = res.data.order_id;
                return res;
            });
        }
        case "finish": {
            if (
                !params.data ||
                !params.data.order_ids ||
                params.data.order_ids.length === 0
            ) {
                throw new Error("Cant process withh empty id");
            }
            return _fetch(
                _createRequest(_endpointOrder.finish, true, {
                    ..._pick(params.data, ["order_ids"]),
                })
            );
        }
        case "delete": {
            return _fetch(
                _createRequest(_endpointOrder.cancel, true, {
                    order_ids: [params.id],
                })
            );
        }
        case "markedReturned": {
            if (
                !params.data ||
                !params.data.order_ids ||
                params.data.order_ids.length === 0
            ) {
                throw new Error("Cant process withh empty id");
            }
            return _fetch(
                _createRequest(_endpointOrder.markedReturned, true, {
                    ..._pick(params.data, ["order_ids"]),
                })
            );
        }
        case "one": {
            params.filter = params.filter || {};
            if (params.filter) params.filter.id = params.id;
            return _fetch(
                _createRequest(_endpointOrder.list, true, {
                    filter: `${_getFilter(params, {
                        agency_id: "eq",
                        id: "eq",
                        status: "eq",
                    })}`,
                })
            ).then((res) => {
                if (res.data && res.data.length >= 1) res.data = res.data[0];
                return res;
            });
        }
        case "many": {
            if (params.ids && params.ids.length) {
                params.filter = params.filter || {};
                params.filter.id = params.ids;
            } else {
                return {
                    data: [],
                    total: 0,
                };
            }

            return _fetch(
                _createRequest(_endpointOrder.list, true, {
                    filter: `${_getFilter(params, {
                        agency_id: "eq",
                        id: "in",
                        status: "eq",
                    })}`,
                })
            );
        }
        default: {
            //list
            return _fetch(
                _createRequest(_endpointOrder.list, true, {
                    filter: `${_getFilter(params, {
                        agency_id: "eq",
                        id: "eq",
                        status: "eq",
                        created_at: "gte",
                    })}`,
                })
            ).then((res) => {
                res.data = res.data.map((e = {}) => {
                    e.buy_shell = false;
                    (e.items || []).forEach((i) => {
                        if (i.buy_shell) {
                            e.buy_shell = true;
                            return;
                        }
                    });
                    return e;
                });
                return res;
            });
        }
    }
};

export default endpoint;
