import React, { cloneElement } from 'react';
import {TopToolbar, CreateButton, ExportButton, Button, useRedirect} from 'react-admin';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
const ActionComponent = ({ classes, className, ...props }) => {
    const redirect = useRedirect();
    const { filters, resource, showFilter, displayedFilters, filterValues } = props
    const handleImport = () => {
        redirect(`${props.basePath}/import`)
    }
    return (
        <TopToolbar>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton {...props} />
            <ExportButton {...props} />
            {/* Add your custom actions */}
            <Button
                onClick={handleImport}
                label="Import"
            >
                <LabelImportantIcon />
            </Button>
        </TopToolbar>
    );
}

export default ActionComponent;