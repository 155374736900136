export {default as useGetAllProducts} from "./useGetAllProducts";
export {default as useGetAllProductMetadatas} from "./useGetAllProductMetadatas";
export {default as useGetAllCategories} from "./useGetAllCategory";
export {default as useGetAllAgencyConfigs} from "./useGetAllAgencyConfigs";
export {default as useGetAllUsers} from "./useGetAllUsers";
export {default as useGetAllWarehouseAgencyStatistic} from "./useGetAllWarehouseAgencyStatistic";
export {default as useGetAllWarehouseCompanyStatistic} from "./useGetAllWarehouseCompanyStatistic";
export {default as useOrderOnAssigning} from "./useOrderOnAssigning";




const filterItems = (data = [], selectedItem = [], mineItem = {}, source = "product_id") => {
    selectedItem = (selectedItem || []).filter((e = {}) => e[source] !== mineItem[source]).map((e = {}) => e[source])
    return (data || []).filter((oneProduct = {}) => oneProduct && oneProduct.id && !selectedItem.find(i => i === oneProduct.id))
}
export {filterItems}