import list from './list';
import detail from './detail';
import edit from './edit';

export default {
    list: list,
    show: detail, 
    edit: edit, 
    name: "vip_customers",
    key: "p_vip_customers",
}