import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteWithConfirmButton, NumberField, BooleanField, TextInput, Filter, ImageField  } from 'react-admin';
import {Button, Menu, MenuItem} from "@material-ui/core"
import ActionComponent from "./actions";
import {LinkField, DateField, Pagination} from '../../partials';

const FilterComponents = (props) => {
    return (
        <Filter {...props}>
            <TextInput
                label="ID"
                source="id"
                
            />
            <TextInput source="category_id" label="Category ID" />
            <TextInput source="name" label="Name" />
        </Filter>
    );
};

const ButtonComponent = (props) => {
    const {record = {}} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>...</Button>
            <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            >
                <MenuItem onClick={handleClose}><EditButton {...props}/></MenuItem>
                {!record.deleted && <MenuItem onClick={handleClose}>
                    <DeleteWithConfirmButton  {...props}/>
                </MenuItem>}
        </Menu>
    </div>
    )
};
const ListComponent = (props) => {
    return (
        <List {...props} pagination={<Pagination />}  perPage={20} bulkActionButtons={false} filters={<FilterComponents />} sort={{ field: 'created_at', order: 'DESC' }} actions={<ActionComponent />}>
            <Datagrid>
                <TextField source="id" label="ID"/>
                <LinkField reference="categories" field="name" source="category_id" label="Category ID"/>
                <TextField source="name" label="Name"/>
                <ImageField source="images[0]" label="Images"/>
                <TextField source="track_by" label="Track by"/>
                <TextField source="unit" label="Unit"/>
                <TextField source="unit_weight" label="Unit Weight"/>
                <TextField source="supplier" label="Supplier"/>
                <TextField source="origin" label="Origin"/>
                <TextField source="expiry" label="Expiry"/>
                <NumberField source="priority" label="Priority"/>
                <BooleanField source="deleted" label="Deleted"/>
                <DateField source="created_at" label="Created"/>
                <ButtonComponent />
            </Datagrid>
        </List>
    )
};

export default ListComponent