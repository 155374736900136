import {_token, _refreshToken, _user, _lang} from './const';

export const _clientStorage = {
    set: (v = '') => {
        if(!v) return null;
        return localStorage.setItem(_token, v);
    },
    get: () => {
        return localStorage.getItem(_token);
    },
    remove: () => {
        return localStorage.removeItem(_token);
    }
}
export const _clientLangueStorage = {
    set: (v = '') => {
        if(!v) return null;
        return localStorage.setItem(_lang, v);
    },
    get: () => {
        return localStorage.getItem(_lang);
    },
    remove: () => {
        return localStorage.removeItem(_lang);
    }
}
export const _clientRefreshStorage = {
    set: (v = '') => {
        if(!v) return null;
        return localStorage.setItem(_refreshToken, v);
    },
    get: () => {
        return localStorage.getItem(_refreshToken);
    },
    remove: () => {
        return localStorage.removeItem(_refreshToken);
    }
}
export const _clientUserStorage = {
    set: (v = {}) => {
        if(!v) return null;
        return localStorage.setItem(_user, JSON.stringify(v));
    },
    get: () => {
        let user = localStorage.getItem(_user);
        if(user) {
            user = JSON.parse(user)
        }
        return user;
    },
    remove: () => {
        return localStorage.removeItem(_user);
    }
}
export const _removeAll = () => {
    return localStorage.clear()
}