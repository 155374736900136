
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Edit, SimpleForm, TextInput, NumberInput, SelectInput, required } from 'react-admin';

import InputComponent from '../imageInput';
import ArrayImageField from '../arrayImageField';

import {useGetAllProductMetadatas} from "../../hooks";
const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
}));

const CreateComponent = (props) => {
    const classes = useStyles();
    const [removeImages, setRemoveImages] = React.useState([])
    const [refresh, setRefresh] = React.useState(true)
    const handleClick = (url = "", isBack = "") => {
        if(!isBack) {
            setRemoveImages([...removeImages, ...[url.src]])
        } else {
            setRemoveImages(removeImages.filter(e => e !== url.src))
        }
    }
    

    const { data: productMetadatas = [] } = useGetAllProductMetadatas();

    return (
        <Edit {...props} undoable={false}>
            <SimpleForm className={classes.form} redirect={"list"}>
                <Grid container spacing={3}>
                    <Grid item sm={6}>
                        <TextInput label="ID" source="id" fullWidth />
                    </Grid>
                    <Grid item sm={6}>
                        <TextInput label="Name" source="name" fullWidth/>
                    </Grid>
                    <Grid item sm={6}>
                        <TextInput source="product_metadata_id" label="Product Metadata ID" fullWidth />
                    </Grid>
                    <Grid item sm={6}>
                        <SelectInput optionText="name" optionValue="id" fullWidth label="Product Metadata ID" source="product_metadata_id" validate={[required()]} choices={productMetadatas}/>
                    </Grid>
                    <Grid item sm={12}>
                        <TextInput multiline label="Description" source="description" rows={3} fullWidth />
                    </Grid>
                    <Grid item sm={3}>
                        <NumberInput source="unit_price" label="Unit Price" fullWidth />
                    </Grid>
                    <Grid item sm={3}>
                        <NumberInput source="sale_price" label="Sale Price" fullWidth />
                    </Grid>
                    <Grid item sm={3}>
                        <NumberInput source="agency_price" label="Agency Price" fullWidth />
                    </Grid>
                    <Grid item sm={3}>
                        <NumberInput source="shell_price" label="Shell Price" fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <NumberInput source="unit_point" label="Unit Point" fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <NumberInput source="net_weight" label="Net Weight" fullWidth />
                    </Grid>
                    <Grid item md={4}>
                        <NumberInput source="priority" label="Priority" fullWidth />
                    </Grid>
                    <Grid item sm={12}>
                        <InputComponent multiple source="imagesNew" nameBase="imagesBase" label="Images" />
                    </Grid>
                </Grid>
                <ArrayImageField label="Pre Images" source="images" edit={true} handleClick={handleClick} removeImages={removeImages} setRefresh={setRefresh} refresh={refresh}/>
            </SimpleForm>
        </Edit>
    )
};

export default CreateComponent;