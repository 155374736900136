import list from './list'
import create from './create'
import edit from './edit'

export default {
    list: list,
    create: create,
    edit: edit, 
    name: "categories",
    key: "p_categories",
}