import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import {connect} from "react-redux"

import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';

import { useNotify, useTranslate, List, useMutation, Filter, ReferenceInput, AutocompleteInput, useRefresh, useUnselectAll, usePermissions } from 'react-admin';
import { ButtonField, Pagination } from '../../../partials';
import { USER_GROUP_OWNER_AGENCY} from '../../../utils/const';
import { _formatPrice } from '../../../utils/helper';
import { useGetAllUsers } from '../../../hooks'

import ListDataGrid from "./list"
const useStyles = makeStyles(theme =>({
    selectEmployee: {
        minWidth: theme.spacing(30),
        marginRight: theme.spacing(3),
    },
}));
const BulkActionAssignButtons = (props) => {
    const {selectedIds = []} = props;
    const classes = useStyles();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll()
    const notify = useNotify();
    const [selectedEmployee, setSelectedEmployee] = useState({});
    const [inputEmployee, setInputEmployee] = useState("");
    const [onClick] = useMutation(
        {
            type: 'assign',
            resource: props.resource,
            payload: { data: {order_ids: selectedIds, employee_id: selectedEmployee.value} },
        },
        {
            onSuccess: ({ data }) => {
                notify('Assign success');
                refresh()
                unselectAll(props.resource)
            },
            onFailure: (error) => notify(`assign error: ${error.message}`, 'warning'),
        }
    );

    const handleEmployeeChange = useCallback((e) => {
        if(e.value !== selectedEmployee.value) {
            setSelectedEmployee(e)
        }
    }, [selectedEmployee.value])

    const handleInputEmployee = useCallback((e) => {
        setInputEmployee(e)
    }, []);
    const filter = useMemo(() => {
        return {
            user_group: "USER_GROUP_EMPLOYEE_AGENCY",
            status: "USER_STATUS_ACTIVE",
            ...(inputEmployee && {name: inputEmployee})
        }
    }, [inputEmployee])
    const {data: dataEmployees = []} = useGetAllUsers("employeeagencies", JSON.stringify(filter));

    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Select
                fullWidth
                label="Employee"
                value={selectedEmployee}
                onChange={handleEmployeeChange}
                options={(dataEmployees || []).map(e => {
                    return {
                        value: e.id,
                        label: e.name,
                        isDisabled: !e.is_online,
                    }
                })}
                styles={{
                    menu: styles => ({
                        ...styles,
                        zIndex: 99,
                    })
                }}
                className={classes.selectEmployee}
                inputValue={inputEmployee}
                onInputChange={handleInputEmployee}
            />
            <ButtonField name="Assign" onClick={onClick} disabled={props.permissions !== USER_GROUP_OWNER_AGENCY ? true : false}/>
        </div>
    );
};


const BulkActionFinishButtons = (props) => {
    const {selectedIds = []} = props;
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll()
    const [actionTransfer] = useMutation();
    const handleFinish = () => actionTransfer(
        {
            type: 'finish',
            resource: 'orders',
            payload: { data:{  order_ids: selectedIds } },
        },
        {
            onSuccess: ({ data }) => {
                notify('Finished');
                refresh()
                unselectAll(props.resource)
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }
    )

    return (
        <Fragment>
            <ButtonField name="Accept" onClick={handleFinish} disabled={props.permissions !== USER_GROUP_OWNER_AGENCY ? true : false}/>
        </Fragment>
    )
};

const BulkActionMarkReturnButtons = (props) => {
    const {selectedIds = []} = props;
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll()
    const [actionTransfer] = useMutation();
    const handleFinish = () => actionTransfer(
        {
            type: 'markedReturned',
            resource: 'orders',
            payload: { data:{  order_ids: selectedIds } },
        },
        {
            onSuccess: ({ data }) => {
                notify('Returned');
                refresh()
                unselectAll(props.resource)
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }
    )
    return (
        <Fragment>
            <ButtonField name="Marked Returned" disabled={props.permissions !== USER_GROUP_OWNER_AGENCY ? true : false} onClick={handleFinish}/>
        </Fragment>
    )
};
const BulkActionButtons = (props) => {
    const {selectedIds = [], resource, data = {}} = props;
    const translate = useTranslate()
    const {permissions} = usePermissions();
    const createDivIds = useCallback(() => {
        const toolbar = document.querySelector("div[data-test='bulk-actions-toolbar']");
        if(toolbar) {
            if(selectedIds && selectedIds.length) {
                toolbar.classList.add("toolbar-with-product-id");

                const ele = document.querySelector(".toolbar-with-product-id > div");
                const list = document.querySelector(".toolbar-with-product-id .list-ids");
                if(ele && !list) {
                    var div = document.createElement("ul");
                    div.setAttribute('class', 'list-ids');
                    div.textContent = "";
                    ele.appendChild(div);
                }
            }
            else {
                toolbar.classList.remove("toolbar-with-product-id");
            }
        }

    }, [selectedIds]);

    useEffect(() => {
        createDivIds()
        return function cleanup() {
            createDivIds()
        }
    })

    useEffect(() => {
        const ele = document.querySelector(".toolbar-with-product-id > div > h6");
        if(ele) {
            createDivIds()
            ele.innerHTML =  `${translate("ra.action.bulk_actions", {smart_count: selectedIds.length})}`
            const list = document.querySelector(".toolbar-with-product-id .list-ids");
            if(list) {
                list.innerHTML = ""
                let total = 0;
                let items = {};
                if(data && selectedIds && selectedIds.length) {
                    let countBuyShell = 0;
                    selectedIds.forEach(id => {
                        const e = data[id];
                        if(e) {
                            if(!e.buy_shell) countBuyShell++;
                            total += e.total_price;
                            if(e.items && e.items.length) {
                                e.items.forEach(i => {
                                    if(!items[i.product_id]){
                                        items[i.product_id] = {...i};
                                    } else {
                                        items[i.product_id].quantity += i.quantity
                                    }
                                })
                            }

                        }
                    })
                    ele.innerHTML =  `${translate("ra.action.bulk_actions", {smart_count: selectedIds.length})}. ${translate("sum", {sum: _formatPrice(total)})}. ${resource === "orderswaitingto_finish" ? translate("need_back_shell", {count: countBuyShell}) : ""}`
                    list.innerHTML = Object.values(items).map(e => `<li>${e.quantity} ${e.name}</li>`).join("\n")
                }
            }
        }

    }, [createDivIds, data, selectedIds, translate, resource])

    return (
        resource === "orderswaitingto_finish" ? <BulkActionFinishButtons {...props} permissions={permissions}/> :
        resource === "orderswaitingfor_approve_return" ? <BulkActionMarkReturnButtons {...props} permissions={permissions} /> :
        resource === "ordersonassigning" ? <BulkActionAssignButtons {...props} permissions={permissions} /> : ""
    )
};
const FilterComponents = (props) => {
    return (
        <Filter {...props}>
            <ReferenceInput allowEmpty label="Employee" source="employee_id" reference="employeeagencies"
                    filterToQuery={searchText => ({ name: searchText })}>
                <AutocompleteInput />
            </ReferenceInput>
            <ReferenceInput allowEmpty label="Customer" source="customer_id" reference="customers"
                    filterToQuery={searchText => ({ name: searchText })}>
                <AutocompleteInput />
            </ReferenceInput>
        </Filter>
    );
};

const ListComponent = ({data = {}, dispatch, history, location, match, options, ...props}) => {
    return (
        <List {...props} pagination={<Pagination />} perPage={20} title="Orders" sort={[{field: 'created_at', order: 'DESC'}, {field: 'id', order: 'ASC'}]}  filters={<FilterComponents />}
            bulkActionButtons={<BulkActionButtons data={data} />}
      >
            <ListDataGrid {...props}/>
        </List>
    );
}
const mapState = (state, ownProps) => {
    const resource = ownProps.resource
    const resources = state.admin && state.admin.resources ? state.admin.resources : {};
    const data = resources[resource] && resources[resource].data ? resources[resource].data : {};
    return {
        data: data
    }
}
export default connect(mapState, null)(ListComponent)
