export const _fileToBase64 = (files = {}) => {
    return new Promise((resolve) => {
        var reader = new FileReader();
        // Read file content on file loaded event
        reader.onloadend = function () {
            const data = {
                extension: (files.type || "").split("/")[1],
                payload: reader.result.split(",")[1],
            };
            resolve(data);
        };

        // Convert data to base64
        reader.readAsDataURL(files);
    });
};
