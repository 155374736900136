import React, {
    useMemo,
    cloneElement,
} from 'react';
import { sanitizeListRestProps } from 'ra-core';
import { makeStyles } from '@material-ui/core/styles';
import { useFieldArray } from 'react-final-form-arrays';

import {TablePagination} from '@material-ui/core';

import classnames from 'classnames';



const useStyles = makeStyles(
    theme => ({
        table: {
            tableLayout: 'auto',
        },
        tbody: {},
        row: {},
    }),
);

const TableComponent = props => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const {
        record: initRecord = {},
        basePath,
        size = 'small',
        children,
        source,
        ...rest
    } = props;
    const offset = useMemo(() => page *rowsPerPage, [page, rowsPerPage])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const fieldProps = useFieldArray(source, {
        ...rest,
    });
    const fields = fieldProps.fields;
    const total = useMemo(() => fields.length, [fields.length])
    
    return (
        <div
            className={classnames(classes.table)}
            size={size}
            {...sanitizeListRestProps(rest)}
        >
            <div className={classes.rows}>
                {cloneElement(children, {
                    ...fieldProps,
                    perPage: rowsPerPage,
                    page: page,
                    offset: offset,
                    lastPage: Math.ceil(total/rowsPerPage) - 1,
                    handleChangePage,
                    source,
                    record: initRecord,
                    fields: {
                        ...fields,
                        map: (iterator: (name: string, index: number) => any): [] => {
                            const results = []
                            for (let i = offset; i < offset + rowsPerPage && i < fields.length; i++) {
                                results.push(iterator(`${source}[${i}]`, i))
                            }
                            return results
                        }
                    },
                })}
            </div>
            <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    );
};

TableComponent.propTypes = {
    
};

TableComponent.defaultProps = {
    
};

export default TableComponent;
export {default as SimpleFormIterator} from "./SimpleFormIterator";