import React, { Fragment } from "react";

import {
    List,
    Datagrid,
    TextField,
    EditButton,
    TextInput,
    Filter,
    NumberField,
} from "react-admin";

const FilterComponents = (props) => {
    return (
        <Filter {...props}>
            <TextInput source="name" label="Name" />
        </Filter>
    );
};

const DatagridComponent = (props) => {
    return (
        <Fragment>
            <Datagrid {...props} selectedIds={[]}>
                <TextField source="id" label="ID" />
                <TextField source="name" label="Name" />
                <NumberField source="point_rate" label="Point Rate" />
                <EditButton {...props} label="Edit" />;
            </Datagrid>
        </Fragment>
    );
};

const ListComponent = (props) => {
    return (
        <List
            {...props}
            pagination={null}
            bulkActionButtons={false}
            filters={<FilterComponents />}
            sort={{ field: "created_at", order: "DESC" }}
        >
            <DatagridComponent {...props} />
        </List>
    );
};
export default ListComponent;

export { DatagridComponent };
