import React from 'react'; import { List, Datagrid, TextField, NumberField, useNotify, useRefresh, useMutation, EditButton, DeleteWithConfirmButton, TextInput, Filter, Button as RaButton, usePermissions } from 'react-admin';
import { Button, Menu, MenuItem } from "@material-ui/core"
import CheckIcon from '@material-ui/icons/Check';

import { _enumNotiStatus } from '../../utils/enum';

import { USER_GROUP_ADMIN } from '../../utils/const';
import { Pagination, EnumField, DateField, LinkField } from "../../partials"

const FilterComponents = (props) => {
    return (
        <Filter {...props}>
            <TextInput source="title" label="Title" />
        </Filter>
    );
};


const ExportButton = ({ record, resource }) => {
    const refresh = useRefresh();
    const notify = useNotify();

    let title = 'Export'

    const [exportHandler, { loading }] = useMutation({
        type: 'export',
        resource: resource,
        payload: { id: record.id }
    },
        {
            onSuccess: ({ data }) => {
                notify(title +' success');
                refresh()
            },
            onFailure: (error) => notify(title +` error: ${error.message}`, 'warning'),
        });

    return <RaButton label={title} onClick={exportHandler} disabled={loading}><CheckIcon /></RaButton>;
};

const ButtonComponent = (props) => {
    const { record = {} } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true"
                disabled={record.status === "NOTIFICATION_MASTER_STATUS_DELETED"}
                onClick={handleClick}>...</Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem fullWidth onClick={handleClose}><EditButton {...props} /></MenuItem>
                <MenuItem fullWidth onClick={handleClose}><ExportButton {...props} /></MenuItem>
      </Menu>
        </div>
    )
};

const ListComponent = (props) => {
    const { permissions: auth } = usePermissions();

    return (
        <List {...props} pagination={<Pagination />} perPage={20} bulkActionButtons={false}/*  filters={<FilterComponents />} */ sort={{ field: 'created_at', order: 'DESC' }} >
            <Datagrid>
                <TextField source="name" label="Name" />
                <TextField source="description" label="Description" />
                <EnumField source="status" label="Status" _enum={_enumNotiStatus} prefix="NOTIFICATION_MASTER_STATUS_" />
                <DateField source="start_time" label="Start Time" />
                <DateField source="end_time" label="End Time" />
                <TextField source="event_url" label="Url" />
                <NumberField source="total_user_joined" label="Total User Joined" />
                <ButtonComponent  {...props} />
            </Datagrid>
        </List>
    );
}

export default ListComponent
