import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Admin, Layout, Resource, Authenticated, useLocale } from "react-admin";
import { Route } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import ImportProduct from "./components/products/import";
import ImportProductMetadata from "./components/productmetadatas/import";
import ImportCategory from "./components/categories/import";
import SalaryDetail from "./components/employeeSalary/detail";
import {
    employeesalary,
    transferrequests,
    profile,
    config,
    appbar,
    sidebars,
    users,
    agencies,
    categories,
    products,
    productmetadatas,
    warehouseagencies,
    warehousecompanies,
    warehouseagenciestransfer,
    warehousecompaniesimport,
    configagencies,
    orders,
    vipCustomer,
    notifications,
    dashboard,
    agencyCustomers,
    partners,
    agencyTypes,
    events,
} from "./components";
import {
    _arrayOrderStatus,
    _configGroupByPermission,
    _arrayTransferStatus,
} from "./utils/enum";
import { USER_GROUP_ADMIN, USER_GROUP_OWNER_AGENCY } from "./utils/const";
import { _clientUserStorage } from "./utils/storage";
import { auth, i18n, data } from "./providers";
import {
    ThemeContext,
    AgencyContext,
    ProductContext,
    OrderOnAssigningContext,
} from "./context";
import { useGetAllProducts, useOrderOnAssigning } from "./hooks";
import LoginPage from "./components/login/loginV2";
import { _decodedToken } from "./utils/helper";

const useStyles = makeStyles((theme) => ({
    appFrame: {
        width: "100%",
    },
    contentWithSidebar: {
        width: "100%",
    },
    content: {
        flex: "1 1 100%",
        padding: theme.spacing(3),
        overflow: "hidden",
        boxSizing: "border-box",
    },
}));

const MyLayout = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { data: dataProducts = [], loading } = useGetAllProducts(true);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);
    const { total, onClear, onUpdate } = useOrderOnAssigning({ handleOpen });
    const user = useMemo(() => _clientUserStorage.get() || {}, []);

    const handleClose = useCallback(
        (e) => {
            // e.preventDefault()
            setOpen(false);
            onClear();
        },
        [onClear]
    );
    useEffect(() => {
        async function handleRefreshToken() {
            const { token: newToken } = await auth.checkAuth();
            const newDecoded = _decodedToken(newToken);
            return newDecoded;
        }

        void (async function () {
            //handle refresh token
            const newDecoded = await handleRefreshToken();
            const min = (newDecoded.exp - newDecoded.iat) / 60;
            setInterval(() => {
                handleRefreshToken();
            }, (min - 3) * 60 * 1000);
        })();
    }, []);
    return (
        <AgencyContext.Provider value={user.agency_id}>
            <OrderOnAssigningContext.Provider
                value={{ total, onClear, onUpdate, open, handleClose }}
            >
                <ProductContext.Provider
                    value={{ products: dataProducts, loading }}
                >
                    <Layout
                        {...props}
                        appBar={appbar}
                        menu={sidebars}
                        classes={{
                            appFrame: classes.appFrame,
                            contentWithSidebar: classes.contentWithSidebar,
                            content: classes.content,
                        }}
                    />
                </ProductContext.Provider>
            </OrderOnAssigningContext.Provider>
        </AgencyContext.Provider>
    );
};

const listOrder = _arrayOrderStatus
    .filter((e) => e.name !== "NONE")
    .map((e) => {
        let name = "orders" + e.name.replace("_", "").toLowerCase();
        return {
            ...orders,
            name: name,
            key: `p_${name}`,
        };
    });

const listTransfer = _arrayTransferStatus
    .filter((e) => e.name !== "NONE")
    .map((e) => {
        let name =
            "transferrequests" +
            e.name.replace("TRANSFER_STATUS_", "").toLocaleLowerCase();
        return {
            ...transferrequests,
            name: name,
            key: `p_${name}`,
        };
    });

const App = () => {
    const locale = useLocale();
    return (
        <ThemeContext.Provider
            value={{
                lang: locale,
            }}
        >
            <Admin
                loginPage={LoginPage}
                dataProvider={data}
                authProvider={auth}
                i18nProvider={i18n}
                customRoutes={[
                    <Route
                        path="/employeesalaries/detail/:id"
                        component={(props) => (
                            <Authenticated>
                                <SalaryDetail
                                    {...props}
                                    resource="employeesalaries"
                                    basePath="/employeesalaries/detail"
                                />
                            </Authenticated>
                        )}
                    />,
                    <Route
                        path="/categories/import"
                        component={(props) => (
                            <Authenticated>
                                <ImportCategory
                                    {...props}
                                    resource="categories"
                                    basePath="/categories/import"
                                />
                            </Authenticated>
                        )}
                    />,
                    <Route
                        path="/products/import"
                        component={(props) => (
                            <Authenticated>
                                <ImportProduct
                                    {...props}
                                    resource="products"
                                    basePath="/products/import"
                                />
                            </Authenticated>
                        )}
                    />,
                    <Route
                        path="/productmetadatas/import"
                        component={(props) => (
                            <Authenticated>
                                <ImportProductMetadata
                                    {...props}
                                    resource="productmetadatas"
                                    basePath="/productmetadatas/import"
                                />
                            </Authenticated>
                        )}
                    />,
                ]}
                layout={MyLayout}
            >
                {(auth) => {
                    if (auth === USER_GROUP_ADMIN) {
                        return [
                            {
                                ...users,
                                name: "admins",
                                key: "p_admins",
                            },
                            {
                                ...users,
                                name: "nones",
                                key: "p_nones",
                            },
                            {
                                ...users,
                                name: "employees",
                                key: "p_employees",
                            },
                            {
                                ...users,
                                name: "owneragencies",
                                key: "p_owneragencies",
                            },
                            {
                                ...users,
                                name: "employeeagencies",
                                key: "p_employeeagencies",
                            },
                            {
                                ...users,
                                name: "customers",
                                key: "p_customers",
                            },
                            {
                                ...config,
                                name: "configs",
                                key: "p_configs",
                            },
                            dashboard,
                            notifications,
                            vipCustomer,
                            agencies,
                            categories,
                            products,
                            productmetadatas,
                            warehouseagencies,
                            warehousecompanies,
                            transferrequests,
                            employeesalary,
                            warehouseagenciestransfer,
                            warehousecompaniesimport,
                            configagencies,
                            profile,
                            orders,
                            partners,
                            agencyTypes,
                            ...listTransfer,
                            ..._configGroupByPermission(
                                USER_GROUP_ADMIN,
                                ""
                            ).map((e) => {
                                let name = `configs${e.id.toLowerCase()}`;
                                return {
                                    ...config,
                                    name: name,
                                    key: `p_${name}`,
                                };
                            }),
                        ].map((e) => {
                            return <Resource {...e} />;
                        });
                    } else if (auth === USER_GROUP_OWNER_AGENCY) {
                        return [
                            dashboard,
                            {
                                ...users,
                                name: "employeeagencies",
                                key: "p_employeeagencies",
                            },
                            {
                                ...users,
                                name: "owneragencies",
                                key: "p_owneragencies",
                            },
                            {
                                ...users,
                                name: "customers",
                                key: "p_customers",
                            },
                            agencyCustomers,
                            notifications,
                            events,
                            vipCustomer,
                            agencies,
                            categories,
                            products,
                            productmetadatas,
                            warehouseagencies,
                            employeesalary,
                            warehouseagenciestransfer,
                            configagencies,
                            orders,
                            profile,
                            ...listOrder,
                        ].map((e) => {
                            return <Resource {...e} />;
                        });
                    }
                }}
            </Admin>
        </ThemeContext.Provider>
    );
};

export default App;
