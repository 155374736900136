import React from 'react';
import Chip from '@material-ui/core/Chip';

import { TextField, useTranslate } from 'react-admin';
import PropTypes from "prop-types";
import classNames from "classnames"
import { makeStyles } from '@material-ui/core/styles';

import { _getString } from '../utils/enum';
import { _getLodash, _setLodash } from '../utils/helper';

const useStyles = makeStyles(theme => ({
    status: {
        textTransform: "uppercase",
    },
    // user status
    active: {
        backgroundColor: "#26bb13",
        color: "#fff",
    },
    ban: {
        backgroundColor: "#c74732",
        color: "#fff"
    },
    inactive: {
        backgroundColor: "#c74732",
        color: "#fff"
    },

    //agency status
    close: {
        backgroundColor: "#c74732",
        color: "#fff"
    },

    //transfer
    canceled: {
        backgroundColor: "#c74732",
        color: "#fff"
    },
    requested: {
        backgroundColor: "#2f9fff",
        color: "#fff"
    },
    accepted: {
        backgroundColor: "#ff422f",
        color: "#fff"
    },
    on_delivering: {
        backgroundColor: "#f1c447",
    },
    delivered: {
        backgroundColor: "#9415ab",
        color: "#fff"
    },
    done: {
        backgroundColor: "#26bb13",
        color: "#fff"
    },
    on_returning: {
        backgroundColor: "#29dcf9",
    },
    returned: {
        backgroundColor: "#d8106c",
        color: "#fff"
    },

    //order
    assigned: {
        backgroundColor: "#f1c447",
    },
    on_assigning: {
        backgroundColor: "#d8106c",
        color: "#fff"
    },
    waiting_to_finish: {
        backgroundColor: "#29dcf9",
    },


    //noti status
    sending: {
        backgroundColor: "#f1c447",
    },
    pending: {
        backgroundColor: "#d8106c",
        color: "#fff"
    },
    sent: {
        backgroundColor: "#26bb13",
        color: "#fff"
    },
    deleted: {
        backgroundColor: "#c74732",
        color: "#fff"
    },
}));

const EnumField = ({ _enum = {}, prefix = "", ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();
    let { record: initialRecord = {}, source = "", label = "", addLabel = true } = props;
    let record = JSON.parse(JSON.stringify(initialRecord))
    let value = _getLodash(record, source).trim();
    prefix = prefix.trim()
    if (value && value.indexOf(prefix) === 0) {
        if (_enum) {
            value = _getString(value, _enum) || ""
        }
        value = value.replace(prefix, "")
        record = _setLodash(record, source, value);
    }

    if (source === "status" && value) {
        if (value === "NONE") {
            return "";
        } else {
            return <Chip record={record} source={source} label={translate(`status.${value.toLowerCase()}`).indexOf("status.") === 0 ? value : translate(`status.${value.toLowerCase()}`)} className={classNames(classes.status, classes[value.toLowerCase()])} />
        }
    }

    return (
        <TextField fullWidth record={record} source={source} label={label} addLabel={addLabel} />
    );
};
EnumField.propTypes = {
    record: PropTypes.object,
    _enum: PropTypes.object,
    source: PropTypes.string,
    prefix: PropTypes.string,
}

EnumField.defaultProps = {
    addLabel: true,
    record: {},
    prefix: "",
    source: "",
    _enum: {},
}
export default EnumField;
