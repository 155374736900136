import React, { useEffect } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
  DateTimeInput,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { USER_GROUP_ADMIN } from "../../utils/const";
import { _clientUserStorage } from "../../utils/storage";
import { _arrayUserGroup } from "../../utils/enum";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        },
    },
}));

const CreateComponent = (props) => {
    const classes = useStyles();
    return (
        <Create {...props} title="Create">
            <SimpleForm className={classes.form} redirect={"list"}>
        <Grid container spacing={3}>
            <Grid item sm={12}>
                <TextInput label="name" source="name" fullWidth />
            </Grid>
            <Grid item sm={12}>
                <TextInput label="Description" source="description" multiline rows={4} fullWidth />
            </Grid>
            <Grid item sm={12}>
                <SelectInput
                    label="Status"
                    source="status"
                    choices={[{
                      id: "active",
                      name: "Active"
                    }, {
                      id: "inactive",
                      name: "Inactive"
                    }]}
                    fullWidth
                    defaultValue={1}
                />
            </Grid>
                    <Grid item md={4} sm={12}>
                        <DateTimeInput
                            label="Start Date"
                            source="start_time"
                            defaultValue={new Date()}
                            parse={v => moment(v).format("YYYY-MM-DDTHH:mm:ssZ")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} sm={12}>
                        <DateTimeInput
                            label="End Date"
                            source="end_time"
                            defaultValue={new Date()}
                            parse={v => moment(v).format("YYYY-MM-DDTHH:mm:ssZ")}
                            fullWidth
                        />
                    </Grid>

            <Grid item sm={12}>
                <TextInput label="Event URL" source="event_url" multiline rows={4} fullWidth />
            </Grid>

                   </Grid>

            </SimpleForm>
        </Create>
    );
};

export default CreateComponent;
