import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  useQuery,
} from "react-admin";

// import CompleteAddress from '../completeAddress';
import { _choiceAgencyStatus } from "../../utils/enum";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    "& > div > div > div:first-child": {
      width: "100%",
    },
  },
}));
const CreateComponent = (props) => {
  const classes = useStyles();
  const { data = {}, loading } = useQuery({
    type: "getAgencyTypes",
    resource: "agencies",
    payload: {
      filter: {},
      pagination: {},
      sort: {},
    },
  });
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <Edit {...props} undoable={false} title={`Update ${props.resource}`}>
      <SimpleForm className={classes.form} redirect="show">
        <Grid container spacing={3}>
          <Grid item sm={4}>
            <TextInput label="Name" source="name" fullWidth />
          </Grid>
          <Grid item sm={4}>
            <TextInput label="Phone" source="phone_number" fullWidth />
          </Grid>
          <Grid item sm={4}>
            <SelectInput
              label="Status"
              source="status"
              optionValue={"name"}
              choices={_choiceAgencyStatus}
              fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <TextInput label="Address" source="address" fullWidth />
          </Grid>
          <Grid item sm={4}>
            <NumberInput label="Latitude" source="latitude" fullWidth />
          </Grid>
          <Grid item sm={4}>
            <NumberInput label="Longitude" source="longitude" fullWidth />
          </Grid>
          <Grid item sm={4}>
            <NumberInput
              label="Radius"
              source="radius"
              initialValue={10}
              fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <SelectInput
              label="Agency Type"
              source="agency_type"
              choices={data}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default CreateComponent;
