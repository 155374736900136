import React from 'react';

import { List, Filter, ReferenceInput, AutocompleteInput } from 'react-admin';

import ListDataGrid from "../../orders/list/list"
import {Pagination} from "../../../partials";

const FilterComponents = (props) => {
    return (
        <Filter {...props}>
            {props.basePath === "/employeeagencies" && <ReferenceInput allowEmpty label="Employee" source="employee_id" reference="employeeagencies" 
                    filterToQuery={searchText => ({ name: searchText })}>
                <AutocompleteInput />
            </ReferenceInput>}
            {props.basePath === "/customers" && <ReferenceInput allowEmpty label="Customer" source="customer_id" reference="customers"
                    filterToQuery={searchText => ({ name: searchText })}>
                <AutocompleteInput />
            </ReferenceInput>}
        </Filter>
    );
};

const ListComponent = (props) => {
    return (
        <List {...props} pagination={<Pagination />}  perPage={20} title=" " style={{marginTop: 16}} resource="orders" sort={{ field: 'created_at', order: 'DESC' }} filters={<FilterComponents {...props} />} bulkActionButtons={false}>
            <ListDataGrid {...props} resource="orders" />
        </List>
    );
}
export default ListComponent
