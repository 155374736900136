import React from "react";
import { Grid } from "@material-ui/core";

import {
  Show,
  SimpleShowLayout,
  TextField,
  ImageField,
  UrlField,
  BooleanField,
  NumberField,
  ArrayField,
  Datagrid,
  ReferenceField,
} from "react-admin";
import { GridField } from "../../partials";
import DataGridAgencyList from "../agencies/agencyDataGrid";

const GridComponent = (props) => {
  return (
    <Grid container spacing={3}>
      <GridField {...props} item sm={3}>
        <TextField source="app_name" label="Name" />
      </GridField>
      <GridField {...props} item sm={3}>
        <TextField source="gift_id" label="Gift ID" />
      </GridField>
      <GridField {...props} item sm={3}>
        <BooleanField source="is_show_all" label="Show All" />
      </GridField>
      <GridField {...props} item sm={3}>
        <ImageField source="app_icon" label="Icon" />
      </GridField>
      <GridField {...props} item sm={4}>
        <UrlField source="appstore_url" label="Appstore" />
      </GridField>
      <GridField {...props} item sm={4}>
        <UrlField source="chplay_url" label="CHPlay" />
      </GridField>
      <GridField {...props} item sm={4}>
        <ReferenceField
          label="Agency Type"
          source="agency_type_id"
          reference="agency_types"
        >
          <TextField source="name" />
        </ReferenceField>
      </GridField>
      <GridField {...props} item sm={3}>
        <BooleanField source="in_system" label="In system" />
      </GridField>
      <GridField {...props} item sm={12}>
        <ArrayField source="categories" label="Categories">
          <Datagrid>
            <TextField source="id" label="Id" />
            <TextField source="name" label="Name" />
            <ArrayField source="levels" label="Levels">
              <Datagrid>
                <NumberField source="level" label="Level" />
                <TextField source="name" label="Name" />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ArrayField>
      </GridField>
      <DataGridAgencyList
        {...props}
        bulkActionButtons={false}
        style={{
          width: "100%",
        }}
        exporter={false}
        filters={false}
        resource="agencies"
        filter={{
          id: props.record?.agency_ids,
        }}
      />
    </Grid>
  );
};
const ShowComponent = (props) => {
  return (
    <Show {...props} title={`Detail ${props.resource}`}>
      <SimpleShowLayout>
        <GridComponent />
      </SimpleShowLayout>
    </Show>
  );
};

export default ShowComponent;
