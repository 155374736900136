import React from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Filter,
  NumberField,
  BooleanField,
  useRedirect,
  ImageField,
  usePermissions,
} from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

import { LinkField, PriceField, DateField, Pagination } from "../../partials";
import { USER_GROUP_ADMIN } from "../../utils/const";
import ExportButton from "../exportWarehouse";

const FilterComponents = (props) => {
  return (
    <Filter {...props}>
      <TextInput source="name" label="Name" />
    </Filter>
  );
};

const ActionComponents = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => {
  const redirect = useRedirect();
  const onClick = () => {
    redirect("/warehousecompaniesimport");
  };
  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <Button color="primary" onClick={onClick}>
        Planing
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </Toolbar>
  );
};
const DatagridComponent = ({ hasShow, hasEdit, hasList, ...props }) => {
  const { permissions: auth } = usePermissions();
  const rowStyle = (record, index) => ({
    backgroundColor:
      (record && record.quantity <= 0)
        ? "#dadada"
        : (record && record.quantity < 10)
        ? "#fffedb"
        : "unset",
  });
  return (
    <Datagrid {...props} rowStyle={rowStyle}>
      {auth === USER_GROUP_ADMIN ? (
        <LinkField
          reference="productmetadatas"
          field="name"
          source="product_metadata_id"
          label="Product Metadata ID"
        />
      ) : (
        ""
      )}
      <TextField source="name" label="Name" />
      <ImageField source="images[0]" label="Images" />
      <TextField source="description" label="Description" />
      <PriceField source="unit_price" label="Unit Price" />
      <PriceField source="sale_price" label="Sale Price" />
      <PriceField source="shell_price" label="Shell Price" />
      <NumberField source="unit_point" label="Unit Point" />
      <NumberField source="net_weight" label="Net Weight" />
      <NumberField source="quantity" label="Quantity" />
      <BooleanField source="deleted" label="Deleted" />
      <DateField source="created_at" label="Created" />
    </Datagrid>
  );
};

const ListComponent = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination />}
      perPage={20}
      bulkActionButtons={false}
      filters={<FilterComponents />}
      sort={{ field: "cw.created_at", order: "DESC" }}
      actions={<ActionComponents />}
    >
      <DatagridComponent {...props} />
    </List>
  );
};
export default ListComponent;
export { DatagridComponent };
