import { _endpointEvent } from "../../utils/provider";
import { _createRequest, _getFilter, _pick, _fetch } from "../../utils/helper";
import { USER_GROUP_OWNER_AGENCY } from "../../utils/const";

const endpoint = async (props) => {
    const { type, user = {} } = props;
    let params = JSON.parse(JSON.stringify(props.params || {}));

    if (user.group === USER_GROUP_OWNER_AGENCY) {
        // if (params.filter) params.filter.agency_id = user.agency_id;
        // if (params.data) params.data.agency_id = user.agency_id;
    }

    switch (type) {
        case "create": {
            return _fetch(
                _createRequest(_endpointEvent.create, true, _pick(params.data, [
              "name",
              "description",
              "event_url",
              "start_time",
              "end_time",
              "status",
            ]))
            );
        }
        case "export": {
            return _fetch(
                _createRequest(_endpointEvent.export, true, {event_id: params.id})
            ).then((res) => {
                let csvContent = "data:text/csv;charset=utf-8,input\n"
                    + res.data.users.map(e => {
                      return `******${e.phone_number.substr(e.phone_number.length - 5)} - ${e.name} - ${e.address} - ${e.lucky_code}`;
                    }).join("\n");

                var downloadLink = document.createElement("a");

                var encodedUri = encodeURI(csvContent);
                downloadLink.href = encodedUri;
                downloadLink.download = "data.csv";

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                // window.open(encodedUri);
                return {
                    data: params.id,
                };
            });
        }

        case "update": {
            return _fetch(
                _createRequest(_endpointEvent.update, true, _pick(params.data, [
                  "id",
              "name",
              "description",
              "event_url",
              "start_time",
              "end_time",
              "status",
            ]))
            ).then((res) => {
                return {
                    data: params.id,
                };
            });
        }
        case "delete": {
            return _fetch(
                _createRequest(_endpointEvent.delete, true, {
                    ..._pick(params, ["id"]),
                })
            ).then((res) => {
                return {
                    data: params.id,
                };
            });
        }
        default: {
            //list
            return _fetch(
                _createRequest(_endpointEvent.list, true, {
                   filter: `${_getFilter(params, {})}`,
                })
            );
        }
    }
};

export default endpoint;
