import {_endpointWarehouseAgency} from '../../utils/provider';
import {_createRequest, _getFilter, _fetch, _pick} from '../../utils/helper'
const endpoint = (props) => {
    const {type, params = {}} = props;
    const objectFilter = {agency_id: "eq", quantity: "lte"}
    params.filter = params.filter || {}
    const sort = !Array.isArray(params.sort) ? [params.sort] : params.sort
    
    switch(type) {
        case "update": {
            return _fetch(_createRequest(_endpointWarehouseAgency.editProduct, true, {
                ..._pick(params.data, ["agency_id", "product_id", "sale_price"]),
            }))
        }
        case "all": {
            const getAll = (page = 1, data = []) => {
                const perPage = 100;
                params.pagination = {
                    perPage: perPage,
                    page: page
                }
                return _fetch(_createRequest(_endpointWarehouseAgency.statistic, true, {
                    filter: _getFilter(params, objectFilter)
                })).then(res => {
                    if (res.total === undefined || res.total <= page * perPage) {
                        return {
                            ...res,
                            data: [...data, ...res.data]
                        }
                    } else {
                        return getAll(page + 1,  [...data, ...res.data])

                    }
                })
            }
            return getAll(1)
        }
        case "many": {
            const {target = "id"} = params;
            if(params.ids) {
                params.filter = {
                    ...params.filter,
                    [target]: params.ids,
                }
            }
            return _fetch(_createRequest(_endpointWarehouseAgency.statistic, true, {
                filter: _getFilter(params, objectFilter)
            }))
            .then(res => {
                if(params && params.pagination && (params.pagination.perPage*params.pagination.page) < res.total) {
                    return endpoint({
                        ...props,
                        pagination: {
                            ...props.pagination,
                            page: params.pagination.page+1
                        }
                    })
                }
                return res
            })
        }
        case "manyReference": {
            const {target = "id"} = params;
            if(params.ids) {
                params.filter = {
                    ...params.filter,
                    [target]: params.ids,
                }
            }
            return _fetch(_createRequest(_endpointWarehouseAgency.statistic, true, {
                filter: _getFilter(params, objectFilter)
            }))
        }
        default: { //list
            return _fetch(_createRequest(_endpointWarehouseAgency.statistic, true, {
                filter: _getFilter({
                    ...params,
                    sort: [
                        ...sort,
                        {field: "id", order :"ASC"}
                    ]
                },
                {
                    ...objectFilter,
                    quantity: "lt",
                })
            }))
        }
    }
}

export default endpoint
