import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteWithConfirmButton, BooleanField, TextInput, Filter, ImageField } from 'react-admin';
import {Button, Menu, MenuItem} from "@material-ui/core"
import ActionComponent from "./actions";
import {Pagination} from "../../partials"
const FilterComponents = (props) => {
    return (
        <Filter {...props}>
            <TextInput
                label="ID"
                source="id"
            />
            <TextInput source="name" label="Name" />
        </Filter>
    );
};

const ButtonComponent = (props) => {
    const {record = {}} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>...</Button>
            <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            >
                <MenuItem onClick={handleClose}><EditButton {...props}/></MenuItem>
                {!record.deleted && <MenuItem onClick={handleClose}>
                    <DeleteWithConfirmButton {...props} />
                </MenuItem>}
        </Menu>
    </div>
    )
};


const ListComponent = (props) => {
    return (
        <List {...props} pagination={<Pagination />}  perPage={20} bulkActionButtons={false} filters={<FilterComponents />} sort={{ field: 'created_at', order: 'DESC' }} actions={<ActionComponent />}>
            <Datagrid>
                <TextField source="id" label="ID"/>
                <TextField source="name" label="Name"/>
                <TextField source="description" label="Description"/>
                <ImageField label="Images" source="images[0]"/>
                <TextField source="priority" label="Priority"/>
                <BooleanField source="deleted" label="Deleted"/>
                <ButtonComponent />
            </Datagrid>
        </List>
    );
}

export default ListComponent