import { _endpointAgency } from "../../utils/provider";
import { _createRequest, _getFilter, _pick, _fetch } from "../../utils/helper";
const agenciesEndpoint = (props) => {
  const { type, params = {} } = props;
  switch (type) {
    case "getAgencyTypes": {
      return _fetch(_createRequest(_endpointAgency.getAgencyTypes, true, {}));
    }
    case "create": {
      return _fetch(
        _createRequest(_endpointAgency.create, true, {
          ..._pick(params.data, [
            "email",
            "password",
            "name",
            "address",
            "phone_number",
            "status",
            "latitude",
            "longitude",
            "radius",
            "agency_type",
          ]),
        })
      ).then((res) => {
        res.data = res.data || {};
        if (res.rawData) res.data.agency_id = res.rawData.agency_id;
        res.data.id = res.data.agency_id;
        return res;
      });
    }
    case "one": {
      return _fetch(
        _createRequest(_endpointAgency.one, true, {
          ..._pick(params, ["id"]),
        })
      ).then((res) => {
        res.data = res.data || {};
        if (res.rawData) {
          res.data = res.rawData.agency;
        }
        if (res.data && !res.data.id) {
          res.data.id = params.id;
        }
        return res;
      });
    }
    case "update": {
      return _fetch(
        _createRequest(_endpointAgency.update, true, {
          ..._pick(params.data, [
            "id",
            "name",
            "address",
            "phone_number",
            "status",
            "latitude",
            "longitude",
            "radius",
            "agency_type",
          ]),
        })
      );
    }
    case "delete": {
      return _fetch(
        _createRequest(_endpointAgency.delete, true, {
          ..._pick(params, ["id"]),
        })
      ).then((res) => {
        res.data = res.data || {};
        if (res.data) res.data.id = params.id;
        return res;
      });
    }
    case "many": {
      params.filter = {
        ...params.filter,
        id: params.ids,
      };
      params.pagination = {
        ...params.pagination,
        perPage: (params.ids || []).length || 10,
      };
      return _fetch(
        _createRequest(_endpointAgency.list, true, {
          filter: _getFilter(params, { id: "in" }),
        })
      );
    }
    case "all": {
      //list
      const getAll = (page = 1, data = []) => {
        const perPage = 100;
        params.pagination = {
          perPage: perPage,
          page: page,
        };
        return _fetch(
          _createRequest(_endpointAgency.list, true, {
            filter: `${_getFilter(params, {})}`,
          })
        ).then((res) => {
          if (res.total <= page * perPage) {
            return {
              ...res,
              data: [...data, ...res.data],
            };
          } else {
            return getAll(page + 1, [...data, ...res.data]);
          }
        });
      };
      return getAll(1);
    }
    default: {
      //list
      return _fetch(
        _createRequest(_endpointAgency.list, true, {
          filter: `${_getFilter(params, {})}`,
        })
      );
    }
  }
};

export default agenciesEndpoint;
