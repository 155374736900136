import React from 'react';
import { List, Datagrid, TextField, TextInput, Filter, SelectInput, usePermissions } from 'react-admin';
import {LinkField, UserField, PriceField, EnumField, DateField, Pagination} from '../../partials';
import {_enumTransferStatus, _arrayTransferStatus} from '../../utils/enum';
import {USER_GROUP_ADMIN} from '../../utils/const';
import ActionComponent from "./actions";
import {_clientUserStorage} from "../../utils/storage";

const FilterComponents = (props) => {
    return (
        <Filter {...props}>
            <TextInput source="agency_id" label="Agency ID" />
            <SelectInput source="status" label="Status" choices={_arrayTransferStatus} optionValue="name"/>
        </Filter>
    );
};



const DatagridComponent = (props) => {
    const { permissions: auth } = usePermissions();
    return (
        <Datagrid {...props}>
            {auth === USER_GROUP_ADMIN ? <LinkField reference="agencies" field="name" source="agency_id" label="Agency"/> : ""}
            <UserField source="transfer_by" label="Transfer by"/>
            <DateField source="transfer_at" label="Transfer at"/>
            <TextField source="note" label="Note"/>
            <EnumField source="status" label="Status" _enum={_enumTransferStatus} prefix="TRANSFER_STATUS_"/>
            <TextField source="total_products" label="Total Products"/>
            <PriceField source="total_price" label="Total Prices"/>
            <DateField source="created_at" label="Created"/>
            <ActionComponent {...props} label="Action"/>
        </Datagrid>
    );
}
const ListComponent = (props) => {
    const user = _clientUserStorage.get() || {}
    const  agencyId = user.agency_id;
    return (
        <List {...props} pagination={<Pagination />} perPage={20} 
            title="Warehouse Transfer" bulkActionButtons={false} filter={{agency_id: agencyId}} filters={<FilterComponents />} 
            sort={[{field: 'created_at', order: 'DESC'}, {field: 'id', order: 'ASC'}]} 
        >
            <DatagridComponent {...props}/>
        </List>
    );
}
export {DatagridComponent}
export default ListComponent
