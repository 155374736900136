import React from 'react';
import {Collapse, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LabelIcon from '@material-ui/icons/Label';

const useStyles = makeStyles(theme => ({
    icon: {
        minWidth: theme.spacing(5)
    },
    collapse: {
        paddingLeft: theme.spacing(2)
    }
}));

const SubMenu = ({children, icon: PropsIcon, primary}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemIcon className={classes.icon}>
                    {PropsIcon ? <PropsIcon/> : <LabelIcon/>}
                </ListItemIcon>
                <ListItemText primary={primary} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit className={classes.collapse}>
                <List component="div" disablePadding>
                    {children}
                </List>
            </Collapse>
        </>
    );
}

export default SubMenu