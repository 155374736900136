
import React from 'react';
import { ImageInput, ImageField } from 'react-admin';
import { useField } from 'react-final-form';

import {_fileToBase64} from '../../utils/base64'

const InputComponent = (props) => {
    const { 
        input: { onChange: onChangeBase },
    } = useField(props.nameBase);
    
    const { 
        input: { onChange },
    } = useField(props.source);

    const handleChange = async(files) => {
        if(!props.multiple) {
            files = [files];
        }
        onChange(files)

        let promise = files.map(async e => {
            return _fileToBase64(e)
        })
        return Promise.all(promise).then(res => {
            onChangeBase({
                target: {
                    value: res
                }
            })
        }).catch(err => {
            console.log(err)
        })
    }
    
    return (
        <ImageInput {...props} accept="image/*" placeholder={<p>Drop your file here</p>} onChange={handleChange}>
            <ImageField source="src" title="Image" />
        </ImageInput>
    )
};

export default InputComponent;
