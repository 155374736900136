import moment from "moment";
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Edit, SimpleForm, TextInput, SelectInput, DateTimeInput } from 'react-admin';
import { useForm } from 'react-final-form';

import { _clientUserStorage } from "../../utils/storage";

const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
}));

const CreateComponent = (props) => {
    const classes = useStyles();
    return (
        <Edit {...props} title="Edit" >
            <SimpleForm className={classes.form} redirect={"list"} >
        <Grid container spacing={3}>
            <Grid item sm={12}>
                <TextInput label="name" source="name" fullWidth autoFocus />
            </Grid>
            <Grid item sm={12}>
                <TextInput label="Description" source="description" multiline rows={4} fullWidth />
            </Grid>
            <Grid item sm={12}>
                <SelectInput
                    label="Status"
                    source="status"
                    choices={[{
                      id: "active",
                      name: "Active"
                    }, {
                      id: "inactive",
                      name: "Inactive"
                    }]}
                    fullWidth
                    defaultValue={1}
                />
            </Grid>
                    <Grid item md={4} sm={12}>
                        <DateTimeInput
                            label="Start Date"
                            source="start_time"
                            defaultValue={new Date()}
                            parse={v => moment(v).format("YYYY-MM-DDTHH:mm:ssZ")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} sm={12}>
                        <DateTimeInput
                            label="End Date"
                            source="end_time"
                            defaultValue={new Date()}
                            parse={v => moment(v).format("YYYY-MM-DDTHH:mm:ssZ")}
                            fullWidth
                        />
                    </Grid>

            <Grid item sm={12}>
                <TextInput label="Event URL" source="event_url" multiline rows={4} fullWidth />
            </Grid>
        </Grid>
            </SimpleForm >
        </Edit >
    )
};

export default CreateComponent;
