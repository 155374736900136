import React, {useMemo} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery,TopToolbar, Show, SimpleShowLayout, Datagrid, TextField, DateField, ArrayField, NumberField } from 'react-admin';
import {EnumField, PriceField} from '../../partials';
import {_enumImportStatus} from '../../utils/enum';
import ActionAdmin from "./actions";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        "& > div > div:first-child": {
            width: "100%",
        },
        marginBottom: theme.spacing(1)
    },
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
}));

const ActionComponent = (props) => {
    const requireProps = {
        ...props,
        permissions: props.permissions,
        record: props.data,
        basePath: props.basePath,
        resource: props.resource,
        input: undefined,
        isShow: true,
        nameBtn: "Action"
    }
    
    return (
        <TopToolbar>
            <ActionAdmin {...requireProps} />
        </TopToolbar>
    );
}

const ItemsComponent = ({record, ...props}) => {
    const productIDs = useMemo(() => (record.items || []).map((e = {}) => e.id), [record.items])
    const {data: products = []} = useQuery({ 
        type: 'list',
        resource: 'warehousecompanies',
        payload: { 
            filter: productIDs && productIDs.length ? {
                "product_id": productIDs || [],
            } : {},
            pagination: {
                perPage: productIDs.length || 1,
            }
        }
    })
    const newRecord = JSON.parse(JSON.stringify(record));
    newRecord.items = (newRecord.items || []).map(e => {
        const find = products.find(i => i.id === e.id) || {};
        e.warehousecompany_quantity = find.quantity;
        return e;
    })
    const rowStyle = (record, index) => {
        return {
            backgroundColor: record.warehousecompany_quantity === 0 ? "#dadada" : record.quantity > record.warehousecompany_quantity ? '#fffedb' : "unset",
        }
    };
    return (
         <ArrayField record={newRecord} source="items">
            <Datagrid rowStyle={rowStyle}>
                <TextField source="name" fullWidth label="Product" />
                <NumberField label="Warehouse" source={"warehousecompany_quantity"} fullWidth />
                <NumberField label="Quantity" source={"quantity"} fullWidth />
                <PriceField label="Price" source={"unit_price"} fullWidth />
            </Datagrid>
         </ArrayField>
    )
};

const CreateComponent = (props) => {
    const classes = useStyles();
    return (
        <Show {...props} actions={<ActionComponent {...props}/>} title={`Detail Import Request`}>
            <SimpleShowLayout {...props} className={classes.form}>
                <DateField label="Import at" source="import_product.import_at" fullWidth />
                <TextField label="Note" source="import_product.note" multiline rows={3} fullWidth />
                <EnumField label="Status" source="import_product.status" _enum={_enumImportStatus} prefix="IMPORT_STATUS_"/>
                <PriceField label="Total Price" source="import_product.total_price" fullWidth />                
                <NumberField label="Total Products" source="import_product.total_products" fullWidth />
                <ItemsComponent source="items" {...props} />
            </SimpleShowLayout>
        </Show>
    )
};

export default CreateComponent;