import {
    warehouseAgencyV2Endpoint,
    agencyReportEndpoint,
    notificationEndpoint,
    eventEndpoint,
    employeeSalariesEndpoint,
    orderOnAssigningsEndpoint,
    agenciesEndpoint,
    configsEndpoint,
    masterDataEndpoint,
    warehouseAgencyEndpoint,
    warehouseCompanyEndpoint,
    userEndpoint,
    warehouseAgencyTransferEndpoint,
    warehouseCompanyTransferEndpoint,
    profileEndpoint,
    configAgencyEndpoint,
    ordersEndpoint,
    vipCustomerEndpoint,
    agencyCustomers,
    orderComment,
    partnerEndpoint,
    agencyTypeEndpoint,
} from "./endpoint";
import auth from "./auth";
import { _convertTypeProvider } from "../utils/provider";
import { _clientUserStorage } from "../utils/storage";

const dataProvider = async (type, key, params) => {
    type = _convertTypeProvider(type);
    const user = _clientUserStorage.get() || {};
    const handleFunc = () => {
        switch (key) {
            case "partners": {
                return partnerEndpoint({ type, key, params, user });
            }
            case "agency_types": {
                return agencyTypeEndpoint({ type, key, params, user });
            }
            case "notifications": {
                return notificationEndpoint({ type, key, params, user });
            }
            case "agencies": {
                return agenciesEndpoint({ type, key, params, user });
            }
            case "categories": {
                return masterDataEndpoint.categoriesEndpoint({
                    type,
                    key,
                    params,
                    user,
                });
            }
            case "products": {
                return masterDataEndpoint.productsEndpoint({
                    type,
                    key,
                    params,
                    user,
                });
            }
            case "productmetadatas": {
                return masterDataEndpoint.productMetadatas({
                    type,
                    key,
                    params,
                    user,
                });
            }
            case "warehouseagenciesv2": {
                return warehouseAgencyV2Endpoint({ type, key, params, user });
            }
            case "warehouseagencies": {
                return warehouseAgencyEndpoint({ type, key, params, user });
            }
            case "warehousecompanies": {
                return warehouseCompanyEndpoint({ type, key, params, user });
            }
            case "transferrequests":
            case "transferrequestsreturned":
            case "transferrequestsdraft":
            case "transferrequestsaccepted":
            case "transferrequestsrequested":
            case "transferrequestson_delivering":
            case "transferrequestsdelivered":
            case "transferrequestscanceled":
            case "transferrequestsdone":
            case "transferrequestson_returning":
            case "warehouseagenciestransfer": {
                return warehouseAgencyTransferEndpoint({
                    type,
                    key,
                    params,
                    user,
                });
            }
            case "warehousecompaniesimport": {
                return warehouseCompanyTransferEndpoint({
                    type,
                    key,
                    params,
                    user,
                });
            }
            case "profiles": {
                return profileEndpoint({ type, key, params, user });
            }
            case "employeesalaries": {
                return employeeSalariesEndpoint({ type, key, params, user });
            }
            case "configscategory_point":
            case "configsapp_version":
            case "configspoint":
            case "configs": {
                return configsEndpoint({ type, key, params, user });
            }
            case "configagencies": {
                return configAgencyEndpoint({ type, key, params, user });
            }
            case "orderComments": {
                return orderComment({ type, key, params, user });
            }
            case "orders":
            case "ordersdraft":
            case "ordersonassigning":
            case "ordersassigned":
            case "ordersondelivering":
            case "ordersdelivered":
            case "orderswaitingto_finish":
            case "ordersdone":
            case "orderswaitingfor_approve_return":
            case "ordersreturned":
            case "orderscanceled": {
                return ordersEndpoint({ type, key, params, user });
            }
            case "orderOnAssigning": {
                return orderOnAssigningsEndpoint({ type, key, params, user });
            }
            case "users":
            case "owneragencies":
            case "employeeagencies":
            case "customers":
            case "admins":
            case "employees":
            case "nones": {
                return userEndpoint({ type, key, params, user });
            }
            case "vip_customers": {
                return vipCustomerEndpoint({ type, key, params, user });
            }
            case "agency_report": {
                return agencyReportEndpoint({ type, key, params, user });
            }
            case "agency_customers": {
                return agencyCustomers({ type, key, params, user });
            }
          case "events": {
                return eventEndpoint({ type, key, params, user });
          }
            default: {
              console.log("vccc", key)
                return "";
            }
        }
    };
    const resp = await handleFunc();
    if (resp === undefined) {
        return {
            data: [],
            total: 0,
        };
    }
    return resp;
};

const wrapperDataProvider = async (type = "", key = "", params = {}) => {
    try {
        return (await dataProvider(type, key, params)) || {};
    } catch (err) {
        if (err.err_code !== 1015) {
            throw err;
        }
        try {
            await auth.checkAuth();
            return (await dataProvider(type, key, params)) || {};
        } catch (err) {
            return err;
        }
    }
};
export default wrapperDataProvider;
