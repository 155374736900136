
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Toolbar, SaveButton, Edit, SimpleForm, TextInput, NumberInput, required, FormDataConsumer, SelectInput} from 'react-admin';
import { _arraryConfigPoint} from "../../utils/enum";

const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
}));

const CustomToolbar = (props) => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
    </Toolbar>
);

const CreateComponent = (props) => {
    const classes = useStyles();
    const redirectBack = () => {
        window.history.go(-1)
    }
    return (
        <Edit {...props} undoable={false} title="Update Config">
            <SimpleForm {...props} toolbar={<CustomToolbar />} className={classes.form} redirect={redirectBack}>
                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <TextInput label="Group" source="config_group" disabled={props.id ? true : false} fullWidth validate={[required()]}/>
                    </Grid>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            if(formData.config_group === "app_version") {
                                return (
                                    <React.Fragment>
                                        <Grid item sm={2}>
                                            <TextInput fullWidth label="Platform" source="platform" validate={[required()]}/>
                                        </Grid>
                                        <Grid item sm={2}>
                                            <TextInput fullWidth label="Bundle ID" source="bundleID" validate={[required()]}/>
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                            if(formData.config_group === "point") {
                                return (
                                    <Grid item sm={4}>
                                        <SelectInput label="Key" source="config_key" fullWidth choices={_arraryConfigPoint} initialValue={_arraryConfigPoint[0].id} validate={[required()]}/>
                                    </Grid>
                                )
                            }
                            return (
                                <Grid item sm={4}>
                                    <TextInput label="Key" source="config_key" fullWidth validate={[required()]}/>
                                </Grid>
                            )
                        }}
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            if(formData.config_group === "point") {
                                return (
                                    <React.Fragment>
                                        <Grid item sm={2}>
                                            <NumberInput fullWidth label="Coefficient" source="coefficient" validate={[required()]}/>
                                        </Grid>
                                        <Grid item sm={2}>
                                            <TextInput fullWidth label="Value" source="config_value" validate={[required()]}/>
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                            return (
                                <Grid item sm={4}>
                                    <TextInput label="Value" source="config_value" fullWidth validate={[required()]}/>
                                </Grid>
                            )
                        }}
                    </FormDataConsumer>
                    <Grid item sm={12}>
                        <TextInput rows={5} multiline label="Description" source="description" fullWidth />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};

export default CreateComponent;