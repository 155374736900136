import * as React from "react";
import { Login } from "react-admin";
import LoginEmail from "./LoginEmail";

const MyLoginPage = () => {
  return (
    <Login>
      <LoginEmail redirectTo="/" />
    </Login>
  );
};

export default MyLoginPage;
