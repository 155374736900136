import React from 'react';
import PropTypes from "prop-types";

import { TextField, ImageField, useQuery } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    avatar: {
        width: 40,
        "& img": {
            width: "100%",
            objectFit: "contain"
        } 
    },
}));
const Field = (props) => {
    const {record = {}} = props;
    const newRecord = record[props.source] || {}
    if (newRecord.name) {
        return <UserField {...props} record={newRecord} />
    }
    if (!newRecord.id) {
        return <TextField record={newRecord} source="name"/>
    }

    return <NewUser {...props} />
    


}

const NewUser =  (props) => {
    const {record = {}} = props;
    const newRecord = record[props.source] || {}
    const {data: user = {}} = useQuery({ 
        type: 'one',
        resource: 'users',
        payload: { id: newRecord.id || record.id}
    });
    return <UserField {...props} record={user} />
};

const UserField =  (props) => {
    const classes = useStyles();
    return (
        <div>
            <TextField {...props} source="name"/>
            <br/>
            <TextField {...props}  source={`email`}/>
            <ImageField {...props}  source={"avatar"} className={classes.avatar}/>
            
        </div>
    );
};
UserField.propTypes = {
    record: PropTypes.object,
    label: PropTypes.string,
}
UserField.defaultProps = {
    addLabel: true,
    record: {},
}
export default Field;
