import React, {Fragment, useCallback} from 'react';
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";

import {Button, Menu, MenuItem} from "@material-ui/core"
import { List, Datagrid, TextField, EditButton, ShowButton, useRedirect } from 'react-admin';

import DialogComponent from "./resetPassword";
import {EnumField, Pagination, LinkField} from '../../partials'


const useStyles = makeStyles((theme) => ({
    rootContent: {
        
    },
    gridFilter: {
        display: "flex",
        alignItems: "center",
    },
    autoSuggest: {
        marginRight: theme.spacing(2),
        minWidth: 200,
    },
    rootList: {
        minHeight: 400,
    },
    link: {
        color: "#3f51b5",
        cursor: "pointer",
    }
}));


const ButtonComponent = (props) => {
    const {record = {}, basePath, resource, id} = props;
    const commonProps = {
        basePath, 
        resource,
        record,
        id
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>...</Button>
            <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            >
                <MenuItem onClick={handleClose}><EditButton {...commonProps} label="Edit"/></MenuItem>
                <MenuItem onClick={handleClose}><ShowButton {...commonProps} label="Show"/></MenuItem>
        </Menu>
    </div>
    )
};

const UserField = (props) => {
    const {record = {}} = props;
    const classes = useStyles();
    const redirect = useRedirect()
    const onClick = useCallback(() => {
        redirect(`/customers/${record.id}/show`)
    }, [record.id, redirect])
    return (
        
        <TextField className={classes.link} onClick={onClick} record={record} source="profile.name" label="Name"/> 
    );
};
UserField.propTypes = {
    record: PropTypes.object,
    label: PropTypes.string,
    basePath: PropTypes.string,
}
UserField.defaultProps = {
    addLabel: true
}

const ItemsField = (props) => {
    const {record = {}, source, basePath} = props;
    const classes = useStyles();
    return (
        <div className={classes.box}>
            {(record[source] || []).map((e = {}, index) => {
                const textName = <LinkField basePath={basePath} reference="categories" record={e} field="name" source="category_id" label="Category ID"/>
                return (
                    <div className={classes.row} key={index}>
                        {textName} - {e.value}
                    </div>
                )
            })}
        </div>
    );
};
ItemsField.propTypes = {
    record: PropTypes.object,
    label: PropTypes.string,
    basePath: PropTypes.string,
}
ItemsField.defaultProps = {
    addLabel: true
}


const DatagridComponent = (props) => {
    const classes = useStyles();
    const [isReset, setIsReset] = React.useState()
    const [record, setRecord] = React.useState({})
    const handleClose = () => {
        setIsReset(false);
    };
    const handleReset = (record) => {
        setIsReset(true)
        setRecord(record)
    }
    return (
        <Fragment>
            <Datagrid {...props}>
                <UserField source="profile.name" label="Name"/>
                <ItemsField source="commitments" label="Commitments"/>
                <ItemsField source="promotions" label="Promotions"/>
                <EnumField source="status" label="Status" _enum={null} prefix="VIP_CUSTOMER_STATUS_"/>
                <ButtonComponent {...props} handleReset={handleReset}/>
            </Datagrid>
            {isReset && 
                <DialogComponent
                    {...props}
                    classesContent={{
                        root: classes.rootContent,
                    }}
                    record={record}
                    user_id={record.id}
                    anchor="right"
                    handleClose={handleClose}
                    title="Reset Password"
                    maxWidth="md"
                    open
                    fullWidth/>}
        </Fragment>
    )
};

const ListComponent = (props) => {
    return (
        <List {...props} pagination={<Pagination />}  perPage={20} bulkActionButtons={false} filterDefaultValues={{}} sort={{ field: 'created_at', order: 'DESC' }}>
            <DatagridComponent {...props}/>
        </List>
    )
};
export default ListComponent

export {DatagridComponent}