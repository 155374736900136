import { USER_GROUP_OWNER_AGENCY } from "../../utils/const";
import { _endpointUser } from "../../utils/provider";
import { _createRequest, _getFilter, _pick, _fetch } from "../../utils/helper";
import { _clientUserStorage } from "../../utils/storage";

const convertKey2Enum = (key = "") => {
    if (key === "admins") return "ADMIN";
    else if (key === "employees") return "EMPLOYEE_COMPANY";
    else if (key === "none") return "NONE";
    else if (key === "owneragencies") return "OWNER_AGENCY";
    else if (key === "employeeagencies") return "EMPLOYEE_AGENCY";
    else if (key === "customers") return "CUSTOMER";
    else if (key === "agency_customers") return "CUSTOMER";
};
const usersEndpoint = async (props) => {
    const { type, key = "" } = props;

    const user = (await _clientUserStorage.get()) || {};
    let params = JSON.parse(JSON.stringify(props.params || {}));
    params.filter = params.filter || {};
    if (user.group === USER_GROUP_OWNER_AGENCY) {
        if (params.filter) params.filter.agency_id = user.agency_id;
        if (params.data) params.data.agency_id = user.agency_id;
    }
    if (params.data && params.data.birth_day) {
        params.data.birth_day = new Date(params.data.birth_day);
    }
    switch (type) {
        case "create": {
            if (
                key === "employeeagencies" &&
                params.data &&
                params.data.phone_number
            ) {
                params.data.phone_number += "_nv";
            }
            return _fetch(
                _createRequest(_endpointUser.create, true, {
                    ..._pick(params.data, [
                        "name",
                        "avatar",
                        "email",
                        "phone_number",
                        "birth_day",
                        "sex",
                        "group",
                        "address",
                        "basic_salary",
                        "password",
                        "agency_id",
                    ]),
                })
            ).then((res) => {
                res.data = res.data || {};
                if (res.rawData) res.data.id = res.rawData.id;
                return res;
            });
        }
        case "one": {
            return _fetch(
                _createRequest(_endpointUser.one, true, {
                    ..._pick(params, ["id"]),
                })
            );
        }
        case "resetPassword": {
            return fetch(
                _createRequest(_endpointUser.reset, true, {
                    ..._pick(params, ["user_id", "password"]),
                })
            )
                .then((res) => res.json())
                .then((res) => {
                    res.data = res.data || {};
                    res.data.id = params.user_id;
                    return res;
                });
        }
        case "update": {
            return _fetch(
                _createRequest(_endpointUser.update, true, {
                    ..._pick(params.data, [
                        "id",
                        "name",
                        "avatar",
                        "phone_number",
                        "birth_day",
                        "sex",
                        "group",
                        "address",
                        "basic_salary",
                        "latitude",
                        "longitude",
                        "status",
                    ]),
                })
            ).then((res) => {
                if (!res.data) res.data = params.data;
                return res;
            });
        }
        case "many":
        case "manyReference": {
            const { target = "id" } = params;
            if (key !== "users" && params.filter) {
                params.filter = {
                    ...params.filter,
                    user_group: `USER_GROUP_${convertKey2Enum(key)}`,
                };
            }
            if (params.ids) {
                params.filter = {
                    ...params.filter,
                    [target]: params.ids,
                };
            }
            params.pagination = {
                ...params.pagination,
                perPage: (params.ids || []).length || 10,
            };
            return _fetch(
                _createRequest(_endpointUser.list, true, {
                    filter: _getFilter(params, {
                        user_group: "eq",
                        status: "eq",
                        agency_id: "in",
                    }),
                })
            );
        }
        case "all": {
            const getAll = (page = 1, data = []) => {
                const perPage = 100;
                params.pagination = {
                    perPage: perPage,
                    page: page,
                };

                if (key !== "users" && params.filter) {
                    params.filter = {
                        ...params.filter,
                        user_group: `USER_GROUP_${convertKey2Enum(key)}`,
                    };
                }
                return _fetch(
                    _createRequest(_endpointUser.list, true, {
                        filter: `${_getFilter(params, {
                            status: "eq",
                            user_group: "eq",
                            agency_id: "eq",
                        })}`,
                    })
                ).then((res) => {
                    if (
                        res.total === undefined ||
                        res.total <= page * perPage
                    ) {
                        return {
                            ...res,
                            data: [...data, ...res.data],
                        };
                    } else {
                        return getAll(page + 1, [...data, ...res.data]);
                    }
                });
            };
            return getAll(1);
        }
        default: {
            //list
            if (key !== "users" && params.filter) {
                params.filter = {
                    ...params.filter,
                    user_group: `USER_GROUP_${convertKey2Enum(key)}`,
                };
            }
            return _fetch(
                _createRequest(_endpointUser.list, true, {
                    filter: `${_getFilter(params, {
                        status: "eq",
                        user_group: "eq",
                        agency_id: "eq",
                    })}`,
                })
            );
        }
    }
};

export default usersEndpoint;
