
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Edit, SimpleForm, TextInput, SelectInput, NumberInput } from 'react-admin';

import InputComponent from '../imageInput';
import ArrayImageField from '../arrayImageField';
import {useGetAllCategories} from "../../hooks";

const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
}));

const CreateComponent = (props) => {
    const classes = useStyles();
    const [removeImages, setRemoveImages] = React.useState([])
    const [refresh, setRefresh] = React.useState(true)
    const handleClick = (url = "", isBack = "") => {
        if(url) {
            if(!isBack) {
                setRemoveImages([...removeImages, ...[url.src]])
            } else {
                setRemoveImages(removeImages.filter(e => e !== url.src))
            }
        }
    }
    const redirect = (basePath, id, data) => {
        return `${basePath}/`;
    }
    const { data: categories = [] } = useGetAllCategories();

    return (
        <Edit {...props} undoable={false}>
            <SimpleForm className={classes.form} redirect={redirect}>
                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <TextInput label="ID" source="id" fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <SelectInput label="Category" source="category_id" choices={categories || []} optionText="name" optionValue="id" fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <TextInput label="Name" source="name" fullWidth />
                    </Grid>
                    <Grid item sm={12}>
                        <TextInput rows={3} multiline label="Description" source="description" fullWidth />
                    </Grid>
                    <Grid item sm={3} md={6}>
                        <TextInput source="unit" label="Unit" fullWidth />
                    </Grid>
                    <Grid item sm={3} md={6}>
                        <TextInput source="unit_weight" label="Unit Weight" fullWidth />
                    </Grid>
                    <Grid item sm={3} md={6}>
                        <SelectInput source="track_by" label="Track by" choices={[{id: "unit", name: "Unit"}, {id:"unit_weight", name: "Unit Weight"}]} fullWidth />
                    </Grid>
                    <Grid item sm={3} md={6}>
                        <TextInput source="supplier" label="Supplier" fullWidth />
                    </Grid>
                    <Grid item sm={6}>
                        <TextInput source="origin" label="Origin" fullWidth />
                    </Grid>
                    <Grid item sm={6}>
                        <TextInput source="expiry" label="Expiry" fullWidth />
                    </Grid>
                    <Grid item sm={6}>
                        <NumberInput source="level" label="Level" fullWidth />
                    </Grid>
                    <Grid item md={6}>
                        <NumberInput source="priority" label="Priority" fullWidth />
                    </Grid>
                    <Grid item sm={12}>
                        <InputComponent multiple source="imagesNew" nameBase="imagesBase" label="Images" />
                    </Grid>
                </Grid>
                <ArrayImageField {...props} source="images" label="Images" edit={true} handleClick={handleClick} removeImages={removeImages} setRefresh={setRefresh} refresh={refresh}/>
            </SimpleForm>
        </Edit>
    )
};

export default CreateComponent;