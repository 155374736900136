
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { Create, SimpleForm, TextInput, NumberInput, SelectInput, required, FormDataConsumer, useRefresh} from 'react-admin';
import {  _configGroupByPermission, _arraryConfigPoint} from "../../utils/enum";
import { USER_GROUP_ADMIN } from '../../utils/const';
import {useGetAllCategories} from "../../hooks"
const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
}));

const CreateComponent = (props = {}) => {
    const classes = useStyles();
    const refresh = useRefresh()
    const {data: categories = []} = useGetAllCategories()
    const choices = _configGroupByPermission(props.permissions, USER_GROUP_ADMIN);
    const redirectBack = () => {
        refresh()
        window.history.go(-1)
    }
    return (
        <Create {...props}>
            <SimpleForm className={classes.form} redirect={redirectBack}>
                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <SelectInput label="Group" source="config_group" choices={choices} 
                            initialValue={props.resource === "configs" || props.resource==="configsapp_version"  ? "app_version" 
                                : props.resource==="configspoint" ? "point" : choices[0] ? choices[0].id : ""} 
                            fullWidth validate={[required()]}/>
                    </Grid>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            if(formData.config_group === "app_version") {
                                return (
                                    <React.Fragment>
                                        <Grid item sm={2}>
                                            <TextInput fullWidth label="Platform" source="platform" validate={[required()]}/>
                                        </Grid>
                                        <Grid item sm={2}>
                                            <TextInput fullWidth label="Bundle ID" source="bundleID" validate={[required()]}/>
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                            if(formData.config_group === "category_point") {
                                return (
                                    <React.Fragment>
                                        <Grid item sm={4}>
                                            <SelectInput fullWidth label="Key" source="config_key" validate={[required()]} choices={categories}/>
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                            else if(formData.config_group === "point") {
                                return (
                                    <Grid item sm={4}>
                                        <SelectInput label="Key" source="config_key" fullWidth choices={_arraryConfigPoint} initialValue={_arraryConfigPoint[0].id} validate={[required()]}/>
                                    </Grid>
                                )
                            } else {
                                return (
                                    <Grid item sm={4}>
                                        <TextInput label="Key" source="config_key" fullWidth validate={[required()]}/>
                                    </Grid>
                                )
                            }
                        }}
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            if(formData.config_group === "point") {
                                return (
                                    <React.Fragment>
                                        <Grid item sm={2}>
                                            <NumberInput fullWidth label="Coefficient" source="coefficient" initialValue={1} validate={[required()]}/>
                                        </Grid>
                                        <Grid item sm={2}>
                                            <TextInput fullWidth label="Value" source="config_value" initialValue={"1"} validate={[required()]}/>
                                        </Grid>
                                    </React.Fragment>
                                )
                            } else {
                                return (
                                    <Grid item sm={4}>
                                        <TextInput label="Value" source="config_value" fullWidth validate={[required()]}/>
                                    </Grid>
                                )
                            }
                        }}
                    </FormDataConsumer>
                    <Grid item sm={12}>
                        <TextInput rows={5} multiline label="Description" source="description" fullWidth />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};

export default CreateComponent;