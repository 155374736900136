import React from "react";
import PropTypes from "prop-types";
import {TextField} from "react-admin";

import {_getLodash, _setLodash,  _formatPrice} from "../utils/helper";

const Field = ({record, source, currency = "VND", ...rest}) => {
    let newRecord = JSON.parse(JSON.stringify(record));
    const value =  _formatPrice(_getLodash(newRecord, source))
    newRecord = _setLodash(newRecord, source, value);
    return (
        <TextField {...rest} source={source} record={newRecord} />
    );
};

Field.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string,
    currency: PropTypes.string,
};
Field.defaultProps = {
    currency: "VND",
    addLabel: true,
    record: {},
};

export default Field;
