import React from 'react';
import { TextField } from 'react-admin';
import PropTypes from "prop-types";
import {_getLodash} from "../utils/helper";

const LinkField =  (props = {}) => {
    let newRecord = JSON.parse(JSON.stringify(props.record));
    const value =  _getLodash(newRecord, props.source)
    return (
        <a href={`tel:${value}`}>
            <TextField {...props}/>
        </a>
    );
};
LinkField.propTypes = {
    source: PropTypes.string,
    field: PropTypes.string,
    reference: PropTypes.string,
}
LinkField.defaultProps = {
    addLabel: true,
    record: {},
}
export default LinkField;
