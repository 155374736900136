
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useForm } from 'react-final-form';

import { Create, SimpleForm, TextInput, SelectInput, required, useRedirect, FormDataConsumer, NumberInput} from 'react-admin';
import {  _configGroupByPermission, _arraryConfigPrice} from "../../utils/enum";
import {USER_GROUP_OWNER_AGENCY} from "../../utils/const";
import { _parseQuery} from "../../utils/helper";
import {_clientUserStorage} from "../../utils/storage";
import {useGetAllCategories, useGetAllAgencyConfigs} from "../../hooks";

const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
}));

const AgencyComponent = (props = {}) => {
    const redirect = useRedirect();
    const form = useForm();

    const user = _clientUserStorage.get() || {}
    const [agencyId, setAgency] = React.useState(user.agency_id);

    const { data: categories = [] } = useGetAllCategories()
    const { data: config = [] } = useGetAllAgencyConfigs(agencyId)


    React.useEffect(() => {
        form.change("agency_id", agencyId)
    }, [agencyId, form])
    React.useEffect(() => {
        const checkRedirectBack = async() => {
            const search = props.location.search;
            const query = _parseQuery(search) || {}
            let agency_id = agencyId || query.agency_id;

            if(!agency_id) {
                redirect(`/agencies`, `/agencies`)
            } else {
                setAgency(agency_id)
            }
        }
        checkRedirectBack()
    }, [props.resource, props.location.search, agencyId, redirect])

    const filterConfig = (_arr = []) => {
        return (_arr || []).filter(i => !config.find(e => e.config_key === i.id))
    }

    const configPrice = (categories = []) => {
        var _arr = _arraryConfigPrice.slice();
      categories.forEach((e) => {
        _arr.push(
             {id: e.id + "_price_coefficient", name: e.name + " Price Coefficient"},
        );
      });
      return _arr;
    }

    const choices = _configGroupByPermission(props.permissions, USER_GROUP_OWNER_AGENCY);
    return (
        <Grid container spacing={3}>
            <Grid item sm={4}>
                <SelectInput label="Group" source="config_group" choices={choices} initialValue={choices[0] ? choices[0].id : ""} fullWidth validate={[required()]}/>
            </Grid>
            <FormDataConsumer>
                {({formData, ...rest}) => {
                    if(formData.config_group === "point") {
                        return (
                            <Grid item sm={4}>
                                <SelectInput
                                  fullWidth label="Key" source="config_key"
                                choices={[
                                    {id: "point_to_money", name: "To money"},
                                    {id: "total_successed_order_bonus", name: "Total successed order bonus"},
                                ]} initialValue="point_rate" validate={[required()]}
                            />
                            </Grid>
                        )
                    }
                    if(formData.config_group === "price") {
                        return (
                            <Grid item sm={4}>
                                <SelectInput fullWidth label="Key" source="config_key" choices={configPrice(categories)} initialValue={_arraryConfigPrice[0]} validate={[required()]}/>
                            </Grid>
                        )
                    } else {
                        return (
                            <Grid item sm={4}>
                                <SelectInput fullWidth  label="Key" source="config_key" optionText="name" optionValue="id" choices={filterConfig(categories)} validate={[required()]}/>
                            </Grid>
                        )
                    }
                }}
            </FormDataConsumer>
            <FormDataConsumer>
                {({formData, ...rest}) => {
                    if(formData.config_group === "point") {
                        return (
                            <React.Fragment>
                                <Grid item sm={2}>
                                    <NumberInput fullWidth label="Coefficient" source="coefficient" initialValue={0} validate={[required()]}/>
                                </Grid>
                                <Grid item sm={2}>
                                    <TextInput fullWidth label="Value" source="config_value" initialValue={"1"} validate={[required()]}/>
                                </Grid>
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <Grid item sm={4}>
                                <TextInput label="Value" source="config_value" fullWidth validate={[required()]}/>
                            </Grid>
                        )
                    }
                }}
            </FormDataConsumer>
            <Grid item sm={12}>
                <TextInput rows={5} multiline label="Description" source="description" fullWidth />
            </Grid>
        </Grid>
    )
};
const CreateComponent = (props = {}) => {
    const classes = useStyles();
    return (
        <Create {...props}>
            <SimpleForm className={classes.form} redirect={() => window.history.back()}>
                <AgencyComponent  {...props}/>
            </SimpleForm>
        </Create>
    )
};

export default CreateComponent;
