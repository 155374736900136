import list from './list'
import create from './create'
import edit from './edit'
import detail from './detail'

export default {
    list: list,
    create: create,
    edit: edit, 
    show: detail,
    name: "agencies",
    key: "p_agencies",
}