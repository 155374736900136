import {_endpointMasterData} from '../../../utils/provider';
import {_createRequest, _pick, _getFilter, _fetch} from '../../../utils/helper'
import {_enumUploadType} from '../../../utils/enum';
import {_fileToBase64} from '../../../utils/base64';
import uploadsEndpoint from '../upload';

const _endpointCategories = _endpointMasterData.categories;

const endpoint = async(props) => {
    const {type, params = {}} = props;
    switch(type) {
        case "create": {
            if(params.data && params.data.imagesBase && params.data.imagesBase.length > 0) {
                let promise = params.data.imagesBase.map(e => {
                    return uploadsEndpoint({type: "upload", params: {
                        data: {
                            uploadType: _enumUploadType["UPLOAD_TYPE_NONE"],
                            ...e
                        }
                    }}) 
                })
                await Promise.all(promise).then(res => {
                    const url = res.map(e => e.data) || [];
                    params.data = {
                        ...params.data,
                        images: url
                    }
                })
            }

            return _fetch(_createRequest(_endpointCategories.create, true, {
                ..._pick(params.data, ["id", "name", "description", "images", "priority"])
            }))
        }
        case "import": {
            return _fileToBase64(params.files.rawFile).then(result => {
                return _fetch(_createRequest(_endpointCategories.import, true, {
                    data: result.payload,
                }))
            })
        }
        case "update": {
            if(params.data && params.data.imagesBase && params.data.imagesBase.length > 0) {
                let promise = params.data.imagesBase.map(e => {
                    return uploadsEndpoint({type: "upload", params: {
                        data: {
                            uploadType: _enumUploadType["UPLOAD_TYPE_NONE"],
                            ...e
                        }
                    }})
                })
                await Promise.all(promise).then(res => {
                    const url = res.map(e => e.data) || [];
                    params.data = {
                        ...params.data,
                        images: url
                    }
                })
            }

            return _fetch(_createRequest(_endpointCategories.update, true, {
                ..._pick(params.data, ["id", "name", "description", "images", "priority"])
            }))
        }
        case "delete": {
            return _fetch(_createRequest(_endpointCategories.delete, true, {
                ..._pick(params, ["id"])
            }))
        }
        case "one": {
            let {filter = {}} = params;
            filter.id = params.id
            params.filter = {...filter}

            return _fetch(_createRequest(_endpointCategories.list, true, {
               filter: _getFilter(params, {id: "eq"})
            }))
            .then(res => {
                const {data = []} = res;
                return {data: data[0]}
            })
        }
        case "many": {
            params.filter = {
                ...params.filter,
                id: params.ids,
            }
            params.pagination = {
                ...params.pagination,
                perPage: (params.ids || []).length || 10,
            }
            return _fetch(_createRequest(_endpointCategories.list, true, {
                filter: _getFilter(params, {id: "in"})
            }))
        }
        case "all": {
            const getAll = (page = 1, data = []) => {
                const perPage = 100;
                params.pagination = {
                    perPage: perPage,
                    page: page
                }

                return _fetch(_createRequest(_endpointCategories.list, true, {
                    filter: _getFilter(params, {deleted_at: "is_null", id: "in"})
                })).then(res => {
                    if (res.total <= page * perPage) {
                        return {
                            ...res,
                            data: [...data, ...res.data]
                        }
                    } else {
                        return getAll(page + 1,  [...data, ...res.data])

                    }
                })
            }
            return getAll(1)
        }
        default: { //list
            return _fetch(_createRequest(_endpointCategories.list, true, {
               filter: _getFilter(params, {})
            }))
        }
    }
}

export default endpoint