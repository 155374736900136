import { useQuery } from 'react-admin';

function useGetAllAgencyConfigs(agencyId) {
    const {data = [], loading, loaded, error} = useQuery(
        {
            type: "all",
            resource: "configagencies",
            payload: {
                agency_id: agencyId,
                sort: [{
                    field: "created_at",
                    order: "DESC",
                },{
                    field: "id",
                    order: "ASC",
                }],
            },
        },
        []
    );
    return {
        data, 
        loading, 
        loaded, 
        error,
    }
}

export default useGetAllAgencyConfigs;
