import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';


import { Datagrid, TextField, NumberField, useRedirect } from 'react-admin';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { UserField, PriceField, EnumField, ButtonField } from '../../../partials';
import { _enumSalaryStatus } from '../../../utils/enum';

const useStyles = makeStyles(theme => ({
    box: {
        display: "flex",
        flexDirection: "column"
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        "& > div:first-child": {
            paddingRight: theme.spacing(1),
        }
    }
}));


const SalaryField = ({hasList, hasEdit, hasShow, ...props}) => {
    const classes = useStyles();
    return (
        <div className={classes.box}>
            <div className={classes.row}>
                <div>Basic:</div> 
                <PriceField {...props} source="basic_salary" />
            </div>
            <div className={classes.row}>
                <div>Bonus:</div> 
                <PriceField {...props} source="bonus_salary" />
            </div>
            <div className={classes.row}>
                <div>Total:</div> 
                <PriceField {...props} source="total_salary" />
            </div>
        </div>
    );
};
SalaryField.propTypes = {
    record: PropTypes.object,
    label: PropTypes.string,
}

const ProductField = ({hasList, hasEdit, hasShow, ...props}) => {
    const classes = useStyles();
    const { record = {} } = props;
    const statistics = record.statistics || {};
    const keys = Object.keys(statistics).filter(e => e.indexOf("total_") >= 0);

    return (
        <div className={classes.box}>
            {keys.map(key => {
                return (
                    <div className={classes.row}>
                        <div>{key.replace("total_", "")}:</div>
                        <NumberField {...props} source={`statistics.${key}`} />
                    </div>
                )
            })}
        </div>
    );
};
ProductField.propTypes = {
    record: PropTypes.object,
    label: PropTypes.string,
}


const BonusField = ({hasList, hasEdit, hasShow, ...props}) => {
    const { record = {} } = props;
    const classes = useStyles();
    const statistics = record.statistics || {};
    const keys = Object.keys(statistics).filter(e => e.indexOf("bonus_rate_") >= 0);
    return (
        <div className={classes.box}>
        {keys.map(key => {
            return (
                <div key={key} className={classes.row}>
                    <div>{key.replace("bonus_rate_", "")}:</div>
                    <NumberField {...props} source={`statistics.${key}`} />
                </div>
            )
        })}
        </div>
    );
};
BonusField.propTypes = {
    record: PropTypes.object,
    label: PropTypes.string,
}

const ListComponent = (props) => {
    const redirect = useRedirect();
    
    const onClick = (e, record = {}) => {
        redirect(`/${props.resource}/detail/${record.summary_id}?employee_id=${record.profile.id}&year=${record.year}&month=${record.month}`, `/${props.resource}`, record.summary_id, {
            ...record,
            employee_id: record.profile.id,
        })
    }
    return (
        <Datagrid {...props}>
            <TextField source="summary_id" label="ID" sortable={false} />
            {props.basePath !== "/employeeagencies" && <UserField source="profile" label="Profile" sortable={false} />}
            <ProductField {...props} label="Product" />
            <SalaryField {...props} label="Salary" />
            <BonusField {...props} label="Bonus" />
            <TextField source="note" label="Note" sortable={false} />
            <TextField source="year" label="Year" />
            <TextField source="month" label="Month" />
            <EnumField source="status" label="Status" _enum={_enumSalaryStatus} prefix="EMPLOYEE_SALARY_STATUS_" />
            <ButtonField label={"Show"} bIcon={<VisibilityIcon />} name="Show" onClick={onClick} color="primary" />
        </Datagrid>
    );
}
export default ListComponent
