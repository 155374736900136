import React from 'react';
import { ArrayField, Datagrid, ImageField} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {IconButton} from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { useForm, useFormState } from 'react-final-form';

const useStyles = makeStyles(theme => ({
    image: {
        maxWidth: theme.spacing(30), 
        minWidth: theme.spacing(15),
        margin: theme.spacing(1) * -1,
        "& > img": {
            width: "100%",
            margin: theme.spacing(1),
        }
    },
    iconRemove: {
        position: "absolute",
        right: theme.spacing(3)*-1,
        top: theme.spacing(2)*-1,
        zIndex: 99
    },
    root: {
        position: "relative", 
        maxWidth: theme.spacing(30),
        '& > div': {
            width: "100%",
            margin: theme.spacing(1) * -1,

        },
        '& > div > img': {
            width: "100%",
            margin: theme.spacing(1),

        }
    }
}));
const CustomField = (props) => {
    const classes = useStyles();
    const formState = useFormState().values;
    const form = useForm();
    const record = {
        src: props.record
    }
    const isBack = props.removeImages && props.removeImages.length && props.removeImages.find(e => e === record.src)

    React.useEffect(() => {
        const images = formState[props.source];
        const newImages = images.filter((e) => props.removeImages.indexOf(e) === -1) || [];
        if(images !== newImages && newImages.length !== images.length) {        
            form.change(props.source, newImages)
        }
    }, [form, formState, props.source, props.removeImages])

    const handleClick = (e) => {
        e.preventDefault()     
        if(props.handleClick) {   
            if(isBack) {
                let images = formState[props.source] || [];
                images = images.concat([record.src])
                form.change(props.source, images)
            }
            props.handleClick({
                src: props.record
            }, isBack)
        }
    }
    return (
        <div className={classes.root}>
            {props.edit && (formState[props.source] || []).length ? (
                <IconButton aria-controls="language-menu" className={classes.iconRemove} aria-haspopup="true" color={isBack ? "action" :"primary"} onClick={handleClick}>
                    {isBack ? <AutorenewIcon /> : <RemoveCircleIcon />}
                </IconButton>
            ) : ""}
            <ImageField className={classes.image} {...props} record={record} source={"src"}/>
        </div>
    )
}
CustomField.defaultProps = {
    removeImages: []
};


const ArrayImageField = (props) => {
    return (
        <ArrayField {...props}>
            <Datagrid>
                <CustomField {...props}/>
            </Datagrid>
        </ArrayField>
    )
};

ArrayImageField.defaultProps = {
    removeImages: []
};

export default ArrayImageField