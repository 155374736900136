import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Edit, SimpleForm, TextInput, Toolbar, SaveButton } from "react-admin";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        },
    },
}));

const CustomToolbar = (props) => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
    </Toolbar>
);

export const AgencyTypeForm = () => {
    return (
        <Grid container spacing={3}>
            <Grid item sm={6}>
                <TextInput label="Name" source="name" fullWidth />
            </Grid>
            <Grid item sm={6}>
                <TextInput label="Point rate" source="point_rate" fullWidth />
            </Grid>
        </Grid>
    );
};
const CreateComponent = (props) => {
    const classes = useStyles();
    return (
        <Edit
            {...props}
            toolbar={<CustomToolbar />}
            undoable={false}
            title={`Update ${props.resource}`}
        >
            <SimpleForm className={classes.form} redirect="list">
                <AgencyTypeForm />
            </SimpleForm>
        </Edit>
    );
};

export default CreateComponent;
