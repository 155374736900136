const _arrError = {
    UNKNOWN: "unknown",
    NOT_FOUND: 'notFound',
    PERMISSION_DENIED: 'permissionDenied',
    INTERNAL: 'internal',
    DISCONNECT: 'disconnectFromServer',
    NOT_AUTHENTICATED: 'notAuthenticated',
    INVALID_CREDENTIAL: 'invalidCredential',
    INVALID_PROMOTION: 'invalidPromotion',
    NO_INTERNET: 'noInternet',
    INVALID_PARAMS: 'invalidParams'
}


export const _getMessageError = (code) => {
    switch(code) {
        case 404: {
            return _arrError.INTERNAL
        }
        case 16: {
            return _arrError.NOT_AUTHENTICATED
        }
        default: {
            return _arrError.UNKNOWN
        }
    }
}
export const _throwMessageError = (error = {}) => {
    const message = error.err_code ? error.message : _getMessageError(error.code)
    error.message = message;

    throw error
}