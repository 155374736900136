import { _endpointWarehouseAgency } from "../../utils/provider";
import { _createRequest, _getFilter, _fetch, _pick } from "../../utils/helper";
const endpoint = (props) => {
    const { type, params = {} } = props;
    const objectFilter = { "aw.agency_id": "eq", "p.quantity": "lte" };
    params.filter = params.filter || {};
    const sort = !Array.isArray(params.sort) ? [params.sort] : params.sort;

    switch (type) {
        case "manyReference": {
            const { target = "aw.id" } = params;
            if (params.id) {
                params.filter = {
                    ...params.filter,
                    [target]: params.id,
                };
            }
            return _fetch(
                _createRequest(_endpointWarehouseAgency.statisticv2, true, {
                    filter: _getFilter(params, objectFilter),
                })
            );
        }
        case "suppendProduct": {
            return fetch(
                _createRequest(_endpointWarehouseAgency.suppendProduct, true, {
                    ..._pick(params, ["agency_warehouse_id", "is_suppend"]),
                })
            )
                .then((res) => res.json())
                .then((res) => {
                    res.data = res.data || {};
                    return res;
                });
        }

        default: {
            //list
            return _fetch(
                _createRequest(_endpointWarehouseAgency.statisticv2, true, {
                    filter: _getFilter(
                        {
                            ...params,
                            sort: [...sort, { field: "aw.id", order: "ASC" }],
                        },
                        {
                            ...objectFilter,
                            quantity: "lt",
                        }
                    ),
                })
            );
        }
    }
};

export default endpoint;
