
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { required, Create, SimpleForm, TextInput, NumberInput, SelectInput, ArrayInput, SimpleFormIterator, FormDataConsumer, BooleanInput } from 'react-admin';
import { useForm } from 'react-final-form';

import {_clientUserStorage} from "../../utils/storage";
import {useGetAllUsers, useGetAllWarehouseAgencyStatistic, filterItems} from "../../hooks";

const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
}));
const Form = (props) => {
    const user = _clientUserStorage.get() || {}
    const agencyID = user.agency_id
    const form = useForm();
    useEffect(() => {
        form.change("agency_id", agencyID)
    }, [agencyID, form])

    const {data: customers = []} = useGetAllUsers("customers", JSON.stringify({agency_id: agencyID}));
    const {data: products = []} = useGetAllWarehouseAgencyStatistic(agencyID)

    return (
        <Grid container spacing={3}>
            <Grid item sm={6}>
                <TextInput label="Phone" source="phone_number" fullWidth />
            </Grid>
            <Grid item sm={6}>
                <SelectInput fullWidth label="Customer" source="customer_id" choices={customers} />
            </Grid>
            <Grid item sm={12}>
                <ArrayInput source="items" validate={[required()]}>
                    <SimpleFormIterator>
                        <FormDataConsumer>
                            {({getSource, formData = {}, scopedFormData = {},...rest}) => {
                                return (
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>
                                            <SelectInput id="product-id" optionText={(e) => `${e.name} - ${e.quantity}`} label="Product" source={getSource("product_id")} fullWidth choices={filterItems(products, formData.items, scopedFormData, "product_id")} />
                                        </Grid>
                                        <Grid item sm={4}>
                                            <NumberInput disabled={!formData.agency_id} source={getSource("quantity")} defaultValue={1} fullWidth label="Quantity"/>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <BooleanInput disabled={!formData.agency_id} source={getSource("buy_shell")} defaultValue={true} fullWidth label="Buy Shell"/>
                                        </Grid>
                                    </Grid>
                                )
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </Grid>
            <Grid item sm={12}>
                <TextInput label="Address" source="address" fullWidth />
            </Grid>
            {/* <Grid item sm={12}>
                <CompleteAddress label="Suggest Address" source="suggestaddress" name="address" effects={["address", "latitude", "longitude"]} isCurrent={true} fullWidth/>
            </Grid> */}
            <Grid item sm={6}>
                <NumberInput label="Latitude" source="latitude" initialValue={0} fullWidth />
            </Grid>
            <Grid item sm={6}>
                <NumberInput label="Longitude" source="longitude" initialValue={0} fullWidth />
            </Grid>
            <Grid item sm={12}>
                <TextInput label="Note" source="note" multiline rows={3} fullWidth />
            </Grid>
        </Grid>
    )
};

const CreateComponent = (props) => {
    const classes = useStyles();
    return (
        <Create {...props} title="Create" resource="orders" basePath='/orders'>
            <SimpleForm className={classes.form} basePath='/orders' resource="orders" redirect={"show"}>
                <Form {...props} />
            </SimpleForm>
        </Create>
    )
};

export default CreateComponent;