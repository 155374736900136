import React, {useState, cloneElement} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Collapse, Typography, Box, IconButton,  Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core"
import { List, Datagrid, TextField, EditButton, TopToolbar, CreateButton, ExportButton, sanitizeListRestProps} from 'react-admin';

import {DateField} from '../../partials';

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    group: {
        marginBottom: theme.spacing(5),
        // boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        // backgroundColor: "#ffffff"
    },
    title: {
        marginBottom: theme.spacing(3),
        display: "flex",
        justifyContent: "space-between"
    },
    content: {
        backgroundColor: "transparent",
        boxShadow: "none",
    },
}));
const ListActions = ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate, // you can hide CreateButton if hasCreate = false
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
}) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {resource !== "configs" && <CreateButton basePath={basePath} />}
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={{ ...filterValues, ...permanentFilter }}
            exporter={exporter}
            maxResults={maxResults}
        />
    </TopToolbar>
);
const ConfigGroupComponent = (props) => {
    const classes = useStyles();  
    const [expanded, setExpanded] = useState(false);
    if(props.resource !== "configs") {
        return (
            <Datagrid {...props} >
                <TextField source="id" label="ID"/>
                <TextField source="config_group" label="Group"/>
                <TextField source="config_key" label="Key"/>
                <TextField source="config_value" label="Value"/>
                <TextField source="description" label="Description"/>
                <DateField source="created_at" label="Created"/>
                <EditButton />
            </Datagrid>
        )
    }
  
    const handleChange = (id) => {
      setExpanded(id);
    };

    const {data = {}} = props
    const record = Object.values(data)

    const config_groups = record.map(e => e.config_group).filter((value, index, self) => {
        return self.indexOf(value) === index
    }).sort()
    const renderRow = (name, keys, style = {}) => {
        return (
            name === "app_version" ? 
                <React.Fragment>
                    {keys.platform && <TableCell style={style}>{keys.platform}</TableCell>}
                    {keys.bundleID && <TableCell style={style}>{keys.bundleID}</TableCell>}
                    <TableCell style={style}>{keys.config_value}</TableCell>
                </React.Fragment>
            : name === "point"? 
                <React.Fragment>
                    <TableCell style={style}>{keys.config_key}</TableCell>
                    {keys.coefficient && <TableCell style={style}>{keys.coefficient}</TableCell>}
                    {keys.config_value && <TableCell style={style}>{keys.config_value}</TableCell>}
                </React.Fragment>
            : <React.Fragment>
                    <TableCell style={style}>{keys.config_key}</TableCell>
                    <TableCell style={style}>{keys.config_value}</TableCell>
                </React.Fragment>
        );
    };
    return (
        <React.Fragment>
            {config_groups.map(name => {
                let configs = record.filter(e => e.config_group === name) || []; 
                const keys = {}
                Object.keys(configs[0] || {}).forEach(key => {
                    keys[key] = key.replace(/_/, " ").toUpperCase().replace("CONFIG", "")
                })
                if(configs.length === 0) return ""
                return (
                    <div key={name} className={classes.group}>
                        <div className={classes.title}>
                            <Typography variant="h6" gutterBottom>{name.replace(/_/, " ").toUpperCase()}</Typography>
                            <CreateButton basePath={`${props.basePath}${name}`} />
                        </div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {renderRow(name, keys)}
                                    <TableCell>{"Description"}</TableCell>
                                    <TableCell>{"Action"}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {([...configs]).map((c, i) => {
                                return (
                                    <> 
                                        <TableRow>
                                                {renderRow(name, c, {borderBottom: "unset"})}
                                                <TableCell style={{borderBottom: "unset"}}>{c.description}</TableCell>
                                                <TableCell style={{borderBottom: "unset"}}>
                                                        <EditButton record={c} basePath={`${props.basePath}${name}`} />
                                                        <IconButton onClick={() =>handleChange(c.id)}>
                                                            <ExpandMoreIcon />
                                                        </IconButton>
                                                </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{padding: 0}} colSpan={name === "app_version" || name === "point"  ? 5 : 4}>
                                                <Collapse in={expanded === c.id}>
                                                    <Box margin={1}>
                                                        <div className={classes.description}>
                                                            <Typography variant="subtitle2" gutterBottom>Description</Typography>
                                                            <Typography>{c.description}</Typography>
                                                        </div>
                                                    </Box>
                                                </Collapse>
                                                </TableCell>

                                        </TableRow>
                                    </>
                                )
                            })}
                            </TableBody>
                        </Table>

                    </div>
                )
            })}
        </React.Fragment>
    )
};

const ListComponent = (props) => {
    const classes = useStyles();  
    return (
        <List {...props} actions={<ListActions />} classes={props.resource !== "configs" ? {} : {
            content: classes.content,
        }} pagination={false} bulkActionButtons={false} sort={{ field: 'created_at', order: 'DESC' }}>
            <ConfigGroupComponent {...props} />
        </List>
    )
};

export default ListComponent
