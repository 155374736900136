import { USER_GROUP_OWNER_AGENCY } from "../../utils/const";
import { _endpointVipCustomer } from "../../utils/provider";
import { _createRequest, _getFilter, _pick, _fetch } from "../../utils/helper";

const usersEndpoint = async (props) => {
    const { type, user = {} } = props;
    let params = JSON.parse(JSON.stringify(props.params || {}));
    if (user.group === USER_GROUP_OWNER_AGENCY) {
        if (params.filter) params.filter.agency_id = user.agency_id;
        if (params.data) params.data.agency_id = user.agency_id;
    }

    switch (type) {
        case "one": {
            params.filter = {
                ...params.filter,
                user_id: params.id,
            };
            return _fetch(
                _createRequest(_endpointVipCustomer.list, true, {
                    filter: `${_getFilter(params, {
                        status: "eq",
                        user_id: "eq",
                        agency_id: "eq",
                    })}`,
                })
            ).then(async (res) => {
                let categoryIds = {};
                res.data = await (res.data || []).map((e = {}) => {
                    e.id = e.profile?.id;
                    e.commitments = e.commitments || [];
                    e.promotions = e.promotions || [];
                    if (e.commitments.length) {
                        categoryIds = {
                            ...categoryIds,
                            ...e.commitments.map((e) => e.category_id),
                        };
                    }
                    if (e.promotions.length) {
                        categoryIds = {
                            ...categoryIds,
                            ...e.promotions.map((e) => e.category_id),
                        };
                    }
                    categoryIds = Object.values(categoryIds);
                    e.categoryIds = categoryIds;
                    e.agency_id = user.agency_id;

                    return e;
                });
                let data = res.data[0];
                res.data = data;
                return res;
            });
        }
        case "update": {
            params.data = {
                ...params.data,
                id: params.id,
                user_id: params.id,
            };
            return _fetch(
                _createRequest(_endpointVipCustomer.update, true, {
                    ..._pick(params.data, [
                        "agency_id",
                        "user_id",
                        "promotions",
                        "status",
                    ]),
                })
            ).then((res) => {
                if (!res.data) res.data = params.data;
                return res;
            });
        }
        case "many":
        case "manyReference": {
            const { target = "id" } = params;
            if (params.ids) {
                params.filter = {
                    ...params.filter,
                    [target]: params.ids,
                };
            }
            params.pagination = {
                ...params.pagination,
                perPage: (params.ids || []).length || 10,
            };
            return _fetch(
                _createRequest(_endpointVipCustomer.list, true, {
                    filter: _getFilter(params, {
                        user_id: "eq",
                        status: "eq",
                        agency_id: "in",
                    }),
                })
            );
        }
        default: {
            //list
            return _fetch(
                _createRequest(_endpointVipCustomer.list, true, {
                    filter: `${_getFilter(params, {
                        status: "eq",
                        user_id: "eq",
                        agency_id: "eq",
                    })}`,
                })
            ).then((res) => {
                res.data = (res.data || []).map((e = {}) => {
                    e.id = e.profile?.id;
                    return e;
                });
                return res;
            });
        }
    }
};

export default usersEndpoint;
