import React from "react";
import { useNotify, useMutation } from "react-admin";
import QRCode from "qrcode";

import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Dialog,
    TextField,
} from "@material-ui/core";
import { useState } from "react";

export default function GenerateOTP({
    email,
    onVerifyOTPSuccess,
    onDisabled2FA,
}) {
    const [open, setOpen] = useState(false);
    const [qrcodeURL, setQRcodeOTP] = useState();
    const [otpCode, setOTPCode] = useState();

    const notify = useNotify();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        onDisabled2FA();
        setOpen(false);
    };

    const [onGenerateOTP, { loading: generateOTPLoading }] = useMutation(
        {
            type: "generateOTP",
            resource: "profiles",
            payload: {
                username: email,
            },
        },
        {
            onSuccess: ({ data, status, message }) => {
                if (status) {
                    notify("generate OTP success");
                    if (data.otp_auth_url) {
                        QRCode.toDataURL(data.otp_auth_url)
                            .then(setQRcodeOTP)
                            .catch((err) => {
                                console.log("error", err);
                            });
                    }
                    handleClickOpen();
                } else {
                    notify(`generate OTP error: ${message}`, "warning");
                }
            },
            onFailure: (error) =>
                notify(`generate OTP error: ${error.message}`, "warning"),
        }
    );

    const [onVerifyOTP, { loading: verifyOTPLoading }] = useMutation(
        {
            type: "verifyOTP",
            resource: "profiles",
            payload: {
                otp_code: otpCode,
            },
        },
        {
            onSuccess: ({ data, status, message }) => {
                if (status) {
                    notify("verify OTP success");
                    handleClose();
                    onVerifyOTPSuccess();
                } else {
                    notify(`verify OTP error: ${message}`, "warning");
                }
            },
            onFailure: (error) =>
                notify(`verify OTP error: ${error.message}`, "warning"),
        }
    );

    return (
        <>
            <Button
                color="primary"
                variant="outlined"
                disabled={generateOTPLoading}
                onClick={onGenerateOTP}
            >
                {"Setup 2FA"}
            </Button>
            {open && (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        Two-Factor Authentication
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <li>
                                Install Google Authenticator (IOS - Android)
                            </li>
                            <li>In the authenticator app, select "+" icon.</li>
                            <li>
                                Select "Scan a barcode (or QR code)" and use the
                                phone's camera to scan this barcode.
                            </li>
                        </DialogContentText>
                        <div>
                            <h4>Scan QR Code</h4>
                            <div>
                                <img src={qrcodeURL} alt="qrcode url" />
                            </div>
                        </div>
                        <TextField
                            label="OTP code"
                            onChange={(e) => setOTPCode(e.target.value)}
                            value={otpCode}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose} color="primary">
                            Hủy
                        </Button>
                        <Button
                            onClick={onVerifyOTP}
                            color="primary"
                            disabled={verifyOTPLoading}
                            autoFocus
                        >
                            Verify
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
