import {_endpointConfigAgency} from '../../utils/provider';
import {_createRequest, _pick, _fetch} from '../../utils/helper';
import {_clientUserStorage} from '../../utils/storage';
import { _parseQuery} from "../../utils/helper";

const endpoint = async(props) => {
    const {key, type, params = {}} = props;
    switch(type) {
        case "create": {
            let {data = {}} = params;
            if (data.config_group === "app_version") {
                data.agency_id = ""
                if(data.platform && data.bundleID) data.config_key = data.platform + "-" + data.bundleID
            }
            if(key === "configs") {
                data.agency_id = ""
            }
            return _fetch(_createRequest(_endpointConfigAgency.create, true, {
                ..._pick(data, ["agency_id", "config_group", "config_key", "config_value", "description", "coefficient"])
            }))
        }
        case "one": {
            const user = await _clientUserStorage.get() || {};
            if(!user.agency_id) {
                const str = window.location.toString().split(`${params.id}?`)[1];
                const query = _parseQuery(str)
                if(!params.agency_id && query && query.agency_id) {
                    params.agency_id = query.agency_id
                }
            } else {
                params.agency_id = user.agency_id
            }
            return _fetch(_createRequest(_endpointConfigAgency.one, true, {
                agency_id: key === "configs" ? "" : params.agency_id || user.agency_id,
            }))
            .then(res => {
                const data = (res.data || []).find(e => e.id === params.id) || {}
                if(data.config_group === "app_version") {
                    data.platform = data.config_key.split("-")[0] || "";
                    data.bundleID = data.config_key.replace(`${data.platform}-`, "")
                }
                res.data = data;
                return res
            })
        }
        case "update": {
            let {data = {}} = params;
            if (data.config_group === "app_version") {
                data.agency_id = ""
                if(data.platform && data.bundleID) data.config_key = data.platform + "-" + data.bundleID
            }
            return _fetch(_createRequest(_endpointConfigAgency.update, true, {
                ..._pick(data, ["id", "config_group", "config_key", "config_value", "description", "coefficient"])
            }))
        }
        case "manyReference": {
            return _fetch(_createRequest(_endpointConfigAgency.one, true, {
                agency_id: params.id,
            }))
            .then(res => {
                return {
                    data: res.data,
                    total: res.data.length
                }
            })
        }
        case "all": {
            const getAll = (page = 1, data = []) => {
                const perPage = 100;
                params.pagination = {
                    perPage: perPage,
                    page: page
                }

                return _fetch(_createRequest(_endpointConfigAgency.one, true, {
                    agency_id: params.agency_id,
                })).then(res => {
                    if (res.total === undefined || res.total <= page * perPage) {
                        return {
                            ...res,
                            data: [...data, ...res.data]
                        }
                    } else {
                        return getAll(page + 1,  [...data, ...res.data])

                    }
                })
            }
            return getAll(1)
        }
        default: { //list
            const user = await _clientUserStorage.get() || {};
            if(!user.agency_id) {
                const str = window.location.toString().split(`${params.id}?`)[1];
                if(_parseQuery(str) && _parseQuery(str).agency_id) {
                    params.agency_id = _parseQuery(str).agency_id
                }
            }
            return _fetch(_createRequest(_endpointConfigAgency.one, true, {
                agency_id: key === "configs" ? "" : params.agency_id || user.agency_id,
            }))
            .then(res => {
                return {
                    data: res.data,
                    total: res.data.length
                }
            })
        }
    }
}

export default endpoint