import { useQuery } from 'react-admin';

function useGetAllWarehouseAgencyStatistic(agencyID) {
    const {data = [], loading, loaded, error} = useQuery(
        {
            type: "all",
            resource: "warehouseagencies",
            payload: {
                filter: {
                    agency_id: agencyID,
                },
                sort: [{
                    field: "created_at",
                    order: "DESC",
                },{
                    field: "id",
                    order: "ASC",
                }],
            },
        },
        []
    );
    return {
        data, 
        loading, 
        loaded, 
        error,
    }
}

export default useGetAllWarehouseAgencyStatistic;
