import currencyFormatter from "currency-formatter";
import moment from "moment";
import lodash from "lodash";
import queryString from "query-string";
import jwtDecode from "jwt-decode";

import { _throwMessageError } from "./error";
import { _baseURL } from "./const";
import { _clientStorage, _clientLangueStorage } from "./storage";
const defaultHeader = {
    "content-type": "application/json",
};
export const _decodedToken = (token) => {
    try {
        return jwtDecode(token);
    } catch (err) {
        console.log(err);
        return null;
    }
};
export const _formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY HH:mm");
};
export const _getHeader = {
    public: (lang = "vi") => {
        return {
            ...defaultHeader,
            "accept-language": lang,
        };
    },
    secure: (token = "", lang = "vi") => {
        return {
            ...defaultHeader,
            Authorization: token,
            "accept-language": lang,
        };
    },
};

export const _createRequest = (
    path = "/",
    secure = false,
    body = {},
    lang = ""
) => {
    if (!lang) lang = _clientLangueStorage.get() || "vi";
    return new Request(_baseURL + path, {
        method: "POST",
        body: JSON.stringify(body),
        headers: secure
            ? _getHeader.secure(_clientStorage.get(), lang)
            : _getHeader.public(lang),
    });
};

export const _convertToPageAndSie = (pagination = {}) => {
    const { page = 1, perPage = 10 } = pagination;
    let offset = (page - 1) * perPage;

    return {
        offset: offset,
        limit: perPage,
    };
};
export function _pick(obj = {}, props = []) {
    const results = {};
    for (let i = 0; i < props.length; i++) {
        if (typeof obj[props[i]] !== "undefined") {
            results[props[i]] = obj[props[i]];
        }
    }

    return results;
}

export function _parseAddress(payload = {}) {
    let {
        address_components = [],
        location = {},
        formatted_address = "",
    } = payload;

    let data = {};
    let city =
        address_components.find(
            (e) =>
                e.types &&
                e.types.find((i) => i === "administrative_area_level_1")
        ) || {};
    let country =
        address_components.find(
            (e) => e.types && e.types.find((i) => i === "country")
        ) || {};
    let district =
        address_components.find(
            (e) =>
                e.types &&
                e.types.find((i) => i === "administrative_area_level_2")
        ) || {};

    data.district = district.long_name || "";
    data.city = city.long_name || "";
    data.country = country.long_name || "";
    data.address = formatted_address || "";
    data.latitude = location.latitude || 0;
    data.longitude = location.longitude || 0;
    return data;
}
export function _generateFilter(filter = {}) {
    for (var prop in filter) {
        if (filter.hasOwnProperty(prop)) {
            _throwMessageError(filter);
        }
    }
}
export function _convertFilter2String(filter = {}, typeFilter = {}) {
    let r = "";
    for (var prop in filter) {
        if (filter.hasOwnProperty(prop)) {
            let v = filter[prop];
            let type = typeFilter[prop];
            if (
                (type === "in" || type === "inq" || type === "nin") &&
                !Array.isArray(v)
            ) {
                r += `{"${prop}": {"eq": "${v}"}}, `;
            } else if (Array.isArray(v)) {
                if (type !== "in" && type !== "inq" && type !== "nin") {
                    type = "in";
                }
                r += `{"${prop}": {"${type}": ${JSON.stringify(v)}}}, `;
            } else if (typeof v === "object") {
                // r += `{"${prop}": ${_convertFilter2String(v, type)}}, `
                continue;
            } else {
                if (v !== undefined) {
                    if (!type) type = "ilike";
                    if (type === "like" || type === "ilike") v = `%${v}%`;
                    if (type === "is_null") v = `${v}`;
                    else if (typeof v !== "number") v = `"${v}"`;
                    r += `{"${prop}": {"${type}": ${v}}}, `;
                }
            }
        }
    }

    return r.slice(0, -2);
}

export function _generateLimitOffsetAndFilter(
    limit = 10,
    size = 0,
    sort = { field: "created_at", order: "DESC" },
    filter = ""
) {
    let querySort = "";
    if (sort === null) {
        querySort = `"created_at DESC"`;
    } else if (!Array.isArray(sort) && sort.field) {
        querySort = `"${sort.field} ${sort.order || "DESC"}"`;
    } else if (Array.isArray(sort)) {
        querySort = sort
            .filter((e) => e.field)
            .map((e) => {
                return `"${e.field} ${e.order || "DESC"}"`;
            })
            .join(", ");
    }

    if (querySort === "") {
        querySort = '"created_at DESC"';
    }

    filter = filter ? `"where":{${filter}}, ` : "";

    return `{${filter}"order":[${querySort}], "limit": ${limit}, "offset":${size}}`;
}

export function _getFilter(params = {}, type) {
    let filter = params.filter || {};
    Object.keys(filter).forEach((e) => {
        if (filter[e] === null || filter[e] === undefined) {
            delete filter[e];
        }
    });

    const where =
        filter && Object.keys(filter).length > 0
            ? `"and": [${_convertFilter2String(filter, type)}]`
            : "";
    const { limit, offset } = _convertToPageAndSie(params.pagination);
    return _generateLimitOffsetAndFilter(limit, offset, params.sort, where);
}

export const _getValueFromJSON = (field = [], record = {}) => {
    if (field.length < 1) return "";
    let data = record[field[0]];
    if (data && field.length > 1) {
        for (let i = 1; i < field.length; i++) {
            if (data[field[i]]) {
                data = data[field[i]];
            }
        }
    }
    return data;
};

export const _formatPrice = (price = 0, code = "VND") => {
    price = Number(price);
    return currencyFormatter.format(price, { code: code });
};

export const _getLodash = (record = {}, source = "") => {
    try {
        const value = lodash.get(record, source);
        return value || "";
    } catch (err) {
        return "";
    }
};

export const _setLodash = (record = {}, source = "", value = "") => {
    try {
        return lodash.set(record, source, value);
    } catch (err) {
        console.log(err);
    }
    return {};
};

export const _parseQuery = (search = "") => {
    return queryString.parse(search);
};
export const _stringifyQuery = (search = {}) => {
    return queryString.stringify(search);
};
export const _wrapResponse = (res = {}) => {
    if (!res.status) {
        if (res.hasOwnProperty("err_code") && res.err_code) {
            return _throwMessageError(res);
        } else {
            throw new Error(res.debug_message || res.message);
        }
    }
    if (!res.hasOwnProperty("data")) res.data = res;
    else {
        const data = res.data;
        res.rawData = data;
        //employee salary get one
        if (data.data) res.data = data.data;
        else res.data = data;
        if (data.total !== undefined) res.total = data.total;
    }
    return res;
};
export const _fetch = (payload = {}) => {
    return fetch(payload)
        .then((res) => res.json())
        .then((res) => {
            return _wrapResponse(res);
        })
        .catch((err) => {
            return _throwMessageError(err);
        });
};
