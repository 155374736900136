import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Create, SimpleForm } from "react-admin";

import { PartnerForm } from "./edit";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        },
    },
}));
const CreateComponent = (props) => {
    const classes = useStyles();
    return (
        <Create {...props}>
            <SimpleForm className={classes.form}>
                <PartnerForm />
            </SimpleForm>
        </Create>
    );
};

export default CreateComponent;
