import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Card} from '@material-ui/core';
import {Button, useRedirect, SimpleForm, Toolbar, FileField, FileInput, useNotify, useMutation} from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import BackspaceIcon from '@material-ui/icons/Backspace';
import { useForm } from 'react-final-form';

const useStyles = makeStyles(theme => ({
    removeBtn: {
        backgroundColor: "rgb(220, 0, 78)",
    },
    cardActions: {
        textAlign: "right",
        width: "100%",
        margin: theme.spacing(1) * -1,
        "& > button": {
            margin: theme.spacing(1),
        },
        marginTop: theme.spacing(3),
    },
}));

const CustomToolbar = props => {
    const classes = useStyles();
    const redirect = useRedirect();
    const notify = useNotify();
    const form = useForm();

    // eslint-disable-next-line no-empty-pattern
    const [handleImport, {}] = useMutation({
        type: "import",
        resource: props.resource,
        payload: {
            ...form.getState().values,
        }
    }, {
        onSuccess: (res) => {
            if (res.error) {
                notify(`Error: ${res.message}`, 'warning');
            } else {
                notify(`Import done`)
                redirect(props.basePath.replace("/import", ""))
            }
        },
        onFailure: (error) => {
            notify(`Error: ${error.message}`, 'warning');
        },

    })
    const handleCancel = () => {
        redirect(props.basePath.replace("/import", ""))
    }
    return (
        <Toolbar {...props} className={classes.cardActions}>
            <Button
                onClick={handleImport}
                label="Import"
                variant="contained"
            >
                <SaveIcon />
            </Button>
            <Button
                onClick={handleCancel}
                label="Cancel"
                variant="contained"
                className={classes.removeBtn}
            >
                <BackspaceIcon />
            </Button>
        </Toolbar>
    );
}

const ImportComponent = ({ classes, className, ...props }) => {
    return (
        <Card>
            <SimpleForm {...props} toolbar={<CustomToolbar {...props}/>}>
                <FileInput source="files" label="Related files" accept="text/csv">
                    <FileField source="src" title="title" />
                </FileInput>
            </SimpleForm>
        </Card>
    );
}

export default ImportComponent;