import React, { useCallback } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import {
    EditButton,
    DeleteWithConfirmButton,
    useRedirect,
    ShowButton,
    Mutation,
    useNotify,
    useRefresh,
} from "react-admin";
import { ButtonField } from "../../../partials";
import {
    _actionOrderTransition,
    _roleOrderTransition,
    _groupPermission,
} from "../../../utils/enum";
import SendNotification from "./sendNotification";

const ActionComponent = (props) => {
    const { record = {}, nameBtn = "..." } = props;
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();
    const customPermission = _groupPermission(props.permissions);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [actions, setActions] = React.useState([]);
    const handleBack = useCallback(() => {
        redirect(`${props.basePath}`);
    }, [redirect, props.basePath]);

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    React.useEffect(() => {
        if (customPermission && _roleOrderTransition[customPermission]) {
            const status =
                (record.status || "").indexOf("ORDER_STATUS_") < 0
                    ? `ORDER_STATUS_${record.status}`
                    : record.status;
            let data =
                _actionOrderTransition.filter((e) =>
                    e.Src.find((i) => i === status)
                ) || [];
            const roles = _roleOrderTransition[customPermission];
            data = data.filter((e) => roles.find((i) => i === e.Name));
            setActions(data);
        }
    }, [customPermission, record]);

    let html = [];
    if (
        record.status === "" ||
        record.status === "DRAFT" ||
        record.status === "ORDER_STATUS_DRAFT"
    ) {
        html = [
            <DeleteWithConfirmButton {...props} label={"Delete"} />,
            <EditButton {...props} label={"Edit"} />,
        ];
    }
    if (!props.isShow) {
        html = [html, ...[<ShowButton {...props} label={"Show"} />]];
    }
    html = [
        html,
        ...actions
            .filter((e) => e.Name !== "ORDER_TRANSITION_ASSIGN")
            .map((role = {}) => {
                if (role.Name === "ORDER_TRANSITION_CANCEL") {
                    if (props.resource === "ordersonassigning") {
                        return (
                            <DeleteWithConfirmButton
                                {...props}
                                label={"Delete"}
                            />
                        );
                    }
                    return (
                        <DeleteWithConfirmButton {...props} label={"Delete"} />
                    );
                }
                if (
                    role.Name === "ORDER_TRANSITION_ACCEPT" ||
                    role.Name === "ORDER_TRANSITION_MARKED_RETURNED"
                ) {
                    const options = {
                        undoable: false,
                        onSuccess: ({ data }) => {
                            notify("Done", "info", {}, false);
                            redirect(props.basePath);
                            refresh();
                        },
                        onFailure: (error) =>
                            notify(`Error: ${error.message}`, "warning"),
                    };
                    return (
                        <Mutation
                            type={
                                role.Name === "ORDER_TRANSITION_ACCEPT"
                                    ? "finish"
                                    : "markedReturned"
                            }
                            resource={props.resource}
                            payload={{ data: { order_ids: [record.id] } }}
                            options={options}
                        >
                            {(approve, { loading }) => {
                                return (
                                    <ButtonField
                                        color="secondary"
                                        name={
                                            role && role.Name
                                                ? role.Name.replace(
                                                      "ORDER_TRANSITION_",
                                                      ""
                                                  )
                                                : ""
                                        }
                                        onClick={approve}
                                        disabled={loading}
                                    />
                                );
                            }}
                        </Mutation>
                    );
                }
                return (
                    <ButtonField
                        disabled
                        color="secondary"
                        name={
                            role && role.Name
                                ? role.Name.replace("ORDER_TRANSITION_", "")
                                : ""
                        }
                    />
                );
            }),
    ];

    return (
        <React.Fragment>
            {props.isShow && (
                <Button
                    onClick={handleBack}
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                >
                    Back
                </Button>
            )}
            {record.status === "" ||
            record.status === "DONE" ||
            record.status === "ORDER_STATUS_DONE" ||
            (!(
                record.status === "" ||
                record.status === "DRAFT" ||
                record.status === "ORDER_STATUS_DRAFT"
            ) &&
                actions.length === 0) ? (
                !props.isShow ? (
                    <ShowButton {...props} label="Show" />
                ) : (
                    ""
                )
            ) : html.length === 0 ? (
                ""
            ) : html.length !== 1 ? (
                <React.Fragment>
                    <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        {nameBtn}
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {React.Children.map(html, (child, index) => {
                            return (
                                <MenuItem
                                    onClick={handleClose}
                                    key={`i-${index}`}
                                >
                                    {React.cloneElement(child, child.props)}
                                </MenuItem>
                            );
                        })}
                        <MenuItem onClick={handleClose}>
                            <SendNotification orderId={record.id} />
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            ) : (
                html
            )}
        </React.Fragment>
    );
};

export default ActionComponent;
