import {
  _clientStorage,
  _clientRefreshStorage,
  _clientUserStorage,
  _removeAll,
} from "../utils/storage";
import { _createRequest } from "../utils/helper";
import { _throwMessageError } from "../utils/error";

export default {
  login: ({ username, password, otp, user_id }) => {
    return fetch(
      _createRequest(
        otp
          ? "/gaogas.console/User/VerifyOTP"
          : "/gaogas.console/User/LoginEmailIn2FA",
        false,
        otp
          ? {
              otp_code: otp,
              user_id,
            }
          : {
              email: username,
              password: password,
            }
      )
    )
      .then((res) => res.json())
      .then(async (res) => {
        const { data } = res;
        if (res.status) {
          const { token, refresh_token, profile } = data;
          if (!token && profile) {
            return data;
          }

          await _clientUserStorage.set(profile);
          _clientRefreshStorage.set(refresh_token);
          return _clientStorage.set(token);
        } else {
          return _throwMessageError(res);
        }
      })
      .catch((err) => {
        _clientStorage.remove();
        _clientRefreshStorage.remove();
        return _throwMessageError(err);
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    _removeAll();
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: (error = {}) => {
    console.log(error);
    if (error.debug_msg && process.env.REACT_APP_ENV === "local") {
      error.message = error.debug_msg;
    }
    const { err_code } = error;
    if (err_code === 1015 || error.message === "ra.notification.logged_out") {
      _removeAll();
      return Promise.reject();
    } else return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {
    const user = await _clientUserStorage.get();
    if (!user) return Promise.reject();
    return await fetch(
      _createRequest("/gaogas.console/User/RefreshToken", true, {
        refresh_token: _clientRefreshStorage.get(),
      })
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          const { token, refresh_token, profile } = res.data;
          _clientRefreshStorage.set(refresh_token);
          _clientUserStorage.set(profile);
          _clientStorage.set(token);
          return Promise.resolve({ profile, token });
        } else {
          _removeAll();
          return Promise.reject();
        }
      })
      .catch((err) => {
        _removeAll();
        return Promise.reject(err);
      });
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: async () => {
    const user = await _clientUserStorage.get();
    if (user && user.group) {
      return Promise.resolve(user.group);
    }
    return Promise.reject();
  },
};
