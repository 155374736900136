import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

import { USER_GROUP_ADMIN } from "../../utils/const";
import { _clientUserStorage } from "../../utils/storage";
import { _arrayUserGroup } from "../../utils/enum";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        },
    },
}));
const Form = (props) => {
    const user = _clientUserStorage.get() || {};
    const agencyID = user.agency_id;
    const form = useForm();
    const formState = useFormState().values;
    useEffect(() => {
        form.change("agency_id", agencyID);
    }, [agencyID, form]);

    return (
        <Grid container spacing={3}>
            <Grid item sm={12}>
                <TextInput label="Title" source="title" fullWidth />
            </Grid>
            <Grid item sm={12}>
                <TextInput
                    label="Content"
                    source="content"
                    multiline
                    rows={4}
                    fullWidth
                />
            </Grid>
            {props.permissions === USER_GROUP_ADMIN ? (
                <Grid item sm={12}>
                    <BooleanInput label="Send All" source="send_all" />
                    <ReferenceInput
                        label="Agency"
                        source="agency_id"
                        reference="agencies"
                    >
                        <SelectInput
                            disabled={formState.send_all}
                            optionText="name"
                            fullWidth
                        />
                    </ReferenceInput>
                </Grid>
            ) : (
                ""
            )}

            <Grid item sm={12}>
                <SelectInput
                    label="Group"
                    source="user_group"
                    choices={_arrayUserGroup}
                    fullWidth
                    defaultValue={1}
                />
            </Grid>
            <Grid item sm={12}>
                <TextInput
                    label="Sound"
                    source="sound"
                    rows={4}
                    fullWidth
                />
            </Grid>
        </Grid>
    );
};

const CreateComponent = (props) => {
    const classes = useStyles();
    return (
        <Create {...props} title="Create">
            <SimpleForm className={classes.form} redirect={"list"}>
                <Form {...props} />
            </SimpleForm>
        </Create>
    );
};

export default CreateComponent;
