
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from "prop-types";
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, useFormState } from "react-final-form";
import { Card, Paper, Grid, CardHeader, CardContent, TextField } from '@material-ui/core';
import { useDataProvider, Loading, useNotify, Datagrid, AutocompleteInput, NumberField, ArrayField, SimpleForm, } from 'react-admin';
import { DateField, PriceField } from "../../partials"
import { _parseQuery, _stringifyQuery } from '../../utils/helper'
import { useGetAllUsers } from '../../hooks'

const useStyles = makeStyles(theme => ({
    box: {
        display: "flex",
        margin: theme.spacing(-3)
    },
    row: {
        display: "flex",
        margin: theme.spacing(3),
        "& > div:first-child": {
            paddingRight: theme.spacing(1),
        }
    }
}));

const ProductField = (props) => {
    const { record = {} } = props;
    const classes = useStyles();
    const statistics = record.statistics || {};
    const keys = Object.keys(statistics).filter(e => e.indexOf("total_") >= 0);

    return (
        <div className={classes.box}>
            {keys.map(key => {
                return (
                    <div className={classes.row}>
                        <div>{key.replace("total_", "")}:</div>
                        <NumberField {...props} source={`statistics.${key}`} />
                    </div>
                )
            })}
        </div>
    );
};
ProductField.propTypes = {
    record: PropTypes.object,
    label: PropTypes.string,
}

const BonusField = (props) => {
    const { record = {} } = props;
    const classes = useStyles();
    const statistics = record.statistics || {};
    const keys = Object.keys(statistics).filter(e => e.indexOf("bonus_rate_") >= 0);
    return (
        <div>
            <p><strong>Bonus</strong></p>
            <div className={classes.box}>
                {keys.map(key => {
                    return (
                        <div className={classes.row}>
                            <div>{key.replace("bonus_rate_", "")}:</div>
                            <NumberField {...props} source={`statistics.${key}`} />
                        </div>
                    )
                })}
            </div>
        </div>
    );
};
BonusField.propTypes = {
    record: PropTypes.object,
    label: PropTypes.string,
}
const SalaryField = (props) => {
    const classes = useStyles();
    return (
        <div>
            <p><strong>Salary</strong></p>
            <div className={classes.box}>
                <div className={classes.row}>
                    <div>Basic:</div>
                    <PriceField {...props} source="basic_salary" />
                </div>
                <div className={classes.row}>
                    <div>Bonus:</div>
                    <PriceField {...props} source="bonus_salary" />
                </div>
                <div className={classes.row}>
                    <div>Total:</div>
                    <PriceField {...props} source="total_salary" />
                </div>
            </div>
        </div>
    );
};
SalaryField.propTypes = {
    classes: PropTypes.object,
    record: PropTypes.object,
    label: PropTypes.string,
}

const DetailComponent = (props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const form = useForm();
    const formData = useFormState().values;
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const { data: dataEmployees = [] } = useGetAllUsers("employeeagencies", JSON.stringify({
        user_group: "USER_GROUP_EMPLOYEE_AGENCY",
    }));
    useEffect(() => {
        const value = _parseQuery(props.location.search)
        if (value && value.employee_id && value.month && value.year) {
            form.batch(() => {
                form.change("employee_id", value.employee_id);
                form.change("month", Number(value.month));
                form.change("year", Number(value.year));
            })
        }
    }, [form, props.location.search])

    const handleDateChange = useCallback((e) => {
        const value = e.target.value
        const month = moment(value).month() + 1;
        const year = moment(value).year()

        form.batch(() => {
            form.change("month", Number(month));
            form.change("year", Number(year));
        })

    }, [form])


    const getData = useCallback(() => {
        setLoading(true);
        return dataProvider.getOne(props.resource, formData)
            .then((res) => {
                if (res.error) {
                    setLoading(false);
                    if (res.message === "NotFound" && res.err_code === 1000) {
                        setData({});
                        notify(`Dont have data for this month`, 'info');
                    } else {
                        notify(`${res.message || res._message}`, 'warning');
                    }
                    return
                }
                const { rawData = {} } = res;
                let { summary = {} } = rawData
                if (summary.statistics && typeof summary.statistics !== "object") {
                    summary.statistics = JSON.parse(summary.statistics);
                }
                let newStatistics = {
                    total: 0,
                }
                if (summary && summary.statistics) {
                    const statistics = summary.statistics || {};
                    const keys = Object.keys(statistics).filter(e => e.indexOf("total_") >= 0);
                    keys.forEach(key => {
                        const bonusKey = `bonus_rate_${key.replace("total_", "")}`
                        const bonus = Number(statistics[bonusKey]);
                        newStatistics[bonusKey] = bonus;
                        res.data = res.data.map(e => {
                            if (e.statistics) {
                                const bonusOfKey = `bonus_of_${key.replace("total_", "")}`;
                                e.statistics[bonusOfKey] = bonus * Number(e[key]);
                                e.statistics.total = (e.statistics.total || 0) + Number(bonus)

                                newStatistics[bonusOfKey] = bonus * Number(e[key]);
                                newStatistics.total += Number(bonus)
                            }
                            return e;
                        })
                    })
                }
                res.data = [...res.data, ...[{ statistics: newStatistics }]]
                res.summary = summary;
                setData(res);
                setLoading(false);
            })
            .catch(error => {
                if (error.message === "NotFound" && error.err_code === 1000) {
                    setData({});
                    notify(`Dont have data for this month`, 'info');
                } else {
                    notify(error.message, 'warning');
                }
                setLoading(false);
            })
    }, [dataProvider, notify, formData, props.resource])

    useEffect(() => {
        if (formData && formData.employee_id && formData.month && formData.year) {
            if (props.history && props.history.location && props.history.push) {
                const location = props.history.location;
                props.history.push({
                    pathname: location.pathname,
                    search: `?${_stringifyQuery({
                        employee_id: formData.employee_id,
                        year: formData.year,
                        month: formData.month
                    })}`
                })
            }
            getData()
        }
    }, [formData, getData, props.history])

    if (loading) return <Loading />;

    return (
        <Paper>
            <Card>
                <CardHeader title="Detail" />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item sm={6}>
                            <TextField variant="outlined" margin="normal" label="Time" type="month" fullWidth value={moment(`${formData.year}-${formData.month}`).format("YYYY-MM")} onChange={handleDateChange} InputLabelProps={{ shrink: true }} />
                        </Grid>
                        <Grid item sm={6}>
                            <AutocompleteInput variant="outlined" margin="normal" fullWidth label="Employee" source="employee_id" choices={dataEmployees} optionText="name" optionValue="id" translateChoice={false} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={6}>
                            <BonusField record={data.summary} data={data} />
                        </Grid>
                        <Grid item sm={6}>
                            <SalaryField record={data.summary} data={data} />
                        </Grid>
                    </Grid>
                    <br />
                    <ArrayField record={data} source="data" label="Data">
                        <Datagrid>
                            <DateField source="date" label="Date" />
                            <ProductField label="Product" />
                            <PriceField source="statistics.total" label="Total" />
                        </Datagrid>
                    </ArrayField>

                </CardContent>
            </Card>
        </Paper>
    )
};

const Component = (props) => {
    return (
        <SimpleForm {...props} style={{ width: "100%" }}>
            <DetailComponent {...props} />
        </SimpleForm>
    )
};

export default Component;