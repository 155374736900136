import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useForm, useFormState } from "react-final-form";
import {
    Create,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    DateInput,
    ArrayInput,
    SelectInput,
    NumberInput,
    FormDataConsumer,
    FileField,
    FileInput,
    useNotify,
} from "react-admin";

import { filterItems } from "../../hooks";
import { useProductsContext } from "../../context";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& > div > div:first-child": {
            width: "100%",
        },
        marginBottom: theme.spacing(1),
    },
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        },
    },
    rowWarning: {
        backgroundColor: "#ffcece !important",
    },
}));

const UploadFile = ({ products = [], ...props }) => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const form = useForm();

    const handleChange = (values) => {
        setLoading(true);
        if (values) {
            if (values.size > 1 * 1024 * 1024) {
                notify("File is too big");
                setLoading(false);
                return;
            }
            var reader = new FileReader();
            reader.onload = function (e) {
                let value = (reader.result.split("\n") || [])
                    .slice(1)
                    .map((e) => {
                        let [product_id, quantity, price] = e.split(",");
                        console.log(e);
                        return {
                            product_id,
                            quantity: Number(quantity),
                            price: Number(price),
                        };
                    });
                value = value.filter(
                    (e = {}) =>
                        e.product_id !== "" &&
                        products.find((i) => i.id === e.product_id) &&
                        e.quantity > 0 &&
                        e.price > 0
                );
                setLoading(false);

                form.batch(() => {
                    form.change("items", []);
                    form.change(
                        "transfer_product.total_products",
                        value.length
                    );
                    form.change("importData", value);
                });
            };
            reader.readAsText(values);
        } else {
            setLoading(false);
            form.change("importData", []);
            form.change("transfer_product.total_products", 0);
        }
    };
    return (
        <>
            {loading && <div className="loader"></div>}
            <FileInput
                source="files"
                label="Related files"
                onChange={handleChange}
            >
                <FileField source="src" title="title" />
            </FileInput>
        </>
    );
};
const ItemFormComponent = ({
    products = [],
    sourceProduct = "product_id",
    sourcePrice = "price",
    ...props
}) => {
    return (
        <FormDataConsumer {...props}>
            {({ getSource, formData = {}, scopedFormData = {} }) => {
                return (
                    <Grid container spacing={3}>
                        <Grid item md={4}>
                            <SelectInput
                                id="product-id"
                                optionText={(e) =>
                                    `${e.name} - ${e.quantity || 0}`
                                }
                                label="Product"
                                source={getSource(sourceProduct)}
                                choices={filterItems(
                                    products,
                                    formData.items || [],
                                    scopedFormData || {},
                                    sourceProduct
                                )}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={4}>
                            <NumberInput
                                label={"Price"}
                                source={getSource(sourcePrice)}
                                defaultValue={100000}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={4}>
                            <NumberInput
                                label="Quantity"
                                source={getSource("quantity")}
                                defaultValue={1}
                                fullWidth
                                autoComplete="off"
                            />
                        </Grid>
                    </Grid>
                );
            }}
        </FormDataConsumer>
    );
};
const ItemComponent = ({ products = [], source, getPrice, ...props }) => {
    const record = useFormState().values;
    const classes = useStyles();

    if ((record.importData && record.importData.length) || record.files) {
        return "";
    }
    return (
        <ArrayInput source="items" record={record}>
            <SimpleFormIterator
                classes={{
                    form: classes.root,
                }}
            >
                <ItemFormComponent
                    {...props}
                    getPrice={getPrice}
                    products={products}
                />
            </SimpleFormIterator>
        </ArrayInput>
    );
};
const CreateComponent = (props) => {
    const classes = useStyles();
    const { products = [] } = useProductsContext();
    return (
        <Create {...props}>
            <SimpleForm className={classes.form} redirect={"list"}>
                <Grid container spacing={3}>
                    <Grid item md={4} sm={12}>
                        <DateInput
                            label="Import at"
                            source="import_at"
                            defaultValue={new Date()}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextInput
                            label="Note"
                            source="note"
                            multiline
                            rows={3}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={12}>
                        <UploadFile products={products} />
                    </Grid>

                    <Grid item md={12}>
                        <ItemComponent
                            label="Item"
                            {...props}
                            products={products}
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default CreateComponent;
export { ItemFormComponent };
