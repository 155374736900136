import { _endpointNotification } from "../../utils/provider";
import { _createRequest, _getFilter, _pick, _fetch } from "../../utils/helper";
import { USER_GROUP_OWNER_AGENCY } from "../../utils/const";

const endpoint = async (props) => {
    const { type, user = {} } = props;
    let params = JSON.parse(JSON.stringify(props.params || {}));

    if (user.group === USER_GROUP_OWNER_AGENCY) {
        if (params.filter) params.filter.agency_id = user.agency_id;
        if (params.data) params.data.agency_id = user.agency_id;
    }

    switch (type) {
        case "create": {
            let { user_group, agency_id, send_all } = _pick(params.data, [
                "send_all",
                "agency_id",
                "user_group",
            ]);

            return _fetch(
                _createRequest(_endpointNotification.create, true, {
                    ..._pick(params.data, ["title", "content", "sound"]),
                    filter: {
                        user_group,
                        agency_id,
                        send_all,
                    },
                })
            );
        }
        case "approve": {
            return _fetch(
                _createRequest(_endpointNotification.another.approve, true, {
                    ..._pick(params, ["id", "resend"]),
                })
            ).then((res) => {
                return {
                    data: params.id,
                };
            });
        }
        case "update": {
            return _fetch(
                _createRequest(_endpointNotification.update, true, {
                    ..._pick(params.data, ["id", "title", "content", "sound"]),
                })
            ).then((res) => {
                return {
                    data: params.id,
                };
            });
        }
        case "delete": {
            return _fetch(
                _createRequest(_endpointNotification.delete, true, {
                    ..._pick(params, ["id"]),
                })
            ).then((res) => {
                return {
                    data: params.id,
                };
            });
        }
        default: {
            //list
            return _fetch(
                _createRequest(_endpointNotification.list, true, {
                    filter: `${_getFilter(params, {
                        agency_id: "eq",
                        id: "eq",
                    })}`,
                })
            );
        }
    }
};

export default endpoint;
