import React from "react";
import { Grid, FormLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
    TextField,
    NumberField,
    ArrayField,
    Datagrid,
    ReferenceField,
    BooleanField,
} from "react-admin";
import {
    PriceField,
    UserField,
    DateField,
    EnumField,
    PhoneField,
    LatlngField,
} from "../../../partials";
import { _enumOrderStatus } from "../../../utils/enum";
import { USER_GROUP_ADMIN } from "../../../utils/const";
const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: "0.875rem",
        marginBottom: theme.spacing(1),
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: "0.00938em",
        display: "block",
    },
    rootGrid: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        boxSizing: "border-box",
        "& > div": {
            marginBottom: theme.spacing(3),
        },
    },
}));

const DataComponent = (props) => {
    const classes = useStyles();
    const requireProps = {
        record: props.record,
        basePath: props.basePath,
        resource: props.resource,
        input: undefined,
    };
    return (
        <Grid spacing={3} className={classes.rootGrid}>
            <Grid item md={6}>
                <FormLabel className={classes.label}>{"Employee"}</FormLabel>
                <UserField {...requireProps} source="employee_info" />
            </Grid>
            <Grid item md={6}>
                <FormLabel className={classes.label}>{"Customer"}</FormLabel>
                <UserField {...requireProps} source="customer_info" />
            </Grid>
            <Grid item md={4}>
                <FormLabel className={classes.label}>{"Phone"}</FormLabel>
                <PhoneField {...requireProps} source="phone_number" />
            </Grid>
            <Grid item md={4}>
                <FormLabel className={classes.label}>{"Total Point"}</FormLabel>
                <NumberField {...requireProps} source="total_point" />
            </Grid>
            <Grid item md={4}>
                <FormLabel className={classes.label}>
                    {"Total Prices"}
                </FormLabel>
                <PriceField {...requireProps} source="total_price" />
            </Grid>
            <Grid item md={6}>
                <FormLabel className={classes.label}>{"Address"}</FormLabel>
                <TextField {...requireProps} source="address" />
            </Grid>
            <Grid item md={6}>
                <FormLabel className={classes.label}>
                    {"Latitude/Longitude"}
                </FormLabel>
                <LatlngField {...requireProps} />
            </Grid>
            <Grid item md={4}>
                <FormLabel className={classes.label}>{"Note"}</FormLabel>
                <TextField {...requireProps} source="note" multiline rows={3} />
            </Grid>
            <Grid item md={4}>
                <FormLabel className={classes.label}>{"Status"}</FormLabel>
                <EnumField
                    {...requireProps}
                    source="status"
                    _enum={_enumOrderStatus}
                    prefix="ORDER_STATUS_"
                />
            </Grid>
            <Grid item md={4}>
                <FormLabel className={classes.label}>{"Created At"}</FormLabel>
                <DateField {...requireProps} source="created_at" />
            </Grid>
            <Grid item md={12}>
                <FormLabel className={classes.label}>{"Items"}</FormLabel>
                <ArrayField {...requireProps} source="items">
                    <Datagrid>
                        <TextField label="Product ID" source="product_id" />
                        {props.permissions === USER_GROUP_ADMIN ? (
                            <ReferenceField
                                label="Product Name"
                                source="product_id"
                                reference="products"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        ) : (
                            ""
                        )}
                        <TextField source="name" label="Name" />
                        <NumberField source="quantity" label="Quantity" />
                        <BooleanField source="buy_shell" label="Buy Shell" />
                        <BooleanField source="is_gift" label="Is Gift" />
                    </Datagrid>
                </ArrayField>
            </Grid>
        </Grid>
    );
};
export default DataComponent;
