import { _endpointListCustomerAgency } from "../../utils/provider";
import { _endpointUser } from "../../utils/provider";
import { _createRequest, _getFilter, _pick, _fetch } from "../../utils/helper";

const usersEndpoint = async (props) => {
    const { type, params = {} } = JSON.parse(JSON.stringify(props));

    switch (type) {
case "one": {
            return _fetch(
                _createRequest(_endpointUser.one, true, {
                    ..._pick(params, ["id"]),
                })
            );
        }
        default: {
            //list
            return _fetch(
                _createRequest(_endpointListCustomerAgency.list, true, {
                    filter: `${_getFilter(
                        {
                            ...params,
                            filter: params.filter.filterStr,
                        },
                        {
                            created_at: "lte",
                        }
                    )}`,
                    customerPhone: params?.filter?.customerPhone,
                    employeeName: params?.filter?.employeeName,
                    month: params?.filter?.month,
                    year: params?.filter?.year,
                })
            ).then((res) => {
                res.data = res.data || [];
                console.log(res);
                return res;
            });
        }
    }
};

export default usersEndpoint;
