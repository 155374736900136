import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import LockIcon from "@material-ui/icons/Lock";
import { Grid, TextField as TextFieldInput, MenuItem } from "@material-ui/core";
import {
  Show,
  EditButton,
  TopToolbar,
  Button,
  SimpleShowLayout,
  TextField,
  NumberField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  useRedirect,
  ReferenceField,
} from "react-admin";

import Map from "../map";
import DialogComponent from "../users/resetPassword";
import DialogEditProductComponent from "./editProductPrice";
import ListOrderDataGrid from "../orders/list/list";
import { DatagridComponent as ListTransferDataGrid } from "../warehouseAgenciesTransfer/list";
import { DatagridComponent as WarehouseAgencyList } from "../warehouseAgencies/list";
import { DatagridComponent as UserList } from "../users/list";
import { DatagridComponent as ConfigComponent } from "../configAgency/list";
import { Component as DashboardComponent } from "../dashboard/list";

import { Pagination, ButtonField } from "../../partials";

import { _arrayTransferStatus } from "../../utils/enum";
const useStyles = makeStyles((theme) => ({
  map: {
    marginBottom: theme.spacing(3),
    minHeight: theme.spacing(10),
    width: "100%",
    height: theme.spacing(30),
    "& > div:first-child": {
      width: "100%",
      height: "100%",
    },
  },
  content: {
    overflow: "auto",
  },
}));
const MapComponent = (props) => {
  const classes = useStyles();
  const { record = {}, basePath, ...rest } = props;
  return (
    <div className={classes.map}>
      <Map {...rest} {...record} />
    </div>
  );
};

const ButtonComponent = (props) => {
  const redirect = useRedirect();
  const onClickCreateConfig = (e, record = {}) => {
    redirect(
      `/configagencies/create?agency_id=${props.id}`,
      `/configagencies`,
      props.id,
      {
        agency_id: props.id,
      }
    );
  };
  return (
    <ButtonField
      {...props}
      onClick={onClickCreateConfig}
      name="Create"
      addLabel={false}
      color="primary"
      bIcon={<AddIcon />}
    />
  );
};

const ShowActions = (props) => {
  const { basePath, data = {} } = props;
  const handleReset = () => {
    if (props.handleReset) props.handleReset(data);
  };
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      <Button onClick={handleReset} label="Reset Password">
        <LockIcon />
      </Button>
    </TopToolbar>
  );
};
const EditProductButtonComponent = (props) => {
  const handleClick = () => {
    props.handleClick(props.record);
  };
  return (
    <ButtonField
      {...props}
      onClick={handleClick}
      name="Edit"
      addLabel={false}
      color="primary"
      bIcon={<EditIcon />}
    />
  );
};

const ShowComponent = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const [status, setStatus] = useState(null);
  const [isReset, setIsReset] = useState();
  const [isEditProduct, setIsEditProduct] = useState();
  const [record, setRecord] = useState({});
  const [product, setProduct] = useState({});
  const handleClose = () => {
    setIsReset(false);
  };
  const handleCloseEditProduct = () => {
    setIsEditProduct(false);
  };
  const handleEditProduct = (data) => {
    setIsEditProduct(true);
    setProduct(data);
  };
  const handleReset = (data) => {
    setIsReset(true);
    setRecord(data);
  };
  const handleChange = (e) => {
    const { target = {} } = e;
    const { value } = target;
    setStatus(value);
  };
  const redirectConfig = (e, record = {}) => {
    redirect(
      `/configagencies/${record.id}?agency_id=${props.id}`,
      `/configagencies`,
      props.id,
      {
        ...record,
        agency_id: props.id,
      }
    );
  };
  return (
    <Show
      {...props}
      actions={<ShowActions handleReset={handleReset} />}
      title={`Detail ${props.resource}`}
    >
      <SimpleShowLayout>
        {isReset && (
          <DialogComponent
            {...props}
            classesContent={{
              root: classes.rootContent,
            }}
            record={record}
            user_id={record.owner ? record.owner.id : ""}
            anchor="right"
            handleClose={handleClose}
            title="Reset Password"
            maxWidth="md"
            open
            fullWidth
          />
        )}
        {isEditProduct && (
          <DialogEditProductComponent
            {...props}
            classesContent={{
              root: classes.rootContent,
            }}
            record={record}
            product={product}
            anchor="right"
            handleClose={handleCloseEditProduct}
            title="Edit Product"
            maxWidth="md"
            open
            fullWidth
          />
        )}
        <TextField label="Name" source="name" />
        <TextField label="Phone" source="phone_number" />
        <TextField label="Address" source="address" />
        <NumberField label="Latitude" source="latitude" />
        <NumberField label="Longitude" source="longitude" />
        <NumberField label="Radius" source="radius" />
        <ReferenceField
          label="Agency Type"
          source="agency_type"
          reference="agency_types"
        >
          <TextField source="name" />
        </ReferenceField>
        <MapComponent label="Map" source={"map"} zoom={10} />
        <TabbedShowLayout
          source={"tab"}
          classes={{
            content: classes.content,
          }}
        >
          <Tab label="Dashboard" path="dashboard">
            <DashboardComponent {...props}></DashboardComponent>
          </Tab>
          <Tab label="Warehouse">
            <ReferenceManyField
              label=" "
              pagination={<Pagination />}
              perPage={20}
              filter={{ "aw.agency_id": props.id }}
              reference="warehouseagenciesv2"
              target="aw.agency_id"
              sort={{ field: "p.id", order: "DESC" }}
            >
              <WarehouseAgencyList {...props}>
                <EditProductButtonComponent handleClick={handleEditProduct} />
              </WarehouseAgencyList>
            </ReferenceManyField>
          </Tab>
          <Tab label="Transfer" path="transfer">
            <Grid container spacing={3}>
              <Grid item sm={4}>
                <TextFieldInput
                  select
                  label="Status"
                  fullWidth
                  onChange={handleChange}
                  value={status}
                >
                  <MenuItem value={undefined} key={""}>
                    ALL
                  </MenuItem>
                  {_arrayTransferStatus.map((e) => {
                    return (
                      <MenuItem value={e.name} key={e.id}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </TextFieldInput>
              </Grid>
            </Grid>
            <br />
            <ReferenceManyField
              label=" "
              pagination={<Pagination />}
              perPage={20}
              filter={{ agency_id: props.id, status: status }}
              reference="warehouseagenciestransfer"
              target="agency_id"
            >
              <ListTransferDataGrid {...props} />
            </ReferenceManyField>
          </Tab>
          <Tab label="Employeee" path="employee">
            <ReferenceManyField
              label=" "
              pagination={<Pagination />}
              perPage={20}
              filter={{ agency_id: props.id }}
              reference="employeeagencies"
              target="agency_id"
            >
              <UserList {...props} />
            </ReferenceManyField>
          </Tab>
          <Tab label="Customer" path="customer">
            <ReferenceManyField
              label=" "
              pagination={<Pagination />}
              perPage={20}
              filter={{ agency_id: props.id }}
              reference="customers"
              target="agency_id"
            >
              <UserList {...props} />
            </ReferenceManyField>
          </Tab>
          <Tab label="Config" path="config">
            <Grid container spacing={3}>
              <Grid item sm={4}>
                <ButtonComponent {...props} />
              </Grid>
            </Grid>
            <br />
            <ReferenceManyField
              label=" "
              pagination={<Pagination />}
              perPage={20}
              filter={{ agency_id: props.id }}
              reference="configagencies"
              target="agency_id"
            >
              <ConfigComponent {...props} onRedirect={redirectConfig} />
            </ReferenceManyField>
          </Tab>
          <Tab label="Orders" path="orders">
            <ReferenceManyField
              label=" "
              pagination={<Pagination />}
              perPage={20}
              filter={{ agency_id: props.id }}
              reference="orders"
              target="agency_id"
            >
              <ListOrderDataGrid {...props} />
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </SimpleShowLayout>
    </Show>
  );
};
export default ShowComponent;
