import React from "react";
import PropTypes from "prop-types";
import { TextField } from "react-admin";

import { _getLodash, _setLodash } from "../utils/helper";

const Field = ({ record, source, choices, ...rest }) => {
    let newRecord = JSON.parse(JSON.stringify(record));
    const value = _getLodash(newRecord, source);
    newRecord = _setLodash(
        newRecord,
        source,
        choices.find((e) => e.id === value)?.name
    );
    return <TextField {...rest} source={source} record={newRecord} />;
};

Field.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string,
    currency: PropTypes.string,
};
Field.defaultProps = {
    addLabel: true,
    record: {},
};

export default Field;
