import React from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, usePermissions, useTranslate, useRefresh } from 'react-admin';
import { withRouter } from 'react-router-dom';
import LabelIcon from '@material-ui/icons/Label';
import UserIcon from '@material-ui/icons/Group';
import { useMediaQuery, Badge } from '@material-ui/core';

import AgencyContext from "../../context/AgencyContext"
import SubMenu from './subMenu';
import { useOrderOnAssigningContext } from "../../context"
import { USER_GROUP_OWNER_AGENCY, USER_GROUP_ADMIN } from "../../utils/const"
import { _adminRule, _ownerRule } from '../../utils/rule'
import { _clientUserStorage } from "../../utils/storage";

import AlertCalling from "../alertCalling";

const Menu = ({ onMenuClick, logout }) => {
    const { permissions: auth } = usePermissions();
    const refresh = useRefresh()
    const { total, open: openAlter, handleClose } = useOrderOnAssigningContext();
    const translate = useTranslate()
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);

    const user = _clientUserStorage.get() || {};


    const recurseItem = (m = {}) => {
        return m.map((resource = {}) => {
            return (
                !resource.menu ? <MenuItemLink
                    key={`menu_${resource.key}`}
                    primaryText={resource.key === "ordersonassigning" && total > 0 ?
                        <Badge badgeContent={total} color="secondary"
                            style={{ marginTop: 8 }}
                        >
                            {translate(resource.name)}
                        </Badge>
                        : translate(resource.name)}
                    leftIcon={resource.icon ? <resource.icon /> : <LabelIcon />}
                    to={{
                        pathname: `/${resource.key}`,
                        state: {
                            ...resource,
                        }
                    }}
                    onClick={(e) => {
                        if (resource.key === "ordersonassigning") {
                            refresh();
                            handleClose(e);
                        }
                        if (typeof onMenuClick === "function") onMenuClick(e);
                    }}
                    sidebarIsOpen={open}
                /> :
                    <SubMenu {...resource} key={`menu_${resource.key}`} primary={translate(resource.name)} icon={resource.icon}>
                        {recurseItem(resource.menu)}
                    </SubMenu>
            )
        })
    }
    return (
        <AgencyContext.Provider value={user.agency_id}>
            {recurseItem(auth === USER_GROUP_ADMIN ? _adminRule : auth === USER_GROUP_OWNER_AGENCY ? _ownerRule : [])}
            <MenuItemLink
                key={"profiles"}
                primaryText={translate("menu.profile")}
                leftIcon={<UserIcon />}
                to={{
                    pathname: `/profiles`,
                }}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {isXSmall && logout}

            <AlertCalling open={openAlter} total={total} handleClose={handleClose} />
        </AgencyContext.Provider>
    );
}

export default withRouter(Menu);