import React from 'react';
import {Button, Menu, MenuItem} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { EditButton, DeleteWithConfirmButton, useMutation, useNotify, useRedirect, ShowButton } from 'react-admin';
import Popup from "../popup"
import {ButtonField} from '../../partials';

const ActionComponent = (props) => {
    const {record = {}, nameBtn = "..."} = props;
    const notify = useNotify();
    const redirect = useRedirect();
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    const [onClick, { loading }] = useMutation(
        {
            type: 'finish',
            resource: 'warehousecompaniesimport',
            payload: { id: record.id },
        },
        {
            // undoable: true,
            refresh: true,
            onSuccess: ({ data, status, message }) => {
                if(status) {
                    notify('Finish import success');
                } else {
                    notify(`Finish import error: ${message}`, 'warning')
                }
            },
            onFailure: (error) => notify(`Finish import error: ${error.message}`, 'warning'),
        }
    );
    const handleBack = () => {
        redirect(`${props.basePath}`)
    }
    let html = [];
    if(record.status === "" || record.status === "DRAFT" || record.status === "IMPORT_STATUS_DRAFT") {
        html = [...html, ...[
            <MenuItem onClick={handleClose}><EditButton {...props} label={"Edit"}/></MenuItem>,
            <MenuItem onClick={handleClose}>
                <DeleteWithConfirmButton  {...props} label="Delete"/>
            </MenuItem>
        ]]
    }
    html.push(<MenuItem onClick={handleClose}><Popup btnOk={<ButtonField onClick={onClick} loading={loading} color="secondary" name={"Finish"}/>} btnName="Finish" title="Finish" content="Are you sure?"/></MenuItem>)
    
    if(!props.isShow) {
        html.push(<MenuItem onClick={handleClose}><ShowButton {...props} label={"Show"}/></MenuItem>)
    }
    
    return (
        <React.Fragment>
            {props.isShow && <Button onClick={handleBack} color="primary" startIcon={<ArrowBackIcon />}>Back</Button>}
            {record.status === "" || record.status === "DONE" || record.status === "IMPORT_STATUS_DONE" ? (!props.isShow ? <ShowButton {...props} label={"Show"}/> : "")
            : html.length === 0 ? "" : <React.Fragment>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>{nameBtn}</Button>
                    <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    >
                    {html}
                </Menu> 
            </React.Fragment>}
        </React.Fragment>
    )
};


export default ActionComponent