
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Create, SimpleForm, TextInput, SelectInput, DateInput, NumberInput, ReferenceInput, PasswordInput } from 'react-admin';

// import CompleteAddress from '../completeAddress';

import { _arrayUserGroup, _arraySex } from '../../utils/enum';
import {USER_GROUP_ADMIN} from '../../utils/const';


const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
}));
const getValueGroup = (props) => {
    const {resource = ""} = props;
    switch(resource) {
        case "customers": {
            return 1
        }
        case "employeeagencies": {
            return 2
        }
        case "admins": {
            return 5
        }
        case "employees": {
            return 3
        }
        default: {
            return 0
        }
    }
}
const CreateComponent = (props) => {
    const classes = useStyles();
    return (
        <Create {...props}>
            <SimpleForm className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <TextInput label="Name" source="name" fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <TextInput label="Phone" source="phone_number" fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <TextInput label="Email" source="email" fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <PasswordInput label="Password" source="password" type="password" fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <DateInput label="DOB" source="birth_day" defaultValue={new Date()} fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <SelectInput label="Sex" source="sex"  choices={_arraySex} fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <SelectInput label="Group" source="group" choices={_arrayUserGroup} fullWidth defaultValue={getValueGroup(props)} disabled/>
                    </Grid>
                    <Grid item sm={4}>
                        <NumberInput label="Basic Salary" source="basic_salary" fullWidth />
                    </Grid>
                    {props.permissions === USER_GROUP_ADMIN ? 
                    <Grid item sm={4}>
                        <ReferenceInput label="Agency" source="agency_id" reference="agencies">
                            <SelectInput optionText="name" fullWidth />
                        </ReferenceInput>
                    </Grid> : ""}

                    <Grid item sm={12}>
                        <TextInput label="Address" source="address" fullWidth />
                    </Grid>
                    {/* <Grid item sm={12}>
                        <CompleteAddress label="Suggest Address" source="suggestaddress" name="address" effects={["address", "latitude", "longitude"]} isCurrent={true} fullWidth/>
                    </Grid> */}
                </Grid>
            </SimpleForm>
        </Create>
    )
};

export default CreateComponent;