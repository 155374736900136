
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core"

import { Show, SimpleShowLayout, TextField,ArrayField, Datagrid, NumberField} from 'react-admin';
import { EnumField, GridField, LinkField } from '../../partials';

const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
    map: {
        marginBottom: theme.spacing(3),
        minHeight: theme.spacing(10),
        width: "100%",
        height: theme.spacing(30),
        "& > div:first-child": {
            width: "100%",
            height: "100%"
        }
    },
    listRoot: {
        overflow: "auto"
    }
}));
const GridComponent = (props) => {
    return (
        <Grid container spacing={3}>
            <GridField {...props} item sm={6}>
                <TextField label="Name" source="profile.name" />
            </GridField>
            <GridField {...props} item sm={6}>
                <EnumField {...props}  label="Status" source="status" _enum={null} prefix="VIP_CUSTOMER_STATUS_" />
            </GridField>
            <GridField {...props} item sm={6}>
                <ArrayField {...props} source="commitments" label="Commitments">
                    <Datagrid>
                        <LinkField reference="categories" field="name" source="category_id" label="Category ID"/>
                        <NumberField source="value" label="Value"/>
                    </Datagrid>
                </ArrayField>
            </GridField>
            <GridField {...props} item sm={6}>
                <ArrayField {...props} source="promotions" label="Promotions">
                    <Datagrid>
                        <LinkField reference="categories" field="name" source="category_id" label="Category ID"/>
                        <NumberField source="value" label="Value"/>
                    </Datagrid>
                </ArrayField>
            </GridField>
        </Grid>
    );
};
const ShowComponent = (props) => {
    const classes = useStyles();
    return (
        <Show {...props} title={`Detail Vip Customer`}>
            <SimpleShowLayout className={classes.form}>
                <GridComponent />
            </SimpleShowLayout>
        </Show>
    )
};

export default ShowComponent;