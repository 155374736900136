import React, {useCallback, useState} from 'react';

import { List, Filter, SelectInput, DateInput } from 'react-admin';

import { _arrayTransferStatus } from "../../../utils/enum";

import DataGrid from "./list";
import {Pagination} from "../../../partials";


const FilterComponents = (props) => {
    const {filterValues = {}, setFilters} = props;
    const [selectedDate, setSelectedDate] = useState(filterValues.datetime);

    const handleChangeDate = useCallback((e) => {
        const {value} = e.target;
        if(value) {
            setSelectedDate(value)
            setFilters({
                ...filterValues,
                datetime: value,
            })
        }
    },[filterValues, setFilters])
    
    return (
        <Filter {...props}>
            <DateInput label="Time" type="month" source="datetime" value={selectedDate} onChange={handleChangeDate}/>
            <SelectInput source="status" label="Status" choices={_arrayTransferStatus} optionValue="name" />
        </Filter>
    );
};



const ListComponent = (props) => {
    return (
        <List {...props} pagination={<Pagination />}  perPage={20} bulkActionButtons={false} filters={<FilterComponents {...props} />} sort={{ field: 'created_at', order: 'DESC' }}>
            <DataGrid {...props }/>
        </List>
    );
}
export default ListComponent
