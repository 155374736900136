import React, {useState} from 'react';
import moment from 'moment';

import {TextField as TextFieldMaterial} from "@material-ui/core"
import { List, Filter, SelectInput } from 'react-admin';


import { _arrayTransferStatus } from "../../../utils/enum";
import {Pagination} from "../../../partials";
import DataGrid from "../../employeeSalary/list/list";

const FilterComponents = (props) => {
    const [selectedDate, setSelectedDate] = useState();
    const handleDateChange = (e) => {
        const value = e.target.value
        const month = moment(e).month() + 1;
        const year = moment(e).year()
        setSelectedDate(value)
        if(props.setMonth) {
            props.setMonth(month)
        }
        if(props.setYear) {
            props.setYear(year)
        }
    }
    return (
        <Filter {...props}>
            <TextFieldMaterial label="Time" type="month" fullWidth value={selectedDate} onChange={handleDateChange} InputLabelProps={{shrink: true}}/>
            <SelectInput source="status" label="Status" choices={_arrayTransferStatus} optionValue="name" />
        </Filter>
    );
};



const ListComponent = (props) => {
    const [month, setMonth] = useState();
    const [year, setYear] = useState();
    return (
        <List {...props} pagination={<Pagination />}  perPage={20} title=" " bulkActionButtons={false} resource="employeesalaries" filter={{...props.filter, month: month, year: year}} filters={<FilterComponents {...props} setMonth={setMonth} setYear={setYear} />} sort={{ field: 'created_at', order: 'DESC' }}>
            <DataGrid {...props } resource="employeesalaries"/>
        </List>
    );
}
export default ListComponent
