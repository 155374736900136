import { _endpointOrderComment } from "../../utils/provider";
import { _createRequest, _pick, _fetch } from "../../utils/helper";

const endpoint = async (props) => {
    const { params, type } = props;

    switch (type) {
        case "sendNotification": {
            if (!params.data || !params.data.order_id) {
                throw new Error("Cant process with empty id");
            }
            if (!params.data || !params.data.message) {
                throw new Error("Cant process with empty message");
            }
            return _fetch(
                _createRequest(_endpointOrderComment.sendNotification, true, {
                    ..._pick(params.data, ["message", "order_id"]),
                })
            );
        }
        case "all": {
            return _fetch(
                _createRequest(_endpointOrderComment.all, true, {
                    order_id: params.data.order_id,
                })
            ).then((res) => {
                res.data = res.data.map((e = {}) => {
                    e.buy_shell = false;
                    (e.items || []).forEach((i) => {
                        if (i.buy_shell) {
                            e.buy_shell = true;
                            return;
                        }
                    });
                    return e;
                });
                return res;
            });
        }
        default: {
            //list
        }
    }
};

export default endpoint;
