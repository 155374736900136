import React, { Fragment } from 'react';
import { makeStyles } from "@material-ui/core/styles";

import LockIcon from '@material-ui/icons/Lock';
import { Button, Menu, MenuItem } from "@material-ui/core"
import { List, Datagrid, TextField, EditButton, ImageField, TextInput, Filter, NumberField, SelectInput, ShowButton } from 'react-admin';

import DialogComponent from "./resetPassword";
import { EnumField, PriceField, LatlngField, DateField, Pagination } from '../../partials'
import { _choiceUserStatus, _enumUserStatus, _enumSex, _arraySex } from "../../utils/enum";


const useStyles = makeStyles((theme) => ({
    rootContent: {

    },
    gridFilter: {
        display: "flex",
        alignItems: "center",
    },
    autoSuggest: {
        marginRight: theme.spacing(2),
        minWidth: 200,
    },
    rootList: {
        minHeight: 400,
    },
}));

const FilterComponents = (props) => {
    return (
        <Filter {...props}>
            <TextInput
                label="ID"
                source="id"

            />
            <TextInput source="name" label="Name" />
            <TextInput source="phone_number" label="Phone" />
            <SelectInput source="status" label="Status" choices={_choiceUserStatus} optionValue="name" />
            <SelectInput source="sex" label="Sex" choices={_arraySex} optionValue="name" />
        </Filter>
    );
};



const ButtonComponent = (props) => {
    const { record = {}, basePath, resource, id } = props;
    const commonProps = {
        basePath,
        resource,
        record,
        id
    }
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleReset = () => {
        if (props.handleReset) props.handleReset(record)
    };
    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>...</Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}><EditButton {...commonProps} label="Edit" /></MenuItem>
                <MenuItem onClick={handleClose}><ShowButton {...commonProps} label="Show" /></MenuItem>
                <MenuItem onClick={handleClose}><Button color="primary" onClick={handleReset} startIcon={<LockIcon />}>Reset Password</Button></MenuItem>
            </Menu>
        </div>
    )
};


const DatagridComponent = (props) => {
    const classes = useStyles();
    const [isReset, setIsReset] = React.useState()
    const [record, setRecord] = React.useState({})
    const handleClose = () => {
        setIsReset(false);
    };
    const handleReset = (record) => {
        setIsReset(true)
        setRecord(record)
    }

    return (
        <Fragment>
            <Datagrid {...props} selectedIds={[]}>
                <TextField source="id" label="ID" />
                <TextField source="name" label="Name" />
                <TextField source="email" label="Email" />
                <ImageField source="avatar" label="Avatar" />
                <TextField source="phone_number" label="Phone" />
                <EnumField source="sex" label="Sex" _enum={_enumSex} prefix="SEX_" />
                <DateField source="birth_day" label="DOB" />
                <PriceField source="basic_salary" label="Basic Salary" />
                <NumberField source="point" label="Point" />
                <TextField source="address" label="Address" />
                <LatlngField label="Latlng" />
                <EnumField source="status" label="Status" _enum={_enumUserStatus} prefix="USER_STATUS_" />
                <DateField source="created_at" label="Created" />
                <ButtonComponent {...props} handleReset={handleReset} />
            </Datagrid>
            {isReset &&
                <DialogComponent
                    {...props}
                    classesContent={{
                        root: classes.rootContent,
                    }}
                    record={record}
                    user_id={record.id}
                    anchor="right"
                    handleClose={handleClose}
                    title="Reset Password"
                    maxWidth="md"
                    open
                    fullWidth />}
        </Fragment>
    )
};

const ListComponent = (props) => {
    return (
        <List {...props} pagination={<Pagination />} perPage={20} bulkActionButtons={false} filters={<FilterComponents />} sort={{ field: 'created_at', order: 'DESC' }}>
            <DatagridComponent {...props} />
        </List>
    )
};
export default ListComponent

export { DatagridComponent }