import React from 'react';
import { TextField, ReferenceField } from 'react-admin';
import PropTypes from "prop-types";

const LinkField =  (props = {}) => {
    const {field = ""} = props;
    return (
        <ReferenceField {...props} source={props.source} label={props.label} reference={props.reference}>
            <TextField {...props} source={field} />
        </ReferenceField>
    );
};
LinkField.propTypes = {
    source: PropTypes.string,
    field: PropTypes.string,
    reference: PropTypes.string,
}
LinkField.defaultProps = {
    addLabel: true,
    record: {},
}
export default LinkField;
