import React, { Fragment } from "react";

import {
    List,
    Datagrid,
    TextField,
    ImageField,
    NumberField,
    TextInput,
    Filter,
    NumberInput,
    ShowButton,
} from "react-admin";

import {
    EnumField,
    PriceField,
    LatlngField,
    DateField,
    Pagination,
} from "../../partials";
import { _enumUserStatus, _enumSex } from "../../utils/enum";

const DatagridComponent = (props) => {
    return (
        <Fragment>
            <Datagrid {...props} selectedIds={[]}>
                <TextField source="customer.id" label="ID" />
                <TextField source="customer.name" label="Name" />
                <TextField source="customer.email" label="Email" />
                <ImageField source="customer.avatar" label="Avatar" />
                <TextField source="customer.phone_number" label="Phone" />
                <EnumField
                    source="customer.sex"
                    label="Sex"
                    _enum={_enumSex}
                    prefix="SEX_"
                />
                <DateField source="customer.birth_day" label="DOB" />
                <PriceField
                    source="customer.basic_salary"
                    label="Basic Salary"
                />
                <NumberField source="customer.point" label="Point" />
                <TextField source="customer.address" label="Address" />
                <LatlngField
                    label="Latlng"
                    latSource="customer.latitude"
                    lngSource={"customer.longitude"}
                />
                <EnumField
                    source="customer.status"
                    label="Status"
                    _enum={_enumUserStatus}
                    prefix="USER_STATUS_"
                />
                <TextField source="employee.name" label="Employee Name" />
                <DateField source="customer.created_at" label="Created" />
          <ShowButton {...props} label="Show" />
            </Datagrid>
        </Fragment>
    );
};


const FilterComponents = (props) => {
    return (
        <Filter {...props}>
            <TextInput source="customerPhone" label="Customer Phone" />
            <TextInput source="employeeName" label="Employee Name" />
            <NumberInput source="month" label="Month" />
            <NumberInput source="year" label="Year" />
        </Filter>
    );
};

const ListComponent = (props) => {
    return (
        <List
            {...props}
            filters={<FilterComponents />}
            pagination={<Pagination />}
            perPage={20}
            bulkActionButtons={false}
            filterDefaultValues={{}}
            sort={{ field: "created_at", order: "DESC" }}
        >
            <DatagridComponent {...props} />
        </List>
    );
};
export default ListComponent;

export { DatagridComponent };
