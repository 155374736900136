import React from 'react';
import PropTypes from "prop-types";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import {Button} from "react-admin"
const useStyles = makeStyles(theme => ({
    root: {
        cursor: "pointer"
    },
}));
const ButtonField =  (props) => {
    const classes = useStyles();
    const { bIcon = ""} = props;
    const onClick = (e) => {
        if(props.onClick) props.onClick(e, props.record)
    }
    return (
        <Button
            {...props} 
            className={classes.root} 
            onClick={onClick} 
            label={props.name}
        >
            {bIcon ? bIcon : <ArrowForwardIcon />}
        </Button>
    );
};
ButtonField.propTypes = {
    bIcon: PropTypes.any,
    onClick: PropTypes.func,
}
ButtonField.defaultProps = {
    color: "primary",
}
export default ButtonField;
