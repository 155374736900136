
import React from 'react';

import { List, Datagrid, TextField, EditButton } from 'react-admin';

import {DateField, Pagination} from '../../partials';
import {USER_GROUP_ADMIN} from '../../utils/const';

const ButtonEdit = ({onRedirect, ...props}) => {
    if(props.permissions !== USER_GROUP_ADMIN && props.record && props.record.config_group === "app_version") return ""
    return (
        <EditButton {...{
            ...props,
            ...(onRedirect && typeof onRedirect === "function" ? {onClick: (e) => onRedirect(e, props.record)}: {})
        }}/>
    )
}
const CoefficientComponent = (props) => {
    const {record = {}} = props;
    if(record.config_group !== "point") return ""
    return (
        <TextField record={record} source="coefficient" label="Coefficient"/>
    );
}
const DatagridComponent = ({onRedirect, ...props}) => {
    return (
        <Datagrid {...props}>
            <TextField source="config_group" label="Group"/>
            <TextField source="config_key" label="Key"/>
            <CoefficientComponent source="coefficient" label="Coefficient"/>
            <TextField source="config_value" label="Value"/>
            <TextField source="description" label="Description"/>
            <DateField source="created_at" label="Created"/>
            <ButtonEdit onRedirect={onRedirect} />
        </Datagrid>
    )
};


const ListComponent = ({onRedirect, ...props}) => {
    return (
        <List {...props} pagination={<Pagination />}  perPage={20} bulkActionButtons={false} sort={{ field: 'created_at', order: 'DESC' }}>
            <DatagridComponent onRedirect={onRedirect} {...props} />
        </List>
    )
};

export default ListComponent
export {DatagridComponent}