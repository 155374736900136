import React from 'react';
import { List, Datagrid, TextField, useNotify, useRefresh, useMutation, EditButton, DeleteWithConfirmButton, TextInput, Filter, Button as RaButton, usePermissions } from 'react-admin';
import { Button, Menu, MenuItem } from "@material-ui/core"
import CheckIcon from '@material-ui/icons/Check';

import { _enumNotiStatus } from '../../utils/enum';

import { USER_GROUP_ADMIN } from '../../utils/const';
import { Pagination, EnumField, DateField, LinkField } from "../../partials"

const FilterComponents = (props) => {
    return (
        <Filter {...props}>
            <TextInput source="title" label="Title" />
        </Filter>
    );
};


const ApproveButton = ({ record, resource, resend }) => {
    const refresh = useRefresh();
    const notify = useNotify();

    let title = resend ? 'Resend' : 'Approve';

    const [approve, { loading }] = useMutation({
        type: 'approve',
        resource: resource,
        payload: { id: record.id, resend: resend }
    },
        {
            onSuccess: ({ data }) => {
                notify(title +' success');
                refresh()
            },
            onFailure: (error) => notify(title +` error: ${error.message}`, 'warning'),
        });

    return <RaButton label={title} onClick={approve} disabled={loading}><CheckIcon /></RaButton>;
};

const ButtonComponent = (props) => {
    const { record = {} } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true"
                disabled={record.status === "NOTIFICATION_MASTER_STATUS_DELETED"}
                onClick={handleClick}>...</Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {record.status === "NOTIFICATION_MASTER_STATUS_PENDING" && props.permissions === USER_GROUP_ADMIN ? <MenuItem fullWidth onClick={handleClose}>
                    <ApproveButton resource={props.resource} record={record} resend={false} />
                </MenuItem> : ""}
                {record.status === "NOTIFICATION_MASTER_STATUS_SENT" && props.permissions === USER_GROUP_ADMIN ? <MenuItem fullWidth onClick={handleClose}>
                    <ApproveButton resource={props.resource} record={record} resend={true} />
                </MenuItem> : ""}
                {record.status === "NOTIFICATION_MASTER_STATUS_PENDING" && <MenuItem fullWidth onClick={handleClose}><EditButton {...props} /></MenuItem>}
                <MenuItem onClick={handleClose}>
                    <DeleteWithConfirmButton fullWidth {...props} />
                </MenuItem>
            </Menu>
        </div>
    )
};


const ListComponent = (props) => {
    const { permissions: auth } = usePermissions();

    return (
        <List {...props} pagination={<Pagination />} perPage={20} bulkActionButtons={false} filters={<FilterComponents />} sort={{ field: 'created_at', order: 'DESC' }} >
            <Datagrid>
                {auth === USER_GROUP_ADMIN ? <LinkField reference="agencies" link="show" field="name" source="agency.id" label="Agency"/> : ""}
                <TextField source="title" label="Title" />
                <TextField source="content" label="Content" />
                <EnumField source="status" label="Status" _enum={_enumNotiStatus} prefix="NOTIFICATION_MASTER_STATUS_" />
                <DateField source="sent_at" label="Sent At" />
                <ButtonComponent  {...props} />
            </Datagrid>
        </List>
    );
}

export default ListComponent
