
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Create, SimpleForm, TextInput, SelectInput, NumberInput } from 'react-admin';

import InputComponent from '../imageInput';
import {useGetAllCategories} from "../../hooks";

const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
}));
const CreateComponent = (props) => {
    const classes = useStyles();
    const { data = [] } = useGetAllCategories();

    return (
        <Create {...props}>
            <SimpleForm className={classes.form} redirect="list">
                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <TextInput label="ID" source="id" fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <SelectInput label="Category" source="category_id" choices={data || []} optionText="name" optionValue="id" fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <TextInput label="Name" source="name" fullWidth />
                    </Grid>
                    <Grid item sm={12}>
                        <TextInput label="Description" source="description" fullWidth rows={3} multiline/>
                    </Grid>
                    <Grid item sm={3} md={6}>
                        <TextInput source="unit" label="Unit" fullWidth />
                    </Grid>
                    <Grid item sm={3} md={6}>
                        <TextInput source="unit_weight" label="Unit Weight" fullWidth />
                    </Grid>
                    <Grid item sm={3} md={6}>
                        <SelectInput source="track_by" label="Track by" initialValue="unit" choices={[{id: "unit", name: "Unit"}, {id:"unit_weight", name: "Unit Weight"}]} fullWidth />
                    </Grid>
                    <Grid item sm={3} md={6}>
                        <TextInput source="supplier" label="Supplier" fullWidth />
                    </Grid>
                    <Grid item sm={6}>
                        <TextInput source="origin" label="Origin" fullWidth />
                    </Grid>
                    <Grid item sm={6}>
                        <TextInput source="expiry" label="Expiry" fullWidth />
                    </Grid>
                    <Grid item sm={6}>
                        <NumberInput source="level" label="Level" fullWidth />
                    </Grid>
                    <Grid item md={6}>
                        <NumberInput source="priority" label="Priority" fullWidth />
                    </Grid>
                    <Grid item sm={12}>
                        <InputComponent multiple source="images" nameBase="imagesBase" label="Images" />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};

export default CreateComponent;