import { useState, useEffect, useCallback } from 'react';

import { useDataProvider, usePermissions } from 'react-admin';
import {USER_GROUP_OWNER_AGENCY} from "../utils/const"

import soundFile from "../utils/sms-alert.mp3";
const audio = new Audio(soundFile);


function useOrderOnAssigning({handleOpen}) {
    const {permissions} = usePermissions();
    const dataProvider = useDataProvider();
    const [endAt, setEndAt] = useState(null);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const onClear = useCallback(() => {
        setEndAt(null)
        setTotal(0);
        audio.pause();
    }, [])
    useEffect(() => {
        var x;
        if(total > 0 && permissions === USER_GROUP_OWNER_AGENCY) {
            handleOpen()
            audio.play()
            x = setInterval(() => {
                audio.play()
            }, 5000)
        }
        return () => {
            clearInterval(x)
            audio.pause()
        }
    }, [handleOpen, permissions, total])
    useEffect(() => {
        if(permissions === USER_GROUP_OWNER_AGENCY) {
            async function fetchData() {
                setLoading(true);
                const result = await dataProvider
                    .getList("orderOnAssigning", {
                        endAt: endAt,
                        status: 'ORDER_STATUS_ON_ASSIGNING',
                    })
                    .then((res = {}) => {
                        const {timestamp: newTimestamp, data = []} = res;
                        setTotal(pre => Number(pre) + data.total);
                        setEndAt(newTimestamp)
                        return res;
                    })
                    .catch((err) => {
                        throw err;
                    });
                setLoading(false);
                return result;
            }
            // count order on assign
            const x = setInterval(() => {
                fetchData();
            }, 5*1000)

            return () => clearInterval(x);
        }
    }, [dataProvider, endAt, permissions])
    return {
        loading, 
        total,
        onClear,
    };
}

export default useOrderOnAssigning;
