import React from "react";
import { TextField } from "react-admin";
import PropTypes from "prop-types";
import { get } from "lodash";

const LatlngField = ({
    basePath,
    addLabel,
    latSource = "latitude",
    lngSource = "longitude",
    ...props
}) => {
    const onClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        window.open(
            `https://www.google.com/maps/search/?api=1&query=${get(
                props.record,
                latSource
            )},${get(props.record, lngSource)}`,
            "_blank"
        );
    };
    return (
        <div
            onClick={onClick}
            style={{
                cursor: "pointer",
                color: "blue",
            }}
            {...props}
        >
            Lat: <TextField {...props} source={latSource} />
            <br />
            Lng: <TextField {...props} source={lngSource} />
        </div>
    );
};
LatlngField.propTypes = {
    record: PropTypes.object,
    label: PropTypes.string,
};
LatlngField.defaultProps = {
    addLabel: true,
    record: {},
    latSource: "latitude",
    lngSource: "longitude",
};
export default LatlngField;
