import React from "react";

import {
    List,
    Datagrid,
    TextField,
    TextInput,
    Filter,
    useQuery,
    Loading,
    SelectInput,
} from "react-admin";

import {
    EnumField,
    LatlngField,
    UserField,
    DateField,
    Pagination,
    ChoiceField,
} from "../../partials";
import { _enumAgencyStatus } from "../../utils/enum";

const FilterComponents = ({ agencyTypes, ...props }) => {
    return (
        <Filter {...props}>
            <TextInput label="ID" source="id" />
            <TextInput source="name" label="Name" />
            <TextInput source="phone_number" label="Phone" />
            <SelectInput
                label="Agency Type"
                source="agency_type"
                choices={agencyTypes}
                fullWidth
                initialValue={agencyTypes[0] ? agencyTypes[0].id : ""}
            />
        </Filter>
    );
};

const DataGridAgencyList = ({ children, ...props }) => {
    const { data: agencyTypes = [], loading } = useQuery({
        type: "list",
        resource: "agency_types",
        payload: {
            filter: {},
            pagination: {},
            sort: {},
        },
    });

    if (loading) {
        return <Loading />;
    }

    return (
        <List
            resource={"agencies"}
            pagination={<Pagination />}
            perPage={20}
            filters={<FilterComponents agencyTypes={agencyTypes} />}
            sort={{ field: "created_at", order: "DESC" }}
            {...props}
        >
            <Datagrid>
                <UserField source="owner" label="Owner" />
                <TextField source="name" label="Name" />
                <TextField source="phone_number" label="Phone" />
                <EnumField
                    source="status"
                    label="Status"
                    _enum={_enumAgencyStatus}
                    prefix="AGENCY_STATUS_"
                />
                <ChoiceField
                    source="agency_type"
                    label="Agency Type"
                    choices={agencyTypes}
                />
                <LatlngField label="Latlng" />
                <TextField source="radius" label="Radius (km)" />
                <DateField source="created_at" label="Created" />
                {children}
            </Datagrid>
        </List>
    );
};

export default DataGridAgencyList;
