/* global google */
import React, {useEffect} from 'react';
import { compose, withProps } from "recompose"

import { withGoogleMap, withScriptjs, GoogleMap, Marker, Circle, InfoWindow } from "react-google-maps"

import "./index.css";

const mapStyles = {
    width: "100%",
    height: "100%",
};

const MapComponent = (props) => {
    let geocoder = new google.maps.Geocoder();
    const {format_address = {}, latitude, longitude, radius = 0, open = true, coords = [], isCurrent, draggable = false} = props;
    const lat = Number(latitude  || format_address.latitude || 0), lng = Number(longitude || format_address.longitude || 0);
    const [zoom, setZoom] = React.useState(props.zoom || 12);
    const position = { lat: lat, lng: lng }
    function handleZoomChanged() {
        setZoom(this.getZoom());
    }
    const handleDragEnd = (e) => {
        const { latLng } = e;
        getAddress({lat: latLng.lat(), lng: latLng.lng()})
    }
    const getAddress = (latLng) => {
        if (geocoder && props.handleChange) {
            geocoder.geocode( { 'location': latLng}, function(results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        const result = results[0];
                        props.handleChange(latLng.lat, latLng.lng, result.formatted_address)
                        return
            
                    } else {
                        console.log("No results found");
                    }
                } else {
                    console.log("Geocode was not successful for the following reason: " + status);
                }
            });
        } 
        
        props.handleChange(lat, lng, "")
    }
    
    useEffect(() => {
        if(isCurrent) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    const {coords = {}} = position
                    getAddress({lat: coords.latitude, lng: coords.longitude})
                });
            } else {
                console.log("Browser doesn't support Geolocation")
            }
        }
    });
    return (
        <div className={"map-container"}>
            <GoogleMap
                visible={open}
                style={mapStyles}
                defaultZoom={zoom}
                defaultCenter={position}
                onZoomChanged={handleZoomChanged}
                centerAroundCurrentLocation={coords.length === 0 ? true : false}
            >
                {coords.length === 0? 
                    <React.Fragment>
                        <Marker 
                            draggable={false}
                            // draggable={draggable}
                            onDragEnd={handleDragEnd}
                            position={position} />
                        {!draggable && <Circle 
                            defaultCenter={position} 
                            radius={Number(radius) * 1000}
                            options={{
                                strokeColor: '#FF0000',
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: '#FF0000',
                                fillOpacity: 0.3,
                            }}
                        />}
                    </React.Fragment> 
                : (coords || []).map((e, i) => {
                    const position = {lat: Number(e.lat), lng: Number(e.lng)}
                    return (
                        <React.Fragment key={`market_box_${i}_${e.lat}_${e.lng}`}>
                            <Marker 
                                key={`market_${i}_${e.lat}_${e.lng}`}
                                draggable={false}
                                position={position}
                                onMouseOver={()=>{ props.showInfo(i)} }>
                                {e.text && props.showInfoIndex === i ? <InfoWindow>
                                    <div>{e.text}</div>    
                                </InfoWindow> : ""} 
                            </Marker>
                            <Circle 
                                key={`circle_${i}_${e.radius}_${e.lat}_${e.lng}`}
                                defaultCenter={position} 
                                radius={Number(e.radius) * 1000}
                                options={{
                                    strokeColor: '#FF0000',
                                    strokeOpacity: 0.8,
                                    strokeWeight: 2,
                                    fillColor: '#FF0000',
                                    fillOpacity: 0.3,
                                }}
                            />
                        </React.Fragment>
                    )
                })}
            </GoogleMap>
        </div>
    );
};

export default compose(
    withProps({
        googleMapURL:
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyA4ubJR6AfEW31dIRKCu0huFAozsLJuCts&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%`, minHeight: 240}} />,
        mapElement: <div style={{ height: `100%` }} />
    }), withScriptjs, withGoogleMap)(MapComponent);