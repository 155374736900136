import { _endpointPartner } from "../../utils/provider";
import { _createRequest, _getFilter, _pick, _fetch } from "../../utils/helper";
import uploadsEndpoint from "./upload";
import { _enumUploadType } from "../../utils/enum";
const partnerEndpoint = async (props) => {
  const { type, params = {} } = JSON.parse(JSON.stringify(props));

  switch (type) {
    case "one": {
      return _fetch(
        _createRequest(_endpointPartner.list, true, {
          filter: `${_getFilter(
            {
              filter: { id: params.id },
              pagination: { page: 1, perPage: 1 },
            },
            {
              id: "eq",
            }
          )}`,
        })
      ).then((res) => {
        const data = (res.data || {})?.[0] || {};
        return {
          data: {
            ...data,
            agency_ids: (data.agencies || []).map((e) => e.id),
            total_agency: data.agencies?.length || 0,
          },
        };
      });
    }
    case "create":
    case "update": {
      if (params.data && params.data.imagesBase) {
        const { imagesBase } = params.data;
        await uploadsEndpoint({
          type: "upload",
          params: {
            data: {
              uploadType: _enumUploadType["UPLOAD_TYPE_NONE"],
              ...imagesBase._based64,
            },
          },
        }).then((res) => {
          const url = res.data;
          params.data = {
            ...params.data,
            app_icon: url,
          };
        });
      }
      let args = [
        "app_name",
        "description",
        "app_icon",
        "is_show_all",
        "chplay_url",
        "appstore_url",
        "agency_ids",
        "in_system",
        "gift_id",
        "categories",
        "agency_type_id",
      ];
      if (type === "update") {
        args.push("id");
      }
      return _fetch(
        _createRequest(_endpointPartner[type], true, {
          ..._pick(params.data, args),
        })
      );
    }
    default: {
      //list
      return _fetch(
        _createRequest(_endpointPartner.list, true, {
          filter: `${_getFilter(params, {})}`,
        })
      );
    }
  }
};

export default partnerEndpoint;
