import englishMessages from "ra-language-english";
const en = {
    ...englishMessages,
    ...{
        resources: {
            users: {
                name: "Users",
                fields: {
                    first_name: "First name",
                    last_name: "Last name",
                    dob: "DOB",
                },
            },
        },
        status: {
            active: "Active",
            inactive: "InActive",
            close: "Close",
            done: "Done",
            draft: "Draft",
            none: "None",
            delivered: "Delivered",
            canceled: "Canceled",
            assigned: "Assigned",
            on_delivering: "On Delivering",
            on_assigning: "On Assigning",
            waiting_to_finish: "Waiting To Finish",
            pending: "Pending",
            requested: "Requested",
            accepted: "Accepted",
            waiting_for_approve_return: "Waiting For Approve Return",
            returned: "Returned",
            on_returning: "On returning",
        },
        all: "All",
        menu: {
            agency_type: "Agency Type",
            users: "Users",
            admin: "Admin",
            employee: "Employee",
            partner: "Partner",
            customer: "Customer",
            vip_customer: "Vip Customer",
            agency: "Agency",
            category: "Category",
            product: "Product",
            productmetadata: "Product Metadata",
            warehouse: "Warehouse",
            transfer_requests: "Transfer Requests",
            config: "Config",
            employee_salary: "Employee Salary",
            orders: "Orders",
            profile: "Profile",
            notification: "Notification",
            event: "Event",
        },
        sum: "Sum: %{sum}",
        user_group: {
            owner_agency: "Owner Agency",
            employee_agency: "Employee Agency",
            employee: "Employee",
            none: "None",
            admin: "Admin",
            customer: "Customer",
        },
        need_back_shell: "Need back shell: %{count}",
        gender: {
            none: "None",
            male: "Male",
            female: "Female",
        },
    },
    USER_STATUS_ACTIVE: "Active",
    USER_STATUS_BAN: "Ban",
};

export { en };
