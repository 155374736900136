import list from './list'
import detail from './detail'
import create from './create'

export default {
    list: list,
    show: detail,
    create: create,
    name: "orders",
    key: "p_orders",
}