import list from "./list";
import detail from "./detail";
import create from "./create";
import edit from "./edit";

export default {
    list: list,
    create: create,
    show: detail,
    edit: edit,
    name: "users",
    key: "p_users",
};
