import React, { useContext } from "react";
import {
    useTranslate,
    useNotify,
    useDataProvider,
    ExporterContext,
    fetchRelatedRecords,
} from "react-admin";

import {
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import ExportIcon from "@material-ui/icons/GetApp";

export default function ExportWarehouse(props) {
    const { resource, total, filter: raFilter = {}, sort, disabled } = props;
    const [open, setOpen] = React.useState(false);
    const [limit, setLimit] = React.useState(10);
    const t = useTranslate();
    var dataProvider = useDataProvider();
    const exporter = useContext(ExporterContext);
    const notify = useNotify();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLimit = React.useCallback((e) => {
        setLimit(e.target.value);
    }, []);

    const handleExporter = React.useCallback(
        (event) => {
            dataProvider
                .getList(resource, {
                    sort: sort,
                    filter: {
                        ...raFilter,
                        ...(limit > 0 && { quantity: Number(limit) }),
                    },
                    pagination: { page: 1, perPage: 2000 },
                })
                .then(function (_a) {
                    var data = Array.isArray(_a.data)
                        ? _a.data.map((e) => {
                              const quantity = e.aw?.quantity || e.quantity;
                              const product_name = e.p?.name || e.name;
                              const price =
                                  e.aw?.sale_price || e.sale_price || 0;

                              return {
                                  product_id: e.id,
                                  quantity: limit - quantity,
                                  price,
                                  name: product_name,
                              };
                          })
                        : [];

                    handleClose();
                    return (
                        exporter &&
                        exporter(
                            data,
                            fetchRelatedRecords(dataProvider),
                            dataProvider,
                            resource
                        )
                    );
                })
                .catch(function (error) {
                    console.error(error);
                    notify("ra.notification.http_error", "warning");
                    handleClose();
                });
        },
        [dataProvider, exporter, limit, notify, raFilter, resource, sort]
    );

    return (
        <div>
            <Button
                color="primary"
                onClick={handleClickOpen}
                startIcon={<ExportIcon />}
            >
                {t("ra.action.export")}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">Export</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vui lòng nhập số lượng giới hạn
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Giới hạn"
                        type="number"
                        value={limit}
                        fullWidth
                        onChange={handleLimit}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Hủy
                    </Button>
                    <Button
                        onClick={handleExporter}
                        color="primary"
                        disabled={disabled || total === 0}
                        autoFocus
                        startIcon={<ExportIcon />}
                    >
                        Xuất
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
