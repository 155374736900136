
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Create, SimpleForm, TextInput, NumberInput, SelectInput, required } from 'react-admin';

import InputComponent from '../imageInput';

import {useGetAllProductMetadatas} from "../../hooks";

const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
}));
const CreateComponent = (props) => {
    const classes = useStyles();
    const { data: productMetadatas = [] } = useGetAllProductMetadatas();

    return (
        <Create {...props}>
            <SimpleForm className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <TextInput label="ID" source="id" fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <SelectInput choices={productMetadatas} validate={[required()]} label="Product Metadata ID" source="product_metadata_id" optionText="name" optionValue="id" fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <TextInput label="Name" source="name" fullWidth />
                    </Grid>
                    <Grid item sm={12}>
                        <TextInput rows={3} multiline label="Description" source="description" fullWidth />
                    </Grid>
                    <Grid item md={3} sm={6}>
                        <NumberInput source="unit_price" label="Unit Price" fullWidth />
                    </Grid>
                    <Grid item md={3} sm={6}>
                        <NumberInput source="sale_price" label="Sale Price" fullWidth />
                    </Grid>
                    <Grid item md={3} sm={6}>
                        <NumberInput source="shell_price" label="Shell Price" fullWidth />
                    </Grid>
                    <Grid item md={3} sm={6}>
                        <NumberInput source="agency_price" label="Agency Price" fullWidth />
                    </Grid>
                    <Grid item md={4} sm={6}>
                        <NumberInput source="unit_point" label="Unit Point" fullWidth />
                    </Grid>
                    <Grid item md={4} sm={6}>
                        <NumberInput source="net_weight" label="Net Weight" fullWidth />
                    </Grid>
                    <Grid item md={4} sm={6}>
                        <NumberInput source="priority" label="Priority" fullWidth />
                    </Grid>
                    <Grid item sm={12}>
                        <InputComponent multiple source="imagesNew" nameBase="imagesBase" label="Images" />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};

export default CreateComponent;