import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ImageField,
  Toolbar,
  SaveButton,
  useRecordSelection,
  ImageInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  useQuery,
  SelectInput,
} from "react-admin";
import { useFormState, useField } from "react-final-form";
import { _fileToBase64 } from "../../utils/base64";
import DataGridAgencyList from "../agencies/agencyDataGrid";

const CustomImageField = () => {
  const record = useFormState().values;

  const {
    input: { onChange },
  } = useField("imagesBase");

  const handleChange = async (files) => {
    const based64 = await _fileToBase64(files);
    files._based64 = based64;
    onChange(files);
  };

  return (
    <>
      <ImageInput
        record={record}
        source="imagesBase"
        accept="image/*"
        onChange={handleChange}
        placeholder={<p>Drop your file here</p>}
      >
        <ImageField source="src" title="Image" />
      </ImageInput>
      {!record.imagesBase && (
        <ImageField record={record} source="app_icon" label="Pre Icon" />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    "& > div > div > div:first-child": {
      width: "100%",
    },
  },
}));

const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
  </Toolbar>
);
const useSetSelectedRecords = ({ record }) => {
  const {
    input: { onChange, value },
    meta: { dirty },
  } = useField("agency_ids", {
    defaultValue: record.agency_ids,
  });

  const [selectedIds, { select }] = useRecordSelection("agencies");
  //   console.log("selectedIds", selectedIds, record.agency_ids);
  useEffect(() => {
    onChange(selectedIds);
  }, [onChange, selectedIds]);

  useEffect(() => {
    if (!dirty) {
      select(value);
    }
  }, [value, select, dirty]);
};

const BulkActionButtons = () => {
  return null;
};

const useAgencyFilterStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiPaper-root": {
      marginTop: 0,
    },
  },
}));
const AgencyList = (props) => {
  const classes = useAgencyFilterStyles();
  useSetSelectedRecords(props);

  return (
    <DataGridAgencyList
      {...props}
      exporter={false}
      bulkActionButtons={<BulkActionButtons />}
      className={classes.root}
      resource={"agencies"}
      title=" "
    />
  );
};
export const PartnerForm = (props) => {
  const formState = useFormState().values;
  const { data = {}, loading } = useQuery({
    type: "getAgencyTypes",
    resource: "agencies",
    payload: {
      filter: {},
      pagination: {},
      sort: {},
    },
  });
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item sm={4}>
        <TextInput label="Name" source="app_name" fullWidth />
      </Grid>
      <Grid item sm={12}>
        <TextInput label="Description" source="description" fullWidth multiline />
      </Grid>
      <Grid item sm={4}>
        <BooleanInput label="Show All" source="is_show_all" fullWidth />
      </Grid>
      <Grid item sm={4}>
        <TextInput label="Gift ID" source="gift_id" fullWidth />
      </Grid>
      <Grid item sm={12}>
        <CustomImageField />
      </Grid>
      <Grid item sm={4}>
        <SelectInput
          label="Agency Type"
          source="agency_type_id"
          choices={data}
          fullWidth
          initialValue={data[0] ? data[0].id : ""}
        />
      </Grid>
      <Grid item sm={4}>
        <TextInput source="appstore_url" label="Appstore" fullWidth />
      </Grid>
      <Grid item sm={4}>
        <TextInput label="CHPlay" source="chplay_url" fullWidth />
      </Grid>

      <Grid item sm={12}>
        <BooleanInput label="In system" source="in_system" fullWidth />
      </Grid>
      <Grid item sm={12}>
        <ArrayInput
          source="categories"
          label="Categories"
          disabled={formState.in_system}
        >
          <SimpleFormIterator>
            <TextInput label="ID" source="id" />
            <TextInput label="Name" source="name" />
            <ArrayInput
              source="levels"
              label="Levels"
              disabled={formState.in_system}
            >
              <SimpleFormIterator>
                <NumberInput label="Level" source="level" />
                <TextInput label="Name" source="name" />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
      <Grid item sm={12}>
        <AgencyList {...props} />
      </Grid>
    </Grid>
  );
};
const CreateComponent = (props) => {
  const classes = useStyles();
  return (
    <Edit
      {...props}
      toolbar={<CustomToolbar />}
      undoable={false}
      title={`Update ${props.resource}`}
    >
      <SimpleForm className={classes.form} redirect="show">
        <PartnerForm />
      </SimpleForm>
    </Edit>
  );
};

export default CreateComponent;
