
import React from 'react';

import { TopToolbar, SimpleShowLayout, Show} from 'react-admin';

import DataComponent from "./detail"
import ActionAgency from "../list/actions";

const ActionComponent = (props) => {
    const requireProps = {
        ...props,
        permissions: props.permission,
        record: props.data,
        basePath: props.basePath,
        resource: props.resource,
        input: undefined,
        isShow: true,
        nameBtn: "Action"
    }
    return (
        <TopToolbar>
            <ActionAgency {...requireProps} />
        </TopToolbar>
    );
}

const DetailComponent = (props) => {
    return (
        <Show {...props} actions={<ActionComponent {...props} />} title="Detail Order">
            <SimpleShowLayout {...props}>
                <DataComponent {...props}/>
            </SimpleShowLayout>
        </Show>
    )
};

export default DetailComponent;