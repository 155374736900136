import { useQuery } from 'react-admin';

function useGetAllProductMetadatas() {
    const {data = [], loading, loaded, error} = useQuery(
        {
            type: "all",
            resource: "productmetadatas",
            payload: {
                pagination: {
                    perPage: 100,
                    page: 1,
                },
                filter: {
                    deleted_at: true,
                },
                sort: [{
                    field: "created_at",
                    order: "DESC",
                },{
                    field: "id",
                    order: "ASC",
                }],
            },
        },
        []
    );
    
    return {
        data, 
        loading, 
        loaded, 
        error,
    }
}

export default useGetAllProductMetadatas;
