
import React from 'react';
import { Grid, Avatar} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SelectInput, SimpleForm, TextInput, Edit, usePermissions, SaveButton, Toolbar } from "react-admin";
import {_choiseSex} from "../../utils/enum";

const useStyles = makeStyles(theme => ({
    large: {
        backgroundColor: theme.palette.secondary.main,
        height: theme.spacing(20),
        width: theme.spacing(20),
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(10),
            width: theme.spacing(10),
        },
        [theme.breakpoints.up('lg')]: {
            height: theme.spacing(30),
            width: theme.spacing(30),
            
        },
    },
    actionBtn: {
        width: "100%",
        textAlign: "right"
    },
    grid: {
        width: "unset",
    }
}));
const EditActiom  = (props) => {
    return (
        <Toolbar {...props}>
            <SaveButton />
        </Toolbar>
    );
}
const ShowComponent = (props) => {
    const classes = useStyles();
    const permissions = usePermissions();
    return (
        <Edit {...props} undoable={false} >
            <SimpleForm {...props} toolbar={<EditActiom />} redirect={"show"}>
                <Grid container spacing={3} className={classes.grid}>
                    <Grid item md={12}>
                        <Avatar alt="Avatar" className={classes.large}>{}</Avatar>
                    </Grid>
                    <Grid item md={6}>
                        <TextInput label="Name" source="name" fullWidth/>
                    </Grid>
                    <Grid item md={6}>
                        <TextInput label="Phone" source="phone_number" fullWidth/>
                    </Grid>
                    <Grid item md={6}>
                        <TextInput label="Email" source="email" fullWidth/>
                    </Grid>
                    <Grid item md={6}>
                        <TextInput label="Birthday" source="birth_day" fullWidth/>
                    </Grid>
                    <Grid item md={4}>
                        <SelectInput choices={_choiseSex} optionValue={"name"} label="Sex" source="sex" fullWidth/>
                    </Grid>
                    <Grid item md={4}>
                        <TextInput disabled label="Group" source="group" fullWidth/>
                    </Grid>
                    <Grid item md={4}>
                        <TextInput disabled label="Status" source="status" fullWidth/>
                    </Grid>
                    <Grid item md={12}>
                        <TextInput label="Address" disabled={permissions !== "USER_GROUP_ADMIN"} source="address" fullWidth/>
                    </Grid>
                    <Grid item md={6}>
                        <TextInput label="Latitude" disabled={permissions !== "USER_GROUP_ADMIN"} source="latitude" fullWidth/>
                    </Grid>
                    <Grid item md={6}>
                        <TextInput label="Longitude" disabled={permissions !== "USER_GROUP_ADMIN"} source="longitude" fullWidth/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};
export default ShowComponent;