import {_endpointWarehouseAgency} from '../../utils/provider';
import {_createRequest, _pick, _getFilter, _fetch} from '../../utils/helper'
import {_clientUserStorage} from '../../utils/storage'
import {_actionTransferTransition, _enumTransferTransition} from '../../utils/enum'
const endpoint = async(props) => {
    const {type, params = {}} = props;
    const  agencyId = _clientUserStorage.get().agency_id;
    switch(type) {
        case "transition": {
            const {name, src, dst} = params;
            const actions = _actionTransferTransition.find(e => e.Name === name && e.Dst === dst && e.Src.find(i => i === src));
            if(!actions || !_enumTransferTransition[name]) {
                throw new Error(`${name} is invalid`)
            }
            return _fetch(_createRequest(_endpointWarehouseAgency.transfer.transition, true, {
                transfer_id: params.id,
                transition: _enumTransferTransition[name],
            }))
        }
        case "create": {
            if (params.data && params.data.transfer_at) {
                params.data.transfer_at = new Date(params.data.transfer_at)
            }
            if(params.data && params.data.importData) {
                params.data.items = params.data.importData
            }
            return _fetch(_createRequest(_endpointWarehouseAgency.transfer.create, true, {
                ..._pick(params.data, ["transfer_at", "note", "items"]),
                agency_id: agencyId
            }))
        }
        case "delete": {
            return _fetch(_createRequest(_endpointWarehouseAgency.transfer.delete, true, {
                transfer_id: params.id,
            }))
        }
        case "update": {
            if(params && params.data && params.data.items) {
                params.data.items = params.data.items.map(e => {
                    return {
                        ...e,
                        product_id: e.id
                    }
                })
            }
            params.transfer_product = params.transfer_product || {}

            const {data = {}} = params;
            if (params.data && params.data.transfer_at) {
                params.data.transfer_at = new Date(params.data.transfer_at)
            }
            return await _fetch(_createRequest(_endpointWarehouseAgency.transfer.update, true, {
                ..._pick(params.data, ["transfer_id", "transfer_at", "note", "items"]),
                transfer_id: params.id,
                transfer_at: data.transfer_at,
                note: data.transfer_product ? data.transfer_product.note : "",
                items: data.items.map(e => {
                    return {
                        product_id: e.product_id,
                        quantity: e.quantity,
                    }
                }),
            }))
        }
        case "one": {
            return _fetch(_createRequest(_endpointWarehouseAgency.transfer.one, true, {
                transfer_id: params.id
            }))
            .then(res => {
                res.data = res.data || {}
                
                res.data.id = res.rawData && res.rawData.transfer_product ? res.rawData.transfer_product.id : params.id
                if(res.rawData.transfer_product) {
                    res.data = {
                        ...res.rawData.transfer_product,
                        ...res.data,
                    }
                }
                return res;
            })
        }
        case "many": {
            const {target = "agency_id"} = params;
            params.filter = params.filter || {}
            if(params.ids) {
                params.filter = {
                    ...params.filter,
                    [target]: params.ids,
                }
            }
            params.pagination = {
                ...params.pagination,
                perPage: (params.ids || []).length || 10,
            }   
            
            return _fetch(_createRequest(_endpointWarehouseAgency.transfer.list, true, {
                filter: _getFilter(params, {agency_id: Array.isArray(params.filter.agency_id)  ? "in" : "eq", status: "eq"})
            }))
        }
        case "manyReference": {
            const {target = "agency_id"} = params;
            params.filter = params.filter || {}
            if(params.ids) {
                params.filter = {
                    ...params.filter,
                    [target]: params.ids,
                }
            }
            params.pagination = {
                ...params.pagination,
                perPage: (params.ids || []).length || 10,
            }   
            
            return _fetch(_createRequest(_endpointWarehouseAgency.transfer.list, true, {
                filter: _getFilter(params, {agency_id: Array.isArray(params.filter.agency_id)  ? "in" : "eq", status: "eq"})
            }))
        }
        default: { //list
            return _fetch(_createRequest(_endpointWarehouseAgency.transfer.list, true, {
                filter: _getFilter(params, {agency_id: "eq", status: "eq"})
            }))
        }
    }
}

export default endpoint
