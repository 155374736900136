
import React, {useCallback} from 'react';
import clsx from "clsx"
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Edit, SimpleForm, TextInput, NumberInput, ArrayInput, FormDataConsumer, DateInput, SelectInput } from 'react-admin';
import {_formatPrice} from "../../utils/helper"
import { _enumTransferStatus} from "../../utils/enum";
import {filterItems} from "../../hooks"
import {useProductsContext} from "../../context"
import TableArrayInput, {SimpleFormIterator} from "../../partials/arrayInputWithPagination";

const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
    input: {
        width: "100%",
        "& > div > div:first-child": {
          width: "100%",
        },
        marginBottom: theme.spacing(1)
    },
    rowWarning: {
        backgroundColor: "#ffcece !important",
    }
}));
const CreateFormComponent = (props) => {
    const classes = useStyles();

    const {products = [], loading} = useProductsContext()

    const getPrice = useCallback((scopedFormData = {}) => {
        return products.find(e => e && e.id === scopedFormData.id) || {};
    }, [products])


    
    const commonProps = {
        record: props.record,
        basePath: props.basePath,
        resource: props.resource,
        permissions: props.permissions,
        addLabel: true,
    }
    const isCondition = props.resource === "warehouseagenciestransfer";
    return (
        <Grid container spacing={3}>
            <Grid item md={6}>
                <TextInput {...commonProps} disabled label="Name Transfer By" source="transfer_product.transfer_by.name" fullWidth />
            </Grid>
            <Grid item md={6}>
                <DateInput {...commonProps} label="Transfer At" source="transfer_product.transfer_at" fullWidth />
            </Grid>
            <Grid item md={6}>
                <NumberInput {...commonProps} disabled label="Total Products" source="transfer_product.total_products" fullWidth />
            </Grid>
            <Grid item md={6}>
                <NumberInput {...commonProps} disabled label="Total Prices" source="transfer_product.total_price" fullWidth />
            </Grid>
            <Grid item md={12}>
                <TextInput {...commonProps} label="Note" source="transfer_product.note" multiline rows={3} fullWidth />
            </Grid>
            <Grid item md={6} label="Status">
                <TextInput disabled {...commonProps} fullWidth={true} source="status" label="Status" _enum={_enumTransferStatus} prefix="TRANSFER_STATUS_"/>
            </Grid>
            <Grid item md={6}>
                <DateInput {...commonProps} disabled label="Created At" source="transfer_product.created_at" fullWidth />
            </Grid>
            <Grid item md={12} label="Items">
                {loading && <div className="loader"></div>}
                {products && products.length ? <ArrayInput {...commonProps} source="items">
                    <TableArrayInput>
                        <SimpleFormIterator classes={{
                            form: classes.input,
                        }}>
                            <FormDataConsumer>
                                {({getSource, formData, scopedFormData, ...rest}) => {
                                    const {unit_price,sale_price, quantity} = getPrice(scopedFormData)
                                    const isWarning = quantity < scopedFormData.quantity
                                    return (
                                        <Grid container spacing={3}>
                                            <Grid item sm={4}>
                                                <SelectInput id="product-id" 
                                                    choices={filterItems(products, formData.items, scopedFormData, "product_id")} 
                                                    label="id" source={getSource("id")} optionValue={"id"} optionText={(e)=> {return `${e.name} - ${e.quantity}`; }} fullWidth/>
                                            </Grid>
                                            <Grid item sm={isCondition ? 2 : 4}>
                                                <TextInput source={getSource("quantity")} label="Quatity" fullWidth 
                                                autoComplete='off'
                                                helperText={isWarning && "Không đủ số lượng"}
                                                options={{
                                                    InputProps: {
                                                        classes: {
                                                            root: clsx({
                                                                [classes.rowWarning]: isWarning,
                                                            }),
                                                        }
                                                    }
                                                }}/>
                                            </Grid>
                                            <Grid item sm={isCondition ? 3 : 4}>
                                                <TextInput label="Unit Price" disabled value={_formatPrice(unit_price)} fullWidth />
                                            </Grid>
                                            {isCondition && 
                                                <Grid item sm={3}>
                                                    <TextInput label="Sale Price" disabled value={_formatPrice(sale_price)} fullWidth />
                                                </Grid>
                                            }
                                        </Grid>
                                    )
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </TableArrayInput>
                </ArrayInput> : ""}
            </Grid>
        </Grid>
    ) 
};

const CreateComponent = (props) => {
    const classes = useStyles();
    return (
        <Edit {...props} undoable={false} title="Update Transfer Request">
            <SimpleForm className={classes.form} redirect='show'>
                <CreateFormComponent {...props} />
            </SimpleForm>
        </Edit>
    )
};

export default CreateComponent;