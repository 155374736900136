import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

import { USER_GROUP_OWNER_AGENCY, USER_GROUP_ADMIN } from "../../utils/const";
import { Pagination, PriceField } from "../../partials";
import ExportButton from "../exportWarehouse";
import {
    Datagrid,
    TextField,
    NumberField,
    ImageField,
    List,
    useRedirect,
    usePermissions,
  useRefresh,
} from "react-admin";
import { useMutation, useNotify } from 'react-admin';

const ActionComponents = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    resource,
    showFilter,
    total,
}) => {
    const redirect = useRedirect();
    const onClick = () => {
        redirect("/warehouseagenciestransfer");
    };
    const { permissions: auth } = usePermissions();

    return (
        <Toolbar>
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: "button",
                })}
            {auth === USER_GROUP_OWNER_AGENCY ? (
                <Button color="primary" onClick={onClick}>
                    Planing
                </Button>
            ) : (
                ""
            )}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />
        </Toolbar>
    );
};

export const SuppendButton = ({ ...props }) => {
    const notify = useNotify();
    const { record} = props;
  const id = (record && record.aw) ? record.aw.id:"";
  const status = (record && record.aw) ? record.aw.warehouse_status :"";
 const refresh = useRefresh();

    const [handleSubmit, { isLoading }] = useMutation(
        {
            type: 'suppendProduct',
            resource: 'warehouseagenciesv2',
            payload: { agency_warehouse_id: id, is_suppend: status !== 'WAREHOUSE_STATUS_SUPPENDED' },
        },
        {
            undoable: false,
            onSuccess: ({ data, status, message }) => {
                if (status) {
                refresh();
                    notify('Done', 'info', {}, false);
                } else {
                    notify(`Error: ${message}`, 'warning')
                }
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }
    );


  if (  status === 'WAREHOUSE_STATUS_SUPPENDED') {
  return (
    <>
      <Button variant="outlined" color="primary" disabled={isLoading} onClick={handleSubmit}>Publish</Button>
    </>
  )
  }
  return (
    <>
      <Button variant="outlined" color="secondary" disabled={isLoading} onClick={handleSubmit}>Suppend</Button>
    </>
  )
}


export const DatagridComponent = ({ hasShow, hasEdit, hasList, ...props }) => {
    const { permissions: auth } = usePermissions();

    const rowStyle = (record, index) => {
      return {
        backgroundColor:
            (record && record.quantity <= 0)
                ? "#dadada"
                : (record && record.quantity < 10)
                ? "#fffedb"
                : "unset",
    }
    };
    return (
        <Datagrid {...props} rowStyle={rowStyle}>
            <TextField source="p.name" label="Name" />
            <ImageField source="p.images[0]" label="Images" />
            <TextField source="p.description" label="Description" />
            <PriceField source="aw.unit_price" label="Unit Price" />
            <PriceField source="aw.sale_price" label="Sale Price" />
            <PriceField source="aw.shell_price" label="Shell Price" />
            <NumberField source="p.unit_point" label="Unit Point" />
            <NumberField source="p.net_weight" label="Net Weight" />
            <NumberField source="aw.quantity" label="Quantity" />
            {auth === USER_GROUP_ADMIN ? (
            <SuppendButton/>
            ) : (
                ""
            )}

        </Datagrid>
    );
};
const ListComponent = (props) => {
    return (
        <List
            {...props}
            pagination={<Pagination />}
            perPage={20}
            title="Warehouse Statistics"
            bulkActionButtons={false}
            sort={{ field: "aw.created_at", order: "DESC" }}
            actions={<ActionComponents />}
        >
            <DatagridComponent {...props} />
        </List>
    );
};
export default ListComponent;
