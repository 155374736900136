
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import { useForm } from 'react-final-form';

import { _clientUserStorage } from "../../utils/storage";

const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
}));
const Form = (props) => {
    const user = _clientUserStorage.get() || {}
    const agencyID = user.agency_id
    const form = useForm();
    useEffect(() => {
        form.change("agency_id", agencyID)
    }, [agencyID, form])

    return (
        <Grid container spacing={3}>
            <Grid item sm={12}>
                <TextInput label="Title" source="title" fullWidth />
            </Grid>
            <Grid item sm={12}>
                <TextInput label="Content" source="content" multiline rows={4} fullWidth />
            </Grid>
            <Grid item sm={12}>
                <TextInput
                    label="Sound"
                    source="sound"
                    rows={4}
                    fullWidth
                />
            </Grid>
        </Grid>
    )
};

const CreateComponent = (props) => {
    const classes = useStyles();
    return (
        <Edit {...props} title="Edit" >
            < SimpleForm className={classes.form} redirect={"list"} >
                <Form {...props} />
            </SimpleForm >
        </Edit >
    )
};

export default CreateComponent;
