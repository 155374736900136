import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    List,
    ListItem,
    Divider,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    fonts: {
        fontWeight: "bold",
    },
    inline: {
        display: "inline",
    },
}));

const Comment = ({ comments }) => {
    const classes = useStyles();
    if (!comments || !comments.length) return null;

    return (
        <List className={classes.root}>
            {comments.map((comment) => {
                return (
                    <React.Fragment key={comment.id}>
                        <ListItem key={comment.id} alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar alt="avatar" src={comment.avatar} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography className={classes.fonts}>
                                        {comment.name}
                                    </Typography>
                                }
                                secondary={
                                    <>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            <Typography
                                                component="p"
                                                variant="body2"
                                            >
                                                {comment.email}
                                            </Typography>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                            >
                                                {comment.title}
                                            </Typography>
                                        </Typography>
                                        {` - ${comment.content}`}
                                    </>
                                }
                            />
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                );
            })}
        </List>
    );
};

export default Comment;
