
import React from 'react';
import { Create, SimpleForm, TextInput, NumberInput } from 'react-admin';

import InputComponent from '../imageInput';

const CreateComponent = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput label="ID" source="id" />
                <TextInput label="Name" source="name" />
                <TextInput multiline label="Description" source="description" />
                <NumberInput label="Priority" source="priority" initialValue={1}/>
                <InputComponent multiple source="images" nameBase="imagesBase" label="Images"/>
            </SimpleForm>
        </Create>
    )
};

export default CreateComponent;