import React, {useState, useCallback} from 'react';
import clsx from "clsx"

import { Create, SimpleForm, SimpleFormIterator, TextInput, DateInput, ArrayInput, SelectInput, NumberInput, FormDataConsumer, FileInput, FileField, useNotify } from 'react-admin';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, useFormState } from 'react-final-form';
import {_formatPrice} from "../../utils/helper"
import {filterItems} from "../../hooks"
import {useProductsContext} from "../../context"
const useStyles = makeStyles(theme => ({
    root: {
      width: "100%",
      "& > div > div:first-child": {
        width: "100%",
      },
      marginBottom: theme.spacing(1)
    },
    rowWarning: {
        backgroundColor: "#ffcece !important",
    }
}));

const UploadFile = ({getPrice, products=[], ...props}) => {
    const notify = useNotify()
    const [loading, setLoading] = useState(false);
    const form = useForm();
    const handleChange = (values) => {
        setLoading(true);
        if(values) {
            if (values.size > 1 * 1024 * 1024) {
                notify("File is too big")
                setLoading(false);
                return
            }
            var reader = new FileReader();
            reader.onload = function(e) {
                const value = (reader.result.split("\n") || []).slice(1).map(e => {
                    let [product_id, quantity] = e.split(",")
                    const product = getPrice({product_id}) || {}
                    if(!product.id) {
                        return {
                            product_id: ""
                        }
                    }
                    return {
                        product_id,
                        quantity: Number(quantity),
                    }
                }).filter((e = {}) => e.product_id !== "" && e.quantity > 0)
                setLoading(false);
                form.batch(() => {
                    form.change("importData", value)
                    form.change("items", [])
                })
            }
            reader.readAsText(values);
        } 
        else {
            form.change("importData", [])
            setLoading(false);
        }
    }                
    return (
        <>
            {loading && <div className="loader"></div>}
            <FileInput source="files" label="Related files" onChange={handleChange}>
                <FileField source="src" title="title" />
            </FileInput>
        </>
    )
}
const ItemFormComponent = ({getPrice, products = [], ...props}) => {
    const classes = useStyles();
    return (
        <FormDataConsumer {...props}>
            {({getSource, formData, scopedFormData, ...rest}) => {
                const {unit_price,sale_price, quantity} = getPrice(scopedFormData)
                const isWarning = scopedFormData ? quantity < scopedFormData.quantity : false
                return (
                    <Grid container spacing={3}>
                        <Grid item sm={4}>
                            <SelectInput id="product-id" choices={filterItems(products, formData.items, scopedFormData, "product_id")} label="Product" 
                            source={getSource("product_id")} optionText={(e)=> {return `${e.name} - ${e.quantity}`; }} fullWidth/>
                        </Grid>
                        <Grid item sm={2}>
                            <NumberInput label="Quantity" source={getSource("quantity")} defaultValue={1} fullWidth 
                                autoComplete='off'
                                helperText={isWarning && "Không đủ số lượng"}
                                options={{
                                    InputProps: {
                                        classes: {
                                            root: clsx({
                                                [classes.rowWarning]: isWarning,
                                            }),
                                        }
                                    }
                                }}/>
                        </Grid>
                        <Grid item sm={3}>
                            <TextField label="Unit Price" disabled value={_formatPrice(unit_price)} fullWidth />
                        </Grid>
                        <Grid item sm={3}>
                            <TextField label="Sale Price" disabled value={_formatPrice(sale_price)} fullWidth />
                        </Grid>
                    </Grid>
                )
            }}
        </FormDataConsumer>
    )
}
const ItemComponent = ({products = [], source, getPrice, ...props}) => {
    const record = useFormState().values;
    const classes = useStyles();
    if (((record.importData && record.importData.length) || record.files)) {
        return "";
    }
    return (
        <ArrayInput source={source} record={record} {...props} fullWidth>
            <SimpleFormIterator classes={{
                form: classes.root,
            }} {...props}>
                <ItemFormComponent {...props} getPrice={getPrice} products={products} />
            </SimpleFormIterator>
        </ArrayInput>
    )
}
const CreateComponent = (props) => {
    const {products = []} = useProductsContext()

    const getPrice = useCallback(
        (scopedFormData = {}) => {
            return products.find(e => e.id === scopedFormData.product_id) || {};
        },
        [products],
    )
    return (
        <Create {...props}>
            <SimpleForm redirect={"list"}>
                <DateInput label="Transfer at" source="transfer_at" defaultValue={new Date()} />
                <TextInput label="Note" source="note" multiline rows={3} fullWidth />
                <UploadFile label="Upload" getPrice={getPrice} products={products || []} fullWidth/>
                <ItemComponent source="items" getPrice={getPrice} products={products} fullWidth />
            </SimpleForm>
        </Create>
    )
};

export default CreateComponent;

export {ItemFormComponent};