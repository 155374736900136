import polyglotI18nProvider from 'ra-i18n-polyglot';

import {en, vi} from '../languages';
import {_clientLangueStorage} from "../utils/storage"
const getMessagesLanguage = (name = "en") => {
    switch(name) {
        case "en": 
            return en;  
        case "vi": 
            return vi;
        default: 
            return en;
    }
}

const i18nProvider = polyglotI18nProvider(locale => 
    getMessagesLanguage(locale),
    _clientLangueStorage.get() || "vi", // Default locale,
    {allowMissing: true}
);
export default i18nProvider