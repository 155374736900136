import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import LockIcon from "@material-ui/icons/Lock";
import { Button, Menu, MenuItem, Card, CardContent } from "@material-ui/core";
import {
    EditButton,
    DeleteWithConfirmButton,
    ShowButton,
    Error,
    useQuery,
} from "react-admin";

import DialogComponent from "../users/resetPassword";

import Map from "../map";
import DataGridAgencyList from "./agencyDataGrid";
const useStyles = makeStyles((theme) => ({
    map: {
        marginBottom: theme.spacing(3),
        minHeight: theme.spacing(30),
        width: "100%",
        height: theme.spacing(70),
        "& > div:first-child": {
            width: "100%",
            height: "100%",
        },
    },
    content: {
        overflow: "auto",
    },
}));

const ButtonComponent = (props) => {
    const { record = {} } = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleReset = () => {
        if (props.handleReset) props.handleReset(record);
    };
    return (
        <div>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                ...
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>
                    <ShowButton {...props} />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <EditButton {...props} />
                </MenuItem>
                {record.status !== "AGENCY_STATUS_CLOSE" &&
                record.status !== "CLOSE" ? (
                    <MenuItem onClick={handleClose}>
                        <DeleteWithConfirmButton {...props} />
                    </MenuItem>
                ) : (
                    ""
                )}
                <MenuItem onClick={handleClose}>
                    <Button
                        {...props}
                        color="primary"
                        onClick={handleReset}
                        startIcon={<LockIcon />}
                    >
                        Reset Password
                    </Button>
                </MenuItem>
            </Menu>
        </div>
    );
};

const ListComponent = (props) => {
    const classes = useStyles();
    const [showInfoIndex, setShowInfoIndex] = useState();
    const [isReset, setIsReset] = useState();
    const [record, setRecord] = useState({});
    const handleClose = () => {
        setIsReset(false);
    };
    const handleReset = (record) => {
        setIsReset(true);
        setRecord(record);
    };
    const { data, error } = useQuery({
        type: "all",
        resource: "agencies",
    });

    if (error) return <Error />;

    return (
        <div>
            <DataGridAgencyList {...props} bulkActionButtons={false}>
                <ButtonComponent {...props} handleReset={handleReset} />
            </DataGridAgencyList>
            <Card>
                <CardContent>
                    <div className={classes.map}>
                        <Map
                            {...props}
                            zoom={10}
                            coords={(data || [])
                                .filter(
                                    (e) =>
                                        e.latitude !== undefined &&
                                        e.longitude !== undefined
                                )
                                .map((e) => {
                                    return {
                                        ...e,
                                        lat: e.latitude,
                                        lng: e.longitude,
                                        text: e.name,
                                    };
                                })}
                            showInfo={setShowInfoIndex}
                            showInfoIndex={showInfoIndex}
                            latitude={11.1820527}
                            longitude={106.3707954}
                        />
                    </div>
                </CardContent>
            </Card>
            {isReset && (
                <DialogComponent
                    {...props}
                    classesContent={{
                        root: classes.rootContent,
                    }}
                    record={record}
                    user_id={record.owner ? record.owner.id : ""}
                    anchor="right"
                    handleClose={handleClose}
                    title="Reset Password"
                    maxWidth="md"
                    open
                    fullWidth
                />
            )}
        </div>
    );
};

export default ListComponent;
