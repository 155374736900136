import * as React from "react";
import PropTypes from "prop-types";
import { Form as RaForm } from "react-final-form";
import { Button, CardActions, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "ra-core";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles(
    (theme) => ({
        form: {
            padding: "0 1em 1em 1em",
        },
        input: {
            marginTop: "1em",
        },
        button: {
            width: "100%",
        },
        icon: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: "RaForm" }
);

export const Input = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input: inputProps, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);
const Form = (props) => {
    const {
        children,
        validate,
        onSubmit,
        loading,
        btn = "ra.auth.sign_in",
    } = props;
    const translate = useTranslate();

    const classes = useStyles(props);

    return (
        <RaForm
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    {children}
                    <CardActions>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            className={classes.button}
                        >
                            {loading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {translate(btn)}
                        </Button>
                    </CardActions>
                </form>
            )}
        />
    );
};

Form.propTypes = {
    redirectTo: PropTypes.string,
};

export default Form;
