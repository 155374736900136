import React from 'react';
import { TextField } from 'react-admin';
import PropTypes from "prop-types";

import {_getLodash, _setLodash, _formatDate} from '../utils/helper';
const DateField =  (props) => {
    const {record: oldRecord = {}, source = "", label = "", addLabel = true} = props;
    let record = JSON.parse(JSON.stringify(oldRecord))
    const value = _getLodash(record, source) ? _formatDate(_getLodash(record, source)) : ""
    if(value) {
        record = _setLodash(record, source, value);
    }
    
    return (
        <TextField {...props} record={record} source={source} label={label} addLabel={addLabel}/>
    );
};
DateField.propTypes = {
    record: PropTypes.object,
    _enum: PropTypes.object,
    source: PropTypes.string,
    prefix: PropTypes.string,
}

DateField.defaultProps = {
    addLabel: true,
    record: {},
    _enum: {},
    prefix: '',
    source: '',
}
export default DateField;
