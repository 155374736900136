import React, { useState, useCallback } from "react";

import {
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";

import {
    useNotify,
    useMutation,
    useRefresh,
    useQuery,
    Loading,
} from "react-admin";
import { ButtonField } from "../../../partials";
import Comments from "../../comments";
const NotificationDialog = ({ open, orderId, handleClose }) => {
    const [content, setContent] = useState();
    const refresh = useRefresh();
    const notify = useNotify();

    const [onSendNotification] = useMutation(
        {
            type: "sendNotification",
            resource: "orderComments",
            payload: {
                data: {
                    order_id: orderId,
                    message: content,
                },
            },
        },
        {
            onSuccess: () => {
                notify("Send successfully");
                refresh();
            },
            onFailure: (error) =>
                notify(`Send error: ${error.message}`, "warning"),
        }
    );

    const handleChangeContent = useCallback((e) => {
        setContent(e.target.value);
    }, []);

    const { data, loading } = useQuery(
        {
            type: "all",
            resource: "orderComments",
            payload: {
                data: {
                    order_id: orderId,
                },
            },
        },
        {
            onFailure: (error) =>
                notify(
                    `get list order comments error: ${error.message}`,
                    "warning"
                ),
        }
    );
    console.log("data", data);
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                Send Notification
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {loading ? <Loading /> : <Comments comments={data} />}
                    Vui lòng nhập nội dung notification
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Content"
                    value={content}
                    multiline
                    fullWidth
                    onChange={handleChangeContent}
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onSendNotification} color="primary" autoFocus>
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
};
const SendNotification = (props) => {
    const { orderId = [] } = props;
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <ButtonField name="Send Notification" onClick={handleClickOpen} />
            {open && (
                <NotificationDialog
                    open={open}
                    orderId={orderId}
                    handleClose={handleClose}
                />
            )}
        </div>
    );
};
export default SendNotification;
