import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

import {Button, Dialog, DialogTitle, DialogContent, TextField as TextFieldMaterial, DialogActions} from "@material-ui/core"
import { useMutation, useNotify} from 'react-admin';


const useStyles = makeStyles((theme) => ({
    rootContent: {
        
    },
}));

const DialogComponent = (props) => {
    const notify = useNotify();
    const classes = useStyles();
    const {maxWidth = "md", user_id, classesContent = {}, handleClose, title, children, ...rest} = props;
    const [password, setPassword] = React.useState()
    const handleChange = (e) => {
        setPassword(e.target.value)
    }
    const [handleSubmit] = useMutation(
        {
            type: 'resetPassword',
            resource: 'users',
            payload: { user_id: user_id, password: password },
        },
        {
            undoable: false,
            onSuccess: ({ data, status, message }) => {
                if(status) {
                    notify('Done', 'info', {}, false);
                    handleClose()
                } else {
                    notify(`Error: ${message}`, 'warning')
                }
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }
    );
    return (
        <Dialog {...rest} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent classes={{
                ...classesContent,
                root: classes.rootContent,
            }} maxWidth={maxWidth}> 
                <TextFieldMaterial label="Password" name="password" type="password" value={password} fullWidth onChange={handleChange}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogComponent;
