import vietnameseMessages from "ra-language-vietnamese";
let object = vietnameseMessages;
object.ra = object.ra || {};
object.ra.page = {
    ...(object.ra.page || {}),
    empty: "Không có dữ liệu",
    invite: "Bạn có muốn tạo mới",
};

export const vi = {
    ...object,
    resources: {
        users: {
            name: "Người dùng",
            fields: {
                first_name: "Tên",
                last_name: "Họ",
                dob: "Ngày sinh",
            },
        },
    },
    status: {
        active: "Hoạt động",
        inactive: "Không hoạt động",
        close: "Không hoạt động",
        done: "Hoàn thành",
        draft: "Nháp",
        none: "none",
        delivered: "Đã giao",
        canceled: "Đã hủy",
        assigned: "Đã nhận",
        on_delivering: "Đang giao",
        on_assigning: "Đang nhận",
        waiting_to_finish: "Chờ hoàn thành",
        pending: "Đang chờ",
        requested: "Đã yêu cầu",
        accepted: "Đã nhận",
        waiting_for_approve_return: "Đang chờ để trả về",
        returned: "Đã trả",
        on_returning: "Đang trả",
    },
    all: "Tất cả",
    Done: "Xong",
    menu: {
        agency_type: "Loại đại lý",
        dashboard: "Dashboard",
        users: "Người dùng",
        admin: "Quản lí",
        employee: "Nhân viên",
        partner: "Đối tác   ",
        customer: "Khách hàng",
        vip_customer: "Khách hàng Vip",
        agency: "Đại lí",
        category: "Danh mục",
        product: "Sản phẩm",
        productmetadata: "Siêu dữ liệu sản phẩm",
        warehouse: "Nhà kho",
        transfer_requests: "Yêu cầu chuyển hàng",
        config: "Tùy chỉnh",
        employee_salary: "Lương nhân viên",
        orders: "Đơn hàng",
        profile: "Hồ sơ",
        notification: "Thông báo",
        event: "Sự kiện",
    },
    sum: "Tổng cộng: %{sum}",
    user_group: {
        owner_agency: "Chủ đại lí",
        employee_agency: "Nhân viên đại lí",
        employee: "Nhân viên",
        none: "None",
        admin: "Quản lí",
        customer: "Khách hàng",
    },
    need_back_shell: "Cần trả vỏ: %{count}",
    gender: {
        none: "None",
        male: "Nam",
        female: "Nữ",
    },
};
