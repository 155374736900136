import { _endpointAgencyType } from "../../utils/provider";
import { _createRequest, _getFilter, _pick, _fetch } from "../../utils/helper";
const agenciesEndpoint = (props) => {
    const { type, params = {} } = JSON.parse(JSON.stringify(props));

    switch (type) {
        case "create": {
            return _fetch(
                _createRequest(_endpointAgencyType.create, true, {
                    ..._pick(params.data, ["name", "point_rate"]),
                })
            );
        }
        case "update": {
            return _fetch(
                _createRequest(_endpointAgencyType.update, true, {
                    ..._pick(params.data, ["name", "id", "point_rate"]),
                })
            );
        }
        case "one": {
            return _fetch(
                _createRequest(_endpointAgencyType.list, true, {
                    filter: `${_getFilter(
                        {
                            filter: { id: params.id },
                        },
                        {
                            id: "eq",
                        }
                    )}`,
                })
            ).then((res) => {
                const data = (res.data || {})?.[0] || {};
                return {
                    data,
                };
            });
        }
        default: {
            //list
            return _fetch(
                _createRequest(_endpointAgencyType.list, true, {
                    filter: `${_getFilter(
                        {
                            filter: params.filter,
                            sort: params.sort,
                        },
                        {}
                    )}`,
                })
            );
        }
    }
};

export default agenciesEndpoint;
