import UserIcon from "@material-ui/icons/Group";
import ApartmentIcon from "@material-ui/icons/Apartment";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import BallotIcon from "@material-ui/icons/Ballot";
import HouseIcon from "@material-ui/icons/House";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import AcUnit from "@material-ui/icons/AcUnit";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import EventIcon from "@material-ui/icons/Event";

import { _arrayOrderStatus, _arrayTransferStatus } from "./enum";
import { USER_GROUP_ADMIN } from "./const";
export const _adminRule = [
    {
        name: "menu.users",
        key: "users",
        icon: UserIcon,
        menu: [
            {
                name: "menu.admin",
                key: "admins",
                user_group: USER_GROUP_ADMIN,
            },
            {
                name: "menu.employee",
                key: "employees",
                user_group: "USER_GROUP_EMPLOYEE_COMPANY",
            },
            {
                name: "menu.customer",
                key: "customers",
                user_group: "USER_GROUP_CUSTOMER",
            },
        ],
    },
    {
        name: "menu.agency",
        key: "agencies",
        icon: ApartmentIcon,
    },
    {
        name: "menu.agency_type",
        key: "agency_types",
        icon: AcUnit,
    },
    {
        name: "menu.partner",
        key: "partners",
        icon: BusinessCenter,
    },
    {
        name: "menu.category",
        key: "categories",
        icon: DragIndicatorIcon,
    },
    {
        name: "menu.product",
        key: "products",
        icon: FlashOnIcon,
    },
    {
        name: "menu.productmetadata",
        key: "productmetadatas",
        icon: OfflineBoltIcon,
    },
    {
        name: "menu.warehouse",
        key: "warehousecompanies",
        icon: BallotIcon,
    },
    {
        name: "menu.transfer_requests",
        key: "transferrequest",
        icon: CloudDownloadIcon,
        menu: [
            { name: "all", key: "transferrequests", icon: CloudDownloadIcon },
            ..._arrayTransferStatus
                .filter((e) => e.name !== "NONE")
                .map((e) => {
                    let name = e.name
                        .replace("TRANSFER_STATUS_", "")
                        .toLocaleLowerCase();
                    return {
                        name: "status." + name,
                        key: `transferrequests${name}`,
                        status: e,
                        icon: CloudDownloadIcon,
                    };
                }),
        ],
    },
    {
        name: "menu.config",
        key: "configs",
        icon: StarBorderIcon,
    },
    {
        name: "menu.notification",
        key: "notifications",
        icon: NotificationsActiveIcon,
    },
];

export const _ownerRule = [
    {
        name: "menu.dashboard",
        key: "dashboard",
        icon: ApartmentIcon,
    },
    {
        name: "menu.users",
        icon: UserIcon,
        key: "users",
        menu: [
            {
                name: "menu.employee",
                key: "employeeagencies",
                user_group: "USER_GROUP_EMPLOYEE_AGENCY",
            },
            {
                name: "menu.customer",
                key: "agency_customers",
                user_group: "USER_GROUP_CUSTOMER",
            },
            {
                name: "menu.vip_customer",
                key: "vip_customers",
                user_group: "USER_GROUP_VIP_CUSTOMER",
            },
        ],
    },
    {
        name: "menu.warehouse",
        key: "warehouseagenciesv2",
        icon: HouseIcon,
    },
    {
        name: "menu.employee_salary",
        key: "employeesalaries",
        icon: LocalAtmIcon,
    },
    {
        name: "menu.orders",
        key: "order",
        icon: StarBorderIcon,
        menu: [
            { name: "all", key: "orders", icon: StarBorderIcon },
            ..._arrayOrderStatus
                .filter((e) => e.name !== "NONE")
                .map((e) => {
                    let name = e.name
                        .replace("ORDER_STATUS_", "")
                        .toLocaleLowerCase();
                    return {
                        name: "status." + name,
                        key: `orders${name.replace("_", "")}`,
                        status: e,
                        icon: StarBorderIcon,
                    };
                }),
        ],
    },
    {
        name: "menu.config",
        key: "configagencies",
        icon: StarBorderIcon,
    },
    {
        name: "menu.notification",
        key: "notifications",
        icon: NotificationsActiveIcon,
    },
    {
        name: "menu.event",
        key: "events",
        icon: EventIcon,
    },
];
