import { _endpointWarehouseCompany } from "../../utils/provider";
import { _createRequest, _getFilter, _fetch } from "../../utils/helper";
const endpoint = (props) => {
  let { type, params = {} } = props;
  params.filter = params.filter || {};
  const sort = !Array.isArray(params.sort) ? [params.sort] : params.sort;
  switch (type) {
    case "manyReference":
    case "many": {
      //many
      const { target = "id" } = params;
      if (params.ids) {
        params.filter = {
          ...params.filter,
          [target]: params.ids,
        };
      }
      params.pagination = {
        ...params.pagination,
        perPage: (params.ids || []).length || 10,
      };
      return _fetch(
        _createRequest(_endpointWarehouseCompany.statistic, true, {
          filter: _getFilter(params, { status: "eq", agency_id: "in" }),
        })
      );
    }
    case "all": {
      const getAll = (page = 1, data = []) => {
        const perPage = 100;
        params.pagination = {
          perPage: perPage,
          page: page,
        };

        return _fetch(
          _createRequest(_endpointWarehouseCompany.statistic, true, {
            filter: _getFilter(params, { status: "eq", agency_id: "in" }),
          })
        ).then((res) => {
          if (res.total === undefined || res.total <= page * perPage) {
            return {
              ...res,
              data: [...data, ...res.data],
            };
          } else {
            return getAll(page + 1, [...data, ...res.data]);
          }
        });
      };
      return getAll(1);
    }
    default: {
      //list
      return _fetch(
        _createRequest(_endpointWarehouseCompany.statistic, true, {
          filter: _getFilter(
            {
              ...params,
              sort: [...sort, { field: "cw.id", order: "ASC" }],
            },
            { status: "eq", quantity: "lt" }
          ),
        })
      );
    }
  }
};

export default endpoint;
