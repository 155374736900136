import { useState, useEffect} from 'react';

import { useDataProvider } from 'react-admin';

function useGetAllProducts(deleted_at) {
    const dataProvider = useDataProvider();
    const perPage = 50;
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setData([]);
        setPage(1);
        setTotal(0);
    }, [deleted_at])

    useEffect(() => {
        async function fetchData(page) {
            setLoading(true);
            const result = await dataProvider
                .getList("products", {
                    filter: deleted_at ? {
                        deleted_at: true,
                     } : {},
                    sort: [{
                        field: "name",
                        order: "DESC",
                    },{
                        field: "created_at",
                        order: "DESC",
                    }],
                    pagination: {page, perPage},
                })
                .then((res = {}) => {
                    const {total = 0, data = []} = res;
                    setData((pre = []) => pre.concat(data));
                    setTotal(total);
                    return res;
                })
                .catch((err) => {
                    throw err;
                });
            setLoading(false);
            return result;
        }
        fetchData(page);
    }, [dataProvider, deleted_at, page])
    useEffect(() => {
        if(total > data.length) {
            setPage(pre => pre + 1)
        }
    }, [data, total])
    return {
        data, 
        loading, 
        page, 
        total,
        perPage,
    };
}

export default useGetAllProducts;
