import {_endpointReportAgency} from '../../utils/provider';
import {_createRequest, _fetch} from '../../utils/helper'
const agencyReportEndpoint = (props) => {
    const {type, params = {}} = props;

    switch(type) {
        case "one": {
            return _fetch(_createRequest(_endpointReportAgency.point, true, {
              "agency_id": params.agencyId,
              "group_by": params.groupBy,
              "year": params.year,
              "limit": params.limit || 10,
              "offset": params.offset
            }))
        }
          default: {
            return
          }
    }
}

export default agencyReportEndpoint
