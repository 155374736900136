
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Edit, SimpleForm, TextInput, SelectInput, required, NumberInput, 
    Toolbar,
    ArrayInput,
    SimpleFormIterator,
    FormDataConsumer,
    SaveButton,
    ArrayField,
    NumberField,
    Datagrid,
 } from 'react-admin';
import { _choiceVipCustomerStatus } from '../../utils/enum';
import {LinkField, GridField} from '../../partials'
import {useGetAllCategories, filterItems} from "../../hooks"


const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
    map: {
        marginBottom: theme.spacing(3),
        minHeight: theme.spacing(10),
        width: "100%",
        height: theme.spacing(30),
        "& > div:first-child": {
            width: "100%",
            height: "100%"
        }
    }
}));

const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const CreateFormComponent = (props) => {
    const {data: categories = []} = useGetAllCategories()
    return (
        <Grid container spacing={3}>
            <Grid item sm={6}>
                <TextInput label="Name" source="profile.name" disabled fullWidth />
            </Grid>
            <Grid item sm={6}>
                <SelectInput label="Status" source="status" optionValue={"key"} choices={_choiceVipCustomerStatus} fullWidth />
            </Grid>
            <GridField item sm={12} label="Commitments">
                <ArrayField {...props} label="Commitments" source="commitments">
                    <Datagrid>
                        <LinkField reference="categories" field="name" source="category_id" label="Category ID"/>
                        <NumberField source="value" label="Value"/>
                    </Datagrid>
                </ArrayField>
            </GridField>
            <Grid item sm={12}>
                <ArrayInput source="promotions" label="Promotions" validate={[required()]}>
                    <SimpleFormIterator>
                        <FormDataConsumer>
                            {({getSource, formData = {}, scopedFormData = {},...rest}) => {
                                return (
                                    <Grid container spacing={3}>
                                        <Grid item sm={6}>
                                            <SelectInput id="category-id" optionText={"name"} label="Product" source={getSource("category_id")} fullWidth choices={filterItems(categories, formData.promotions, scopedFormData, "category_id")} />
                                        </Grid>
                                        <Grid item sm={6}>
                                            <NumberInput disabled={!formData.agency_id} source={getSource("value")} defaultValue={1} fullWidth label="Value"/>
                                        </Grid>
                                    </Grid>
                                )
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </Grid>
        </Grid>
    )
};

const CreateComponent = (props) => {
    const classes = useStyles();
    return (
        <Edit {...props} undoable={false} title={`Update Vip Customer`}>
            <SimpleForm className={classes.form} redirect="show" toolbar={<CustomToolbar />}>
                <CreateFormComponent {...props} />
            </SimpleForm>
        </Edit>
    )
};

export default CreateComponent;