import moment from 'moment';

import {_endpointEmployeeSalary} from '../../utils/provider';
import {_createRequest, _getFilter, _pick, _fetch} from '../../utils/helper';
import {_clientUserStorage} from '../../utils/storage';
import {USER_GROUP_OWNER_AGENCY} from '../../utils/const';
const endpoint = async(props) => {
    const {type, params = {}} = props;
    const {filter = {}} = params;
    const user = await _clientUserStorage.get() || {};
    if(user.group === USER_GROUP_OWNER_AGENCY) {
        filter.agency_id = user.agency_id;
    } 
    if(filter.datetime) {
        const e = filter.datetime
        const month = moment(e).month() + 1;
        const year = moment(e).year()

        delete filter.datetime;
        filter.month = month;
        filter.year = year;
    }
    params.filter = filter;
    
    switch(type) {
        case "one": {
            return _fetch(_createRequest(_endpointEmployeeSalary.one, true, {
                ..._pick(params, ["employee_id", "year", "month"])
            })).then(res => {
                res.data = res.data.map(e => {
                    if(e.statistics && typeof e.statistics !== "object") {
                        e.statistics = JSON.parse(e.statistics)
                    }
                    return e;
                })
                return res
            })
        }
        case "many": 
        case "manyReference": {
            const {target = "id"} = params;
            if(params.ids) {
                params.filter = {
                    ...params.filter,
                    [target]: params.ids,
                }
            }
            params.pagination = {
                ...params.pagination,
                perPage: (params.ids || []).length || 10,
            }
            return _fetch(_createRequest(_endpointEmployeeSalary.list, true, {
                filter: _getFilter(params, {year: "eq", month: "eq", status: "eq", agency_id: "in", employee_id: "eq"})
            }))
        }
        default: { //list
            return _fetch(_createRequest(_endpointEmployeeSalary.list, true, {
                filter: `${_getFilter(params, {status: "eq", month : "eq", year : "eq", agency_id: "eq", employee_id: "eq"})}`
            }))
            .then(res => {
                if(res.data && res.data.length) {
                    res.data = res.data.map((row, index) => {
                        if(row.statistics && typeof row.statistics !== "object") {
                            row.statistics = JSON.parse(row.statistics)
                        }
                        row.id = row.summary_id
                        return row
                    })
                }
                
                return res
            })
        }
    }
}

export default endpoint