import React, { Fragment } from "react";

import { Button, Menu, MenuItem } from "@material-ui/core";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ImageField,
  TextInput,
  Filter,
  UrlField,
  ShowButton,
  BooleanField,
  ReferenceField,
} from "react-admin";

import { Pagination } from "../../partials";

const FilterComponents = (props) => {
  return (
    <Filter {...props}>
      <TextInput source="app_name" label="Name" />
    </Filter>
  );
};

const ButtonComponent = (props) => {
  const { record = {}, basePath, resource, id } = props;
  const commonProps = {
    basePath,
    resource,
    record,
    id,
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        ...
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <EditButton {...commonProps} label="Edit" />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ShowButton {...commonProps} label="Show" />
        </MenuItem>
      </Menu>
    </div>
  );
};

const DatagridComponent = (props) => {
  return (
    <Fragment>
      <Datagrid {...props} selectedIds={[]}>
        <TextField source="id" label="ID" />
        <TextField source="app_name" label="Name" />
        <ImageField source="app_icon" label="Icon" />
        <UrlField source="appstore_url" label="Appstore" />
        <UrlField source="chplay_url" label="CHPlay" />
        <UrlField source="gift_id" label="Gift ID" />
        <BooleanField source="is_show_all" label="Show All" />
        <BooleanField source="in_system" label="In system" />
        <ReferenceField
          label="Agency Type"
          source="agency_type_id"
          reference="agency_types"
        >
          <TextField source="name" />
        </ReferenceField>

        <ButtonComponent {...props} />
      </Datagrid>
    </Fragment>
  );
};

const ListComponent = (props) => {
  return (
    <List
      {...props}
      pagination={<Pagination />}
      perPage={20}
      bulkActionButtons={false}
      filters={<FilterComponents />}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <DatagridComponent {...props} />
    </List>
  );
};
export default ListComponent;

export { DatagridComponent };
