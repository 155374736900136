import * as React from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  useTranslate,
  useNotify,
  useSafeSetState,
  useAuthProvider,
} from "ra-core";
import Form, { Input } from "./Form";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(
  (theme) => ({
    form: {
      padding: "0 1em 1em 1em",
    },
    input: {
      marginTop: "1em",
    },
    button: {
      width: "100%",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: "RaLoginEmail" }
);

const LoginEmail = (props) => {
  const { redirectTo } = props;
  var authProvider = useAuthProvider();
  const [loading, setLoading] = useSafeSetState(false);
  const [profile, setProfile] = React.useState({});
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);
  var history = useHistory();

  const validate = (values) => {
    const errors = { username: undefined, password: undefined };

    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  const submit = (values) => {
    setLoading(true);

    authProvider
      .login({
        user_id: profile.id,
        ...values,
      })
      .then(async (data) => {
        if (data?.profile && !data.token) {
          setProfile(data.profile);
        } else {
          history.push(redirectTo);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const message =
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message;

        notify(message, {
          type: "warning",
          messageArgs: {
            _:
              typeof error === "string"
                ? error
                : error && error.message
                ? error.message
                : undefined,
          },
        });
      });
  };

  return (
    <Form onSubmit={submit} validate={validate} loading={loading}>
      <div className={classes.form}>
        <div className={classes.input}>
          <Field
            autoFocus
            id="username"
            name="username"
            component={Input}
            label={translate("ra.auth.username")}
            disabled={loading || profile?.id}
          />
        </div>
        <div className={classes.input}>
          {profile.id ? (
            <Field
              autoFocus
              id="otp"
              name="otp"
              component={Input}
              label={"OTP"}
              disabled={loading}
            />
          ) : (
            <Field
              id="password"
              name="password"
              component={Input}
              label={translate("ra.auth.password")}
              type="password"
              disabled={loading}
              autoComplete="current-password"
            />
          )}
        </div>
      </div>
    </Form>
  );
};

LoginEmail.propTypes = {
  redirectTo: PropTypes.string,
};

export default LoginEmail;
