import {_endpointWarehouseCompany} from '../../utils/provider';
import {_createRequest, _pick, _getFilter, _fetch} from '../../utils/helper'
const endpoint = async(props) => {
    const {type, params = {}} = props;
    switch(type) {
        case "create": {
            if(params.data && params.data.importData) {
                params.data.items = params.data.importData
            }
            return _fetch(_createRequest(_endpointWarehouseCompany.import.create, true, {
                ..._pick(params.data, ["import_at", "note", "items"])
            }))
        }
        case "update": {
            params.data = params.data || {};
            let data = {
                import_id: params.id,
                ..._pick((params.data.import_product || {}), ["import_at", "note"]),
            }
            data.items = (params.data.items || []).map(e => {
                return {
                    product_id: e.id || e.product_id,
                    price: e.unit_price,
                    quantity: e.quantity
                }
            })
            return await _fetch(_createRequest(_endpointWarehouseCompany.import.update, true, {
                ..._pick(data, ["import_at", "note", "items", "import_id"]),
            }))
        }
        case "delete": {
            return _fetch(_createRequest(_endpointWarehouseCompany.import.delete, true, {
                import_id: params.id
            }))
        }
        case "one": {
            return _fetch(_createRequest(_endpointWarehouseCompany.import.one, true, {
                import_id: params.id
            }))
            .then(res => {
                if(res.data) {
                    res.data.id = res.data.import_product ? res.data.import_product.id : ""
                }
                return res;
            })
        }
        case "many": 
        case "manyReference": {
            const {target = "id"} = params;
            params.filter = params.filter || {}
            if(params.ids) {
                params.filter = {
                    ...params.filter,
                    [target]: params.ids,
                }
            }
            params.pagination = {
                ...params.pagination,
                perPage: (params.ids || []).length || 10,
            }
            return _fetch(_createRequest(_endpointWarehouseCompany.transfer.list, true, {
                filter: _getFilter(params, {agency_id: "in", [target]: "in"})
            }))
        }
        // case "many": {
        //     params.filter = {
        //         ...params.filter,
        //         agency_id: params.ids,
        //     }
        //     params.pagination = {
        //         ...params.pagination,
        //         perPage: (params.ids || []).length || 10,
        //     }
        //     return _fetch(_createRequest(_endpointWarehouseCompany.transfer.list, true, {
        //         filter: _getFilter(params, {agency_id: "in"})
        //     }))
        // }
        case "finish": {
            return _fetch(_createRequest(_endpointWarehouseCompany.import.finish, true, {
                import_id: params.id
            }))
            .then(res => {
                if(res.data) res.data.id = res.import_product ? res.import_product.id : ""
                return res;
            })
        }
        default: { //list
            return _fetch(_createRequest(_endpointWarehouseCompany.import.list, true, {
                filter: _getFilter(params, {status: "eq"})
            }))
        }
    }
}

export default endpoint