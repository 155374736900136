
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Edit, SimpleForm, TextInput, SelectInput, DateInput, NumberInput, 
    Toolbar,
    SaveButton } from 'react-admin';
// import CompleteAddress from '../completeAddress';
import { _choiceUserGroup, _choiseSex, _choiceUserStatus } from '../../utils/enum';


const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
    map: {
        marginBottom: theme.spacing(3),
        minHeight: theme.spacing(10),
        width: "100%",
        height: theme.spacing(30),
        "& > div:first-child": {
            width: "100%",
            height: "100%"
        }
    }
}));

const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const CreateComponent = (props) => {
    const classes = useStyles();
    return (
        <Edit {...props} undoable={false} title={`Update ${props.resource}`}>
            <SimpleForm className={classes.form} redirect="show" toolbar={<CustomToolbar />}>
                <Grid container spacing={3}>
                    <Grid item sm={6}>
                        <TextInput label="Name" source="name" fullWidth />
                    </Grid>
                    <Grid item sm={6}>
                        <TextInput label="Phone" source="phone_number" fullWidth />
                    </Grid>
                    <Grid item sm={6}>
                        <TextInput label="Email" source="email" fullWidth />
                    </Grid>
                    <Grid item sm={6}>
                        <DateInput label="DOB" source="birth_day" defaultValue={new Date()} fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <SelectInput label="Sex" source="sex" optionValue={"name"} choices={_choiseSex} fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <SelectInput label="Group" source="group" optionValue={"name"} disabled choices={_choiceUserGroup} fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <SelectInput label="Status" source="status" optionValue={"key"} choices={_choiceUserStatus} fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <NumberInput label="Basic Salary" source="basic_salary" fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <NumberInput label="Latitude" source="latitude" fullWidth />
                    </Grid>
                    <Grid item sm={4}>
                        <NumberInput label="Longitude" source="longitude" fullWidth />
                    </Grid>
                    <Grid item sm={12}>
                        <TextInput label="Address" source="address" fullWidth />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};

export default CreateComponent;