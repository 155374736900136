import list from './list'
import create from './create'
import edit from './edit'

export default {
    list,
    create,
    edit,
    name: "notifications",
    key: "p_notifications",
}