import { _createRequest, _fetch, _pick } from "../../utils/helper";
import { _clientUserStorage } from "../../utils/storage";
import { _endpointUser } from "../../utils/provider";

const endpoint = async (props) => {
  const { type, params = {} } = props;

  switch (type) {
    case "profile":
    case "one": {
      const user = _clientUserStorage.get();
      return _fetch(
        _createRequest(_endpointUser.one, true, {
          ..._pick(user, ["id"]),
        })
      );
    }
    case "disable2FA": {
      return _fetch(_createRequest(_endpointUser.disable2FA, true, {}));
    }
    case "verifyOTP": {
      const user = _clientUserStorage.get();
      return _fetch(
        _createRequest(_endpointUser.verifyOTP, true, {
          otp_code: params.otp_code,
          user_id: user?.id || params.user_id,
        })
      );
    }
    case "generateOTP": {
      const user = _clientUserStorage.get();

      return _fetch(
        _createRequest(_endpointUser.generateOTP, true, {
          user_id: user?.id || params.user_id,
          username: params.username,
        })
      );
    }
    case "update": {
      let { data = {} } = params;
      if (data) {
        if (data.group.indexOf("USER_GROUP_") === -1) {
          data.group = "USER_GROUP_" + data.group;
        }

        if (data.sex.indexOf("SEX_") === -1) {
          data.sex = "SEX_" + data.sex;
        }
        if (data.status.indexOf("USER_STATUS_") === -1) {
          data.status = "USER_STATUS_" + data.status;
        }
      }
      return _fetch(
        _createRequest(_endpointUser.update, true, {
          ..._pick(data, [
            "id",
            "name",
            "avatar",
            "phone_number",
            "birth_day",
            "sex",
            "group",
            "address",
            "basic_salary",
            "latitude",
            "longitude",
            "status",
          ]),
          // ..._pick(data, ["id", "name", "avatar", "phone_number", "group", "birth_day", "sex", "address", "latitude", "longitude"])
        })
      );
    }
    default: {
      return {
        data: [],
      };
    }
  }
};

export default endpoint;
