export const _token = process.env.REACT_APP_TOKEN_NAME || "token";
export const _refreshToken =
    process.env.REACT_APP_REFRESH_TOKEN_NAME || "refresh_token";
export const _user = process.env.REACT_APP_USER_NAME || "user";
export const _lang = process.env.REACT_APP_LANGUAGE || "vi";

export const _arrayLanguages = [
    {
        key: "vi",
        name: "Vietnamese",
    },
    {
        key: "en",
        name: "English",
    },
];
export const _baseURL =
    process.env.REACT_APP_BASE_URL || "https://gao-gas.com";
export const USER_GROUP_ADMIN = "USER_GROUP_ADMIN";
export const USER_GROUP_OWNER_AGENCY = "USER_GROUP_OWNER_AGENCY";
