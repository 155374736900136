import {_endpointMasterData} from '../../../utils/provider';
import {_createRequest, _pick, _getFilter, _fetch} from '../../../utils/helper'
import {_enumUploadType} from '../../../utils/enum';
import uploadsEndpoint from '../upload';
import {_fileToBase64} from '../../../utils/base64';

const _endpointProductMetadatas = _endpointMasterData.productMetadatas;

const endpoint = async(props) => {
    
    const {type, params = {}} = props;
    switch(type) {
        case "create": {
            if(params.data && params.data.imagesBase && params.data.imagesBase.length > 0) {
                let promise = params.data.imagesBase.map(e => {
                    return uploadsEndpoint({type: "upload", params: {
                        data: {
                            uploadType: _enumUploadType["UPLOAD_TYPE_NONE"],
                            ...e
                        }
                    }})
                })
                await Promise.all(promise).then(res => {
                    const url = res.map(e => e.data) || [];
                    params.data = {
                        ...params.data,
                        images: url
                    }
                })
            }

            return _fetch(_createRequest(_endpointProductMetadatas.create, true, {
                ..._pick(params.data, ["id", "priority", "category_id", "name", "description", "images", "unit", "unit_weight", "supplier", "origin", "expiry", "level", "track_by"])
            }))
        }
        case "import": {
            return _fileToBase64(params.files.rawFile).then(result => {
                return _fetch(_createRequest(_endpointProductMetadatas.import, true, {
                    data: result.payload,
                }))
            })
        }
        case "update": {
            if(params.data && params.data.imagesBase && params.data.imagesBase.length > 0) {
                let promise = params.data.imagesBase.map(e => {
                    return uploadsEndpoint({type: "upload", params: {
                        data: {
                            uploadType: _enumUploadType["UPLOAD_TYPE_NONE"],
                            ...e
                        }
                    }})
                })
                await Promise.all(promise).then(res => {
                    const url = res.map(e => e.data) || [];
                    params.data.images = (params.data.images || []).concat(url)
                }).catch(err => {
                    throw err
                })
            }


            return _fetch(_createRequest(_endpointProductMetadatas.update, true, {
                ..._pick(params.data, ["id", "priority", "category_id", "name", "description", "images", "unit", "unit_weight", "supplier", "origin", "expiry", "level", "track_by"])
            }))
        }
        case "one": {
            return _fetch(_createRequest(_endpointProductMetadatas.one, true, {
                ..._pick(params, ["id"])
            })).then((res = {}) => {
                const {data = {}} =  res 
                return {
                    data: data.product_metadata
                }
            })
        }
        case "delete": {
            return _fetch(_createRequest(_endpointProductMetadatas.delete, true, {
                ..._pick(params, ["id"])
            }))
        }
        case "many": {
            params.filter = {
                ...params.filter,
                id: params.ids,
            }
            params.pagination = {
                ...params.pagination,
                perPage: (params.ids || []).length || 10,
            }
            return _fetch(_createRequest(_endpointProductMetadatas.list, true, {
                filter: _getFilter(params, {id: "in"})
            }))
        }
        case "all": {
            const _getList = async (params = {}) => {
                const {perPage = 100, page = 1} = params.pagination || {};
                return await _fetch(_createRequest(_endpointProductMetadatas.list, true, {
                    filter: _getFilter(params, {deleted_at: "is_null", id: "in"})
                })).then(async res => {
                    let {data = [], total = 1} = res;
                    if(page * perPage < total) {
                        const {data: nextData = []} = await _getList({
                            ...params,
                            pagination: {
                                page: page + 1,
                                perPage,
                            }
                        })
                        data = data.concat(nextData)
                        res.data = data
                    }
                    return res;
                })
            }
            return _getList(params)
        }
        default: { //list
            return _fetch(_createRequest(_endpointProductMetadatas.list, true, {
                filter: _getFilter(params, {})
            }))
        }
    }
}

export default endpoint