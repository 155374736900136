
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { Edit, SimpleForm, TextInput, DateInput, ArrayInput} from 'react-admin';
import TableArrayInput, {SimpleFormIterator} from "../../partials/arrayInputWithPagination";

import {useProductsContext} from "../../context"
import {ItemFormComponent} from "./create";
const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        "& > div > div:first-child": {
            width: "100%",
        },
        marginBottom: theme.spacing(1)
    },
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
    rowWarning: {
        backgroundColor: "#ffcece !important",
    }
}));

const ItemComponent = ({products = [], source, getPrice, record, ...props}) => {
    const classes = useStyles();
    
    return (
        <ArrayInput source="items" record={record}>
            <TableArrayInput>
                <SimpleFormIterator classes={{
                    form: classes.root,
                }}>
                    <ItemFormComponent {...props} products={products} sourceProduct='id' sourcePrice="unit_price" />
                </SimpleFormIterator>
            </TableArrayInput>
        </ArrayInput>
    )
}
const CreateFormComponent = (props) => {
    const {products = [], loading} = useProductsContext()
    return (
        <>
            <Grid container spacing={3}>
                <Grid item md={4} sm={12}>
                    <DateInput label="Import at" source="import_product.import_at" defaultValue={new Date()} fullWidth />
                </Grid>
                <Grid item sm={12}>
                    <TextInput label="Note" source="import_product.note" multiline rows={3} fullWidth />
                </Grid>
            </Grid>
            {loading && <div className="loader"></div>}
            {!loading && <ItemComponent {...props} products={products} />}
        </>
    )
};

const CreateComponent = (props) => {
    const classes = useStyles();
    return (
        <Edit {...props} undoable={false} title={`Update Import Request`}>
            <SimpleForm className={classes.form}>
                <CreateFormComponent {...props} />
            </SimpleForm>
        </Edit>
    )
};

export default CreateComponent;