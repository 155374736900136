import {_endpointUpload} from '../../utils/provider';
import {_createRequest, _pick} from '../../utils/helper'
import {_enumUploadType} from '../../utils/enum';

const uploadsEndpoint = (props) => {
    const {type, params = {}} = props;
    switch(type) {
        case "upload": {
            return fetch(_createRequest(_endpointUpload.upload, true, {
                uploadType: _enumUploadType["UPLOAD_TYPE_NONE"], //UPLOAD_TYPE_NONE
                ..._pick(params.data, ["uploadType", "payload", "extension"])
            })).then(res => res.json())
            .then(res => {
                if(!res.status) {
                    throw res
                }
                const {data} = res;
                return {data: data.url}
            }).catch(err => {
                throw err;
            })
        }
        default: { //TODO
            return false
        }
    }
}

export default uploadsEndpoint