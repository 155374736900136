import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { Datagrid, TextField, NumberField } from "react-admin";
import {
    LatlngField,
    PriceField,
    EnumField,
    DateField,
    UserField,
    PhoneField,
} from "../../../partials";
import { _enumOrderStatus } from "../../../utils/enum";

import ActionComponent from "./actions";
const useStyles = makeStyles((theme) => ({
    box: {
        display: "flex",
        flexDirection: "column",
    },
    row: {
        display: "flex",
        "& > div:first-child": {
            paddingRight: theme.spacing(1),
        },
    },
}));

const ItemsField = (props) => {
    const { record = {} } = props;
    const classes = useStyles();
    return (
        <div className={classes.box}>
            {(record.items || []).map((e = {}, index) => {
                return (
                    <div className={classes.row} key={index}>
                        {e.name} (Quantity: {e.quantity})
                    </div>
                );
            })}
        </div>
    );
};
ItemsField.propTypes = {
    record: PropTypes.object,
    label: PropTypes.string,
};
ItemsField.defaultProps = {
    addLabel: true,
};

const BuyShell = (props) => {
    const { record = {} } = props;
    return <div>{record.buy_shell ? "Có lấy vỏ" : "Ko lấy vỏ"}</div>;
};
const ListComponent = ({ hasList, hasEdit, hasCreate, hasShow, ...props }) => {
    const commomProps = {
        basePath: `/${props.resource}`,
        data: props.data,
        resource: props.resource,
        permissions: props.permissions,
        perPage: props.perPage,
        page: props.page,
    };
    return (
        <Datagrid {...props} style={{ minHeight: 300 }}>
            {props.basePath !== "/customers" && (
                <UserField label="Customer" source="customer_info" />
            )}
            {props.basePath !== "/employeeagencies" && (
                <UserField label="Employee" source="employee_info" />
            )}
            <PhoneField source="phone_number" label="Phone" />
            <TextField source="address" label="Address" />
            <LatlngField label="Latlng" />
            <TextField source="note" label="Note" />
            <EnumField
                source="status"
                label="Status"
                _enum={_enumOrderStatus}
                prefix="ORDER_STATUS_"
            />
            <TextField source="total_point" label="Total Points" />
            <PriceField source="total_price" label="Total Prices" />
            <NumberField
                source="current_point"
                label="Current Points"
            />
            <ItemsField {...commomProps} label="Items" source="items" />
            {props.resource === "orderswaitingto_finish" && (
                <BuyShell source="buy_shell" label="Buy Shell" />
            )}
            <DateField source="created_at" label="Created" />
            <ActionComponent {...commomProps} label="Action" />
        </Datagrid>
    );
};
export default ListComponent;
