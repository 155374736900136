import { useState, useEffect} from 'react';

import { useDataProvider } from 'react-admin';

function useGetAllUsers(resource, filter) {
    const dataProvider = useDataProvider();
    const perPage = 50;
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setData([]);
        setPage(1);
        setTotal(0);
    }, [filter])

    useEffect(() => {
        async function fetchData(page) {
            setLoading(true);
            const result = await dataProvider
                .getList(resource, {
                    filter: JSON.parse(filter),
                    sort: [{
                        field: "name",
                        order: "DESC",
                    },{
                        field: "created_at",
                        order: "DESC",
                    }],
                    pagination: {page, perPage},
                })
                .then((res = {}) => {
                    const {total = 0, data = []} = res;
                    setData((pre = []) => pre.concat(data));
                    setTotal(total);
                    return res;
                })
                .catch((err) => {
                    throw err;
                });
            setLoading(false);
            return result;
        }
        fetchData(page);
    }, [dataProvider, filter, page, resource])
    useEffect(() => {
        if(total > data.length) {
            setPage(pre => pre + 1)
        }
    }, [data, total])
    return {
        data, 
        loading, 
    };
}

export default useGetAllUsers;
