import {_endpointConfig} from '../../utils/provider';
import {_createRequest, _pick, _fetch} from '../../utils/helper';
import {USER_GROUP_ADMIN} from '../../utils/const';
import {_configGroupByPermission} from '../../utils/enum';

const convertKey2Enum = (key = "") => {
    let result = key;
    _configGroupByPermission(USER_GROUP_ADMIN, "").forEach(e => {
        let name = `configs${e.id.toLowerCase()}`;
        if(name === key) {
            result = e.id;
            return result;
        }
    })
    return result;
}
const endpoint = async(props) => {
    const {key, type, params = {}} = props;
    switch(type) {
        case "create": {
            let {data = {}} = params;
            if (data.config_group === "app_version") {
                if(data.platform && data.bundleID) data.config_key = data.platform + "-" + data.bundleID
            } 
            return _fetch(_createRequest(_endpointConfig.create, true, {
                ..._pick(data, ["config_group", "config_key", "config_value", "description", "coefficient"])
            })).then(res => {
                return res
            })
        }
        case "one": {
            const {id = ""} = params;
            return _fetch(_createRequest(_endpointConfig.list, true, {}))
            .then(res => {
                let data = res.data.find(e => e.id === id)
                if(data.config_group === "app_version") {
                    data.config_key = data.config_key || "";
                    const split = data.config_key.split("-");
                    data.platform = split[0]
                    data.bundleID = split[1]
                }
                return {
                    data: data,
                }
            })
        }
        // eslint-disable-next-line no-fallthrough
        case "update": {
            let {data = {}} = params;
            if (data.config_group === "app_version") {
                if(data.platform && data.bundleID) data.config_key = data.platform + "-" + data.bundleID
            } 
            return _fetch(_createRequest(_endpointConfig.update, true, {
                ..._pick(data, ["id", "config_group", "config_key", "config_value", "description", "coefficient"])
            }))
        }
        default: { //list, manyReference
            return _fetch(_createRequest(_endpointConfig.list, true, {}))
            .then(res => {
                let data = res.data
                if(key !== "configs") {
                    const config_group = convertKey2Enum(key);
                    data = data.filter(e => e.config_group === config_group)
                }   
                if(params.filter && params.filter.config_group) {
                    data = data.filter(e => e.config_group === params.filter.config_group)
                }
                data = data.map(e => {
                    if(e.config_group === "app_version") {
                        const split = e.config_key.split("-");
                        e.platform = split[0]
                        e.bundleID = split[1]
                    } 
                    
                    return e;
                })
                return {
                    data: data,
                    total: data.length
                }
            })
        }
    }
}

export default endpoint