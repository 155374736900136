import React from 'react';
import {IconButton, Menu, MenuItem} from '@material-ui/core';
import { useLocale, useSetLocale } from 'react-admin';
import { Language } from '@material-ui/icons';


import {_clientLangueStorage} from '../../utils/storage';
import {_arrayLanguages} from '../../utils/const';


const LocaleSwitcher = () => {
    const locale = useLocale();
    const setLocale = useSetLocale();


    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (key) => {
        setLocale(key)
        _clientLangueStorage.set(key)
        handleClose()
    }
    
    return (
        <React.Fragment>
            <IconButton aria-controls="language-menu" aria-haspopup="true" color="inherit" onClick={handleClick}>
                <Language />
            </IconButton>
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                >
                {_arrayLanguages.map(e => {
                    return <MenuItem key={e.key}
                    disabled={locale === e.key}
                    onClick={() => handleChange(e.key)}>{e.name}</MenuItem>
                })}
            </Menu>
        </React.Fragment>
    );
};

export default LocaleSwitcher;