
import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Toolbar, SaveButton, Edit, SimpleForm, TextInput, NumberInput, FormDataConsumer, required} from 'react-admin';

import { _parseQuery} from "../../utils/helper";
import {_clientUserStorage} from "../../utils/storage";

const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        "& > div > div > div:first-child": {
            width: "100%",
        }
    },
}));
const CustomToolbar = (props) => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
    </Toolbar>
);

const CreateComponent = (props) => {
    const classes = useStyles();
    const user = _clientUserStorage.get() || {}
    const [agencyId, setAgencyId] = React.useState(user.agency_id);


    
    useEffect(() => {
        const checkRedirectBack = async() => {
            const search = props.location.search;
            const query = _parseQuery(search) || {}
            const agency_id = agencyId || query.agency_id
            if(!agency_id) {
                window.history.back();
            } else {
                setAgencyId(agency_id)
            }
        }
        checkRedirectBack()
    }, [agencyId, props.location.search])



    return (
        <Edit {...props} undoable={false} title="Update Config">
            <SimpleForm {...props} toolbar={<CustomToolbar />} className={classes.form} redirect={() => window.history.back()}>
                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <TextInput label="Group" source="config_group" disabled fullWidth validate={[required()]}/>
                    </Grid>
                    <Grid item sm={4}>
                        <TextInput label="Key" source="config_key" disabled fullWidth validate={[required()]}/>
                    </Grid>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            if(formData.config_group === "point") {
                                //point
                                return (
                                    <React.Fragment>
                                        <Grid item sm={2}>
                                            <NumberInput fullWidth label="Coefficient" source="coefficient" initialValue={1} validate={[required()]}/>
                                        </Grid>
                                        <Grid item sm={2}>
                                            <TextInput fullWidth label="Value" source="config_value" initialValue={1} validate={[required()]}/>
                                        </Grid>
                                    </React.Fragment>
                                )
                            } else {
                                return (
                                    <Grid item sm={4}>
                                        <TextInput label="Value" source="config_value" fullWidth validate={[required()]}/>
                                    </Grid>
                                )
                            }
                        }}
                    </FormDataConsumer>
                    <Grid item sm={12}>
                        <TextInput rows={5} multiline label="Description" source="description" fullWidth />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};

export default CreateComponent;