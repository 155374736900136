import React from 'react';
import {Grid} from "@material-ui/core"
import PropTypes from "prop-types";
import {Labeled} from "react-admin"
const GridField =  (props) => {
    return (
        <Grid {...props}>
            {React.Children.map(props.children, child => {
                return (
                    <Labeled {...child.props}>
                        {React.cloneElement(child, {
                            ...child.props,
                            record: props.record,
                            basePath: props.basePath,
                            resource: props.resource,
                            addLabel: false,
                        })}
                    </Labeled>
                );
            })}
        </Grid>
    );
};
GridField.propTypes = {
    label: PropTypes.string,
}
GridField.defaultProps = {
    addLabel: true,
}
export default GridField;
