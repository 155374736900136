/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { Typography, MenuItem, ListItemIcon, ListItem, Button } from '@material-ui/core';
import { AppBar, UserMenu, useRedirect } from "react-admin";
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';

import DialogComponent from "../users/resetPassword";
import { makeStyles } from '@material-ui/core/styles';
import { _clientUserStorage } from "../../utils/storage";

import LocaleSwitcher from '../localeSwitcher'


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    icon: {
        minWidth: theme.spacing(5)
    },
    listItem: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    reset: {
        color: "white"
    }
}));

const MyUserMenu = (props) => {
    const classes = useStyles();
    const redirect = useRedirect();
    const handleProfile = () => {
        redirect('/profiles')
    }
    return (
        <UserMenu {...props}>
            <MenuItem>
                <ListItem onClick={handleProfile} className={classes.listItem}>
                    <ListItemIcon onClick={handleProfile} className={classes.icon}>
                        <AccountCircle />
                    </ListItemIcon> Profile
                </ListItem>
            </MenuItem>
        </UserMenu>
    )
};

const MyAppBar = props => {
    const classes = useStyles();
    const user = _clientUserStorage.get() || {}
    const [isReset, setIsReset] = React.useState()
    const handleClose = () => {
        setIsReset(false);
    };
    const handleReset = () => {
        setIsReset(true)
    }

    return (
        <div className={classes.root}>
            {isReset &&
                <DialogComponent
                    {...props}
                    classesContent={{
                        root: classes.rootContent,
                    }}
                    record={user}
                    user_id={user.id}
                    anchor="right"
                    handleClose={handleClose}
                    title="Reset Password"
                    maxWidth="md"
                    open
                    fullWidth />}
            <AppBar {...props} userMenu={<MyUserMenu />} >
                <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.title}
                    id="react-admin-title"
                />
                <span className={classes.spacer} />

                <LocaleSwitcher />
                <Button className={classes.reset} onClick={handleReset} startIcon={<LockIcon />}>Reset Password</Button>
            </AppBar>

        </div>
    );
}
export default MyAppBar;
