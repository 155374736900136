
import React from 'react';

import { TopToolbar, SimpleShowLayout, Show} from 'react-admin';

import DataComponent from "./detail"
import ActionAdmin from "../actions";
import ActionAgency from "../actions";
import { _groupPermission} from "../../../utils/enum"

const ActionComponent = (props) => {
    const requireProps = {
        ...props,
        permissions: props.permissions,
        record: props.data,
        basePath: props.basePath,
        resource: props.resource,
        input: undefined,
        isShow: true,
        nameBtn: "Action"
    }
    return (
        <TopToolbar>
            {_groupPermission(props.permissions) === "admin" ? <ActionAdmin {...requireProps} />: <ActionAgency {...requireProps} />}
        </TopToolbar>
    );
}

const DetailComponent = (props) => {
    return (
        <Show {...props} actions={<ActionComponent {...props}/>} title="Detail Transfer Request">
            <SimpleShowLayout>
                <DataComponent {...props}/>
            </SimpleShowLayout>
        </Show>
    )
};

export default DetailComponent;